import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
//  import {  useNavigate, useLocation } from "react-router-dom";
import { token,getProfile } from "./store/slices/userSlice"
import { getUserEventList,getStoreList,userLogout } from "../src/store/slices/userSlice";
import Layout from './components/Layout/Layout';
import ContextProvider from "./context/context"
import { requestForToken, messaging } from "./firebase"
import { onMessage } from "firebase/messaging"
import { setSocket } from "./store/slices/socketSlice";
import { toast } from 'react-toastify';

 
function App() { 
  const dispatch = useDispatch()  
  const profile=useSelector(getProfile)
  // const navigate = useNavigate();



  const GetEventNames = async () => {
    try {
     
     
        await dispatch(getUserEventList()).unwrap();
      
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  useEffect(() => {
    try { 
      dispatch(token())
      dispatch(setSocket())
      requestForToken();
     onMessage(messaging, (payload) => {
        toast(payload.notification.body)
        console.log("foreground", payload)
     })
    } catch (error) {
      console.log(error)
    }
  }, [])

  useEffect(()=>{
    GetEventNames()
    const selectedLanguage = localStorage.getItem('selectedLanguage');

  if (!selectedLanguage) {
    // If it doesn't exist, set it to 'en'
    localStorage.setItem('selectedLanguage', 'en');
  }

  },[])



//   useEffect(() => {
//     const handleBeforeUnload = () => {
//       localStorage.setItem('isReturning', 'true');
//     };

//     window.addEventListener('beforeunload', handleBeforeUnload);

//     return () => {
//       window.removeEventListener('beforeunload', handleBeforeUnload);
//     };
//   }, []);

//   const handleLogout=async()=>{
//     try {
   
//       console.log("first")

//         // await new Promise((resolve) => setTimeout(resolve, 1000));
//         await dispatch(userLogout()).unwrap()
//         localStorage.removeItem('user');
//         localStorage.removeItem('pageUnloading');
//         localStorage.removeItem('isReturning');
//         // navigate(profile?.role=="customer"?"/login":"/vendor/login")
      
//       } catch (rejectedValueOrSerializedError) {
//         console.log(rejectedValueOrSerializedError)
//       }
// }

//   useEffect(() => {
//     const handleVisibilityChange = () => {
//       if (!document.hidden) {
//         const isReturning = localStorage.getItem('isReturning');
//         console.log('isReturning', isReturning)
     
//         const loggedInUser = localStorage.getItem('loggedInUser');
       
//         if (isReturning&&loggedInUser === 'true' || loggedInUser === true) {
     
//           console.log('First visit or page refresh');
//           localStorage.removeItem('isReturning');
//         } 
//         else {
         
//           console.log('User was not logged in last time');
//             handleLogout();
//         }
//       }
//     };

//     document.addEventListener('visibilitychange', handleVisibilityChange);
//     window.addEventListener('pagehide', handleLogout);

//     return () => {
//       document.removeEventListener('visibilitychange', handleVisibilityChange);
//       window.removeEventListener('pagehide', handleLogout);
//     };
//   }, []);


 

  return (
    <ContextProvider >
      <Layout />
    </ContextProvider>
  );
}

export default App;
