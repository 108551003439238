import React, { useEffect, useState, useContext } from "react";
import { BrowserRouter, Routes, Route, Navigate, useLocation } from "react-router-dom";
import Nav from "./Nav";
import Spinner from "../Spinner";
import Login from "../../pages/Authentication/Login";
import SignUp from "../../pages/Authentication/SignUpLanding";
import NewPassword from "../../pages/Authentication/NewPassword";
import ForgetPassword from "../../pages/Authentication/ForgetPassword";
import SignUp1 from "../../pages/Authentication/SignUp1";
import SignUp2 from "../../pages/Authentication/SignUp2";
import RequireAuth from "../../pages/Authentication/RequireAuth"
import UnAuthorized from "../../pages/Authentication/UnAuthorized"
import Signupform from "../../pages/Authentication/SignUpForm";
import DashboardUser from "../../pages/UserDashboard/Dashboard";
import UserBookings from "../../pages/UserDashboard/Bookings";
import Budget from "../../pages/UserDashboard/Budget";
import ShortList from "../../pages/UserDashboard/ShortList";
import Vendor from "../../pages/UserDashboard/Vendor";
import SavedPins from "../../pages/UserDashboard/SavedPins";
import Settings from "../../pages/UserDashboard/Settings";
import TaskManagement from "../../pages/UserDashboard/TaskManagement";
import Advice from "../../pages/UserDashboard/Advice";
import Chat from "../../pages/UserDashboard/Chat";
import CreateEvent from "../../pages/UserDashboard/CreateEventForm";
import GuestList from "../../pages/UserDashboard/GuestList";
import GuestManagementFeature from "../../pages/Public/GuestManagementFeature";
import TaskManagementFeature from "../../pages/Public/TaskManagementFeature";
import BudgetManagementFeature from "../../pages/Public/BudgetManagementFeature";
import Home from "../../pages/Public/Home";
import FAQS from "../../pages/Public/FAQs";
import ContactUs from "../../pages/Public/ContactUs";
import AboutUs from "../../pages/Public/AboutUs";
import Vendors from "../../pages/Public/Vendors";
import Storefront from "../../pages/Public/Storefront";
import BlogDetails from "../../pages/Public/BlogDetails";
import IdeasandInspiration from "../../pages/Public/IdeasandInspiration";
import Page404 from "../../pages/Public/404";
import RequestReview from "../../pages/Public/RequestReview";
import RequestReviewBooking from "../../pages/UserDashboard/ReviewBooking";
import GuestInviteConfirmation from "../../pages/Public/Invitation";
import { useSelector } from "react-redux";
import { getUserStatus, getUsertoken, getProfile } from "../../store/slices/userSlice";
// import TermsAndConditions from "../../pages/Authentication/TermsAndConditions";
// import PrivacyPolicy from "../../pages/Authentication/PrivacyPolicy";
import { context } from "../../context/context";
import { useTranslation } from "react-i18next";
import Header from "../../pages/Public/HeaderLight";
import Sidebar from "./Sidebar";
import Dashboard from "../../pages/Planner/Dashboard";
import CoupleList from "../../pages/Planner/CoupleList";
import EventOverview from "../../pages/Planner/EventOverview";
import CoupleOverview from "../../pages/Planner/CoupleOverview";
import EventList from "../../pages/Planner/EventList";
import Calendar from "../../pages/Planner/Calendar";
import AddCouple from "../../pages/Planner/AddCouple";
import Privacy from "../../pages/Public/Privacy";
import Support from "../../pages/Public/Support";
import Marketing from "../../pages/Public/Marketing";
import CopywriteNotice from "../../pages/Public/CopywriteNotice";
import DeleteAccount from "../../pages/Public/DeleteAccount";




const Layout = () => {
  const { i18n } = useTranslation();
  const { isLoading } = useContext(context);
  const status = useSelector(getUserStatus);
  const authtoken = useSelector(getUsertoken);
  const profile = useSelector(getProfile);

 
  useEffect(() => {
    // Update lang and dir attributes based on the selected language
    document.documentElement.lang = i18n.language;
    document.documentElement.dir = i18n.language === 'ar' ? 'rtl' : 'ltr';
    if (i18n.language === 'ar') {
      document.body.classList.add('rtl-arabic');
    } else {
      document.body.classList.remove('rtl-arabic');
    }
  }, [i18n.language]);

console.log('status', status)

  return (
    <>
      {/* {status === "loading"  ? 
      <Spinner /> 
      : null} */}
   
        <BrowserRouter>
          <Content authtoken={authtoken}/>
        </BrowserRouter>
    
    </>
  );
};


const Content = ({ authtoken }) => {
  var dash = "";
const location = useLocation();
const p = location.pathname.split('/');
const p1 = p[p.length - 1];

for (var i = p1.length - 1; i >= 0; i--) {
  if (p1[i] == '_') {
    console.log("trueeeeeeeee");
    dash = i;
    break;
  }
}

const t = decodeURIComponent(p1.replace(/-/g, ' '));


function capitalizeEachWord(str) {
  return str.replace(/\b\w/g, (char) => char.toUpperCase());
}

useEffect(() => {
  document.title = location.pathname == "/" ? "Weddified" : dash !== "" ? capitalizeEachWord(t.substring(0, dash)) : capitalizeEachWord(t);

}, [dash, t]);

useEffect(() => {
  const body = document.body;
  if (
    location.pathname === '/dashboard' ||
    location.pathname === '/task-management' ||
    location.pathname === '/guest-list' ||
    location.pathname === '/budget' ||
    location.pathname === '/event' ||
    location.pathname === '/savedpins' ||
    location.pathname === '/chat' ||
    location.pathname === '/short-list' ||
    location.pathname === '/settings' ||
    location.pathname === '/bookings'
  ) {
    body.classList.add('wf-user-pages');
  } else {
    body.classList.remove('wf-user-pages');
  }
  // Clean up the class when the component unmounts
  return () => {
    body.classList.remove('wf-user-pages');
  };
}, [location.pathname]);


  return (
    <>
      {authtoken ? (
        <>
          {location.pathname === "/dashboard" || location.pathname === "/calendar" || location.pathname === "/task-management" || location.pathname === "/bookings" || location.pathname === "/guest-list" || location.pathname === "/budget" || location.pathname === "/set-budget"
          || location.pathname === "/event" || location.pathname === "/booking-review" || location.pathname === "/savedpins" ||  location.pathname === "/advice" ||  location.pathname === "/chat"|| location.pathname === "/short-list" 
          || location.pathname === "/settings" || location.pathname === "/dashboard-planner" || location.pathname === "/budget-planner"|| location.pathname === "/couples-list" || location.pathname === "/couples-overview" || location.pathname === "/event-overview"|| location.pathname === "/event-list"
          || location.pathname === "/add-couple" ? (
            <>
            <Sidebar />
            <Header theme="dark" width="full" />
            <section className={location.pathname == "/add-business" || location.pathname == "/verify-sucessfully" ? "" : "content-section"}>
              
              <>
              
                <Routes>
                  <Route path="/dashboard" element={<RequireAuth allowedRoles={["customer","planner"]}><DashboardUser /></RequireAuth>} />
                  <Route path="/budget" element={<RequireAuth allowedRoles={["customer","planner"]}><Budget /></RequireAuth>} />
                  <Route path="/bookings" element={<RequireAuth allowedRoles={["customer","planner"]}><UserBookings /></RequireAuth>} />
                  <Route path="/task-management" element={<RequireAuth allowedRoles={["customer","planner"]}><TaskManagement /></RequireAuth>} />
                  <Route path="/advice" element={<RequireAuth allowedRoles={["customer","planner"]}><Advice /></RequireAuth>} />
                  <Route path="/chat" element={<RequireAuth allowedRoles={["customer","planner"]}><Chat /></RequireAuth>} />
                  <Route path="/event" element={<RequireAuth allowedRoles={["customer","planner"]}><CreateEvent /></RequireAuth>} />
                  <Route path="/booking-review" element={<RequireAuth allowedRoles={["customer","planner"]}><RequestReviewBooking /></RequireAuth>} />
                  <Route path="/guest-list" element={<RequireAuth allowedRoles={["customer","planner"]}><GuestList /></RequireAuth>} />
                  <Route path="/savedpins" element={<RequireAuth allowedRoles={["customer","planner"]}><SavedPins /></RequireAuth>} />
                  <Route path="/short-list" element={<RequireAuth allowedRoles={["customer","planner"]}><ShortList /></RequireAuth>} />
                  <Route path="/settings" element={<RequireAuth allowedRoles={["customer","planner"]}><Settings /></RequireAuth>} />
                  <Route path="/dashboard-planner" element={<RequireAuth allowedRoles={["planner"]}><Dashboard /></RequireAuth>} />
                  <Route path="/couples-list" element={<RequireAuth allowedRoles={["planner"]}><CoupleList /></RequireAuth>} />
                  <Route path="/couples-overview" element={<RequireAuth allowedRoles={["planner"]}><CoupleOverview /></RequireAuth>} />
                  <Route path="/add-couple" element={<RequireAuth allowedRoles={["planner"]}><AddCouple /></RequireAuth>} />
                  <Route path="/event-overview" element={<RequireAuth allowedRoles={["customer", "planner"]}><EventOverview /></RequireAuth>} />
                  <Route path="/event-list" element={<RequireAuth allowedRoles={["customer", "planner"]}><EventList /></RequireAuth>} />
                  <Route path="/calendar" element={<RequireAuth allowedRoles={["customer", "planner"]}><Calendar /></RequireAuth>} />


                  </Routes>
                  </>
                  </section>
                  </>
                  ) :
                  
                  
                  (
                    <>
                    <Routes>

                    /*User Routes Other Then Dashboard*/

                    <Route path="/signupform/" element={<RequireAuth allowedRoles={["customer","planner"]}><Signupform /></RequireAuth>} />
                    <Route path="/confirmation/" element={<RequireAuth allowedRoles={["customer","planner"]}><SignUp2 /></RequireAuth>} />
                    <Route path="/business/" element={<RequireAuth allowedRoles={["customer","planner"]}><Vendor /></RequireAuth>} />
                    



              <Route path="*" element={<Navigate to="/" />} />
              <Route path="/" exact element={<Home />} />
              <Route path="/invitation"  element={<GuestInviteConfirmation />} />
              <Route path="/suppliers"  element={<Vendors />} />
              <Route path="/suppliers/:name"  element={<Vendors />} />
              <Route path="/supplier-details/:name"  element={<Storefront />} />
              <Route path="/login/" exact element={<Login />} />
              <Route path="/about"  element={<AboutUs />} />
              <Route path="/signup/" element={<SignUp />} />             
              <Route path="/contact"  element={<ContactUs />} />
              <Route path="/faqs"  element={<FAQS />} />
              <Route path="/verify-account/" element={<SignUp1 />} />
              <Route path="/forgetpassword/" element={<ForgetPassword />} />
              <Route path="/newpassword/" element={<NewPassword />} />
              <Route path="/home/" element={<Home />} />
              <Route path="/features/guest-management/" element={<GuestManagementFeature />} />
              <Route path="/features/task-management/" element={<TaskManagementFeature />} />
              <Route path="/features/budget-management/" element={<BudgetManagementFeature />} />
              <Route path="/request-review" element={<RequestReview />} />
              <Route path="/ideas-inspiration" element={<IdeasandInspiration />} />
              <Route path="/ideas-inspiration/:name" element={<IdeasandInspiration />} />
              <Route path="/ideas-inspiration/:name/:name" element={<IdeasandInspiration />} />
              <Route path="/blog/:name" element={<BlogDetails />} />
              <Route path="/unauthorized" element={<UnAuthorized />} />
              <Route path="/404" element={<Page404 />} />
              {/* Terms Privacy */}
              <Route path="/privacy" element={<Privacy />} />
              <Route path="/support" element={<Support />} />
              <Route path="/marketing" element={<Marketing />} />
              <Route path="/copyright" element={<CopywriteNotice />} />
              <Route path="/delete-account" element={<DeleteAccount />} />              
              </Routes>
            </>
          )}
        </>
      ) :
      
      
      (
        <>
          <Routes>
          <Route path="*" element={<Navigate to="/" />} />
              <Route path="/" exact element={<Home />} />
              <Route path="/invitation"  element={<GuestInviteConfirmation />} />
              <Route path="/suppliers"  element={<Vendors />} />
              <Route path="/suppliers/:name"  element={<Vendors />} />
              <Route path="/supplier-details/:name"  element={<Storefront />} />
              <Route path="/login/" exact element={<Login />} />
              <Route path="/about"  element={<AboutUs />} />
              <Route path="/signup/" element={<SignUp />} />
              <Route path="/contact"  element={<ContactUs />} />
              <Route path="/faqs"  element={<FAQS />} />
              <Route path="/verify-account/" element={<SignUp1 />} />
              <Route path="/forgetpassword/" element={<ForgetPassword />} />
              <Route path="/newpassword/" element={<NewPassword />} />
              <Route path="/home/" element={<Home />} />
              <Route path="/features/guest-management/" element={<GuestManagementFeature />} />
              <Route path="/features/task-management/" element={<TaskManagementFeature />} />
              <Route path="/features/budget-management/" element={<BudgetManagementFeature />} />
              <Route path="/request-review" element={<RequestReview />} />
              <Route path="/ideas-inspiration" element={<IdeasandInspiration />} />
              <Route path="/ideas-inspiration/:name" element={<IdeasandInspiration />} />
              <Route path="/ideas-inspiration/:name/:name" element={<IdeasandInspiration />} />
              <Route path="/blog/:name" element={<BlogDetails />} />
              <Route path="/unauthorized" element={<UnAuthorized />} />
              <Route path="/404" element={<Page404 />} />
              {/* Terms Privacy */}
              <Route path="/privacy" element={<Privacy />} />
              <Route path="/support" element={<Support />} />
              <Route path="/marketing" element={<Marketing />} />
              <Route path="/copyright" element={<CopywriteNotice />} />
              <Route path="/delete-account" element={<DeleteAccount />} />
          </Routes>
        </>
      )}
    </>
  );
};

export default Layout;