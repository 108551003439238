import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Firstlogin,
  getEventList,
  getProfile,
  
} from "../../store/slices/userSlice";
import axios from "axios";
import { Navigation, Pagination, A11y, EffectFade } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/effect-fade";
import VendorCardSkeletonHome from "../LoadingSkeleton/VendorCardSkeletonHome";
import { Heart } from "lucide-react";
import ButtonSkeleton from "../LoadingSkeleton/ButtonSkeleton";
import Modal from "react-modal";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
const customStyles = {
  content: {
    "--bs-modal-width" : "35%",
    display: "block",
  },
};
Modal.setAppElement("#root");

const WeddingSuppliers_Dashboard = () => {
  const { t } = useTranslation();
  const savedLanguage = localStorage.getItem('selectedLanguage');
    const dispatch = useDispatch();
    const myEvents = useSelector(getEventList);
    const [event, setevent] = useState([]);
    const [category_id, setcategory_id] = useState("");
    const [store_id, setstore_id] = useState("");
    const [modalIsOpen, setmodalIsOpen] = useState(false);
    const profile = useSelector(getProfile);
  


    const [data, setdata] = useState([]);
    
    const [categories, setcategories] = useState([]);
    const [value, setvalue] = useState([]);
 
    const [load, setload] = useState(false);
    const [loadd, setloadd] = useState(false);
    const [load1, setload1] = useState(false);
  
    

    const getCategories = async () => {
      try {
         setload(true);
  
         const response = await axios.get(`user/getservices`);
         setcategories(response?.data?.data.filter(item => item.name !== "Venue"));
         setload(false);
  
      } catch (rejectedValueOrSerializedError) {}
    };


    const IncrementView = async (store_id,type) => {
      try {
        console.log("store_id",store_id)
        await axios.post(`vendorpage/incrementview`, { store_id: store_id, type: type });
      } catch (error) {
        console.error("Error incrementing view:", error);
      }
    }


    const handleChange = async (e) => {
      if (!value.includes(e)) {
        setvalue([...value, e.toString()]);
      } else {
        setvalue(value.filter((service) => service !== e));
      }
    };


    const viewModal = async (store_id,category_id,event_id) => {

      if(profile?.role=="customer"&&myEvents?.length<1){
        toast.error("You don't have any event to shortlist this store")
      }
      else{
        console.log('event_id', event_id)
        const firstElementType = typeof event_id[0]
        setmodalIsOpen(true);
        setstore_id(store_id)
        setcategory_id(category_id)
        setevent(firstElementType === 'string'?event_id:event_id?.map((ev)=>ev.event_id))
      }
    };
    
    
    function closeModal() {
      setmodalIsOpen(false);
    }

    console.log('eventsasaas', event)

    const handlePin = async () => {

      try {
        setload1(true)
      const finalIndex= store_id
    
      
     
      console.log('event', event)
      console.log('store_id', store_id)
      console.log('category_id', category_id)


     


      const abc = await axios.post("/vendorpage/favourite",{ id: profile?._id, user_events: myEvents?.map((evv)=>evv.Event._id),store_id:store_id, event_id:event,category_id:category_id })
    
      if (abc) {
        closeModal();
        toast.success(abc?.data?.message);
        
        // FeaturedSuppliers();
        
        
      
        const index = data.findIndex(item => item._id == finalIndex);
        console.log('index', index)
        let val= event
    
    
      // If the object with the specified _id is found, update its name
      if (index !== -1) {
        const newData = [...data];
          newData[index].isfavourite = val;
    
          // Update the state with the new data
          setdata(newData);
      } 
                    
      // setevent([])
      // setcategory_id("")
      // setstore_id("")
     
    }
    setload1(false)
  } catch (error) {
    // Handle error
    console.error("Error occurred:", error);
  }
    
    
    };


    const getallStores = async () => {
      try {
        setloadd(true);

        
        const response = await axios.post('vendorpage/getallstores', {
        
            page: 1,
            user_id: profile ? profile?._id : "",
            category: value.length>0?JSON.stringify(value):JSON.stringify([]),
            lang: savedLanguage
          
        });
    
        setdata(response?.data?.results);
    
       
        setloadd(false);
       
    
      } catch (rejectedValueOrSerializedError) {
        console.log(rejectedValueOrSerializedError);
      }
    };
 
  
    useEffect(() => {
      getCategories();     
  }, []);

    useEffect(() => {
     getallStores()
      
  }, [value]);

  console.log('data111111', data)


  const handleShotlistEventChange = (id) => {
    console.log('idddddddddddddddd', id);
    if (!event.includes(id)) {
      setevent([...event, id]);
    } else {
      const updatedEventIds = event.filter((itemId) => itemId !== id);
      setevent(updatedEventIds);
    }
  };
  
  console.log('data', data)

  return (
    <>
    <Modal
      closeTimeoutMS={500}
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Add Task"
      className="modal fade show wf-modal tasks"
    >
      <div class="modal-dialog wf-modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header border-0">
          <h1
          class="font-size-26 dm-font purple-color"
          id="staticBackdropLabel"
          >
          {t('SelectAnEvent')}
          </h1>
          <button
          type="button"
          onClick={closeModal}
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          ></button>
          
          </div>
        
          
          <div class="modal-body">
          
          <p class="font-size-16  color-black">{t('Pleaseselecttheeventsforwhichyouwanttoshortlistthisstore')}</p>
          <div className="d-flex flex-wrap gap-2 mb-3">
          {myEvents?.length>0&&myEvents?.map((e)=>(<>
          <input onChange={()=>handleShotlistEventChange(e.Event._id)} checked={event?.includes(e.Event._id) ? true : false}  type="checkbox" class="btn-check" name="event-select" id={e.Event.event_name} autocomplete="off"/>
          <label style={{"--bs-btn-active-bg":`${e.Event.event_color}`}} className="btn wf-select-btn" for={e.Event.event_name}>{e.Event.event_name}</label>
          </>))}
          </div>
         
          </div>
          <div class="modal-footer border-0 justify-content-center">
          <button class="btn wf-btn-2 wf-pink-btn py-2 px-4 rounded-2 wf-shadow-2" onClick={()=>handlePin()}>{load1 ? (
          <div className="spinner-border wf-spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        ) : (
          t('Add')
        )}</button>
          </div>
        </div>
      </div>
    </Modal>


    <div className="d-flex gap-4 align-items-end mb-2">
        <h2 className="font-size-26 dm-font color-black mb-0">{t('WeddingSuppliers')}</h2>
        <a className="font-size-14 pink-color mb-0" href="/suppliers"><u>{t('ViewAll')}</u></a>
    </div>
    <div className="d-flex gap-2 wf-select-btn mb-3 flex-wrap">
  {load ? (
    new Array(21).fill(null).map((_, index) => (
      <ButtonSkeleton key={index} />
    ))
  ) : (
    <>
      <input onChange={()=>setvalue([])} checked={value.length<1?true:false} type="checkbox" name="cat-select" className="btn-check" id={102} />
      <label className="btn wf-select-btn" for={102}>
        All
      </label>
      {categories?.map((cat, index) => (
<>
          <input onChange={(e)=>handleChange(e.target.value)} value={cat._id} checked={value.includes(cat._id)?true:false} type="checkbox" name="cat-select" className="btn-check" id={cat._id} />
          <label key={103} className="btn wf-select-btn" for={cat._id}>
            {cat.name}
          </label>
</>
      ))}
    </>
  )}
</div>
    <div className="wf-filter-category-slider wf-saved-pins-slider wf-shadow-2 white-bg rounded py-3 px-4 mb-3">
    <Swiper
      modules={[Navigation, A11y]}
      spaceBetween={15}
      // slidesPerView={}
      navigation
      onSwiper={(swiper) => console.log(swiper)}
      onSlideChange={() => console.log('slide change')}
      breakpoints={{
        640: {
          slidesPerView: 1,
        },
        768: {
          slidesPerView: 4,
        },
      }}>
      {loadd ? (<div className="row">
    {new Array(4).fill(null).map((_, index) => (
      <VendorCardSkeletonHome key={index} />
    ))}
  </div>
) : data?.map((d)=>(
  <SwiperSlide>
  <div key={d._id} className="vendor-card vendor-card-dashboard">
<div className="vendor-card-body">
  <a href={`${process.env.REACT_APP_FrontURL}/supplier-details/${d.slug}`} onClick={()=>IncrementView(d._id,"profile")}>
  <img alt={d.store_name} src={`${process.env.REACT_APP_IMGURL}${d?.image}`} className="vendor-image w-100"/>
  </a>
  <div className="d-flex justify-content-between align-items-center top-box">
  {d?.is_featured==1?(<span className="font-size-10 rounded-1 wf-purple-btn py-1 px-2 text-uppercase">Featured</span>):null}
  {profile&&profile?.role=="customer"&&(<div className="heart-icon" onClick={() => viewModal(d?._id,d?.prefered_services[0]?._id,d?.isfavourite)}>
   {d?.isfavourite?.length>0?<Heart fill="#EE0E7B" stroke="#EE0E7B" />:<Heart />}
   </div>)}
  </div>
  <div className="d-flex justify-content-between align-items-center bottom-box">
    <span className="font-size-10 rounded-1 wf-pink-btn py-1 px-2 text-uppercase"><a href={`${process.env.REACT_APP_FrontURL}/suppliers/${d?.prefered_services[0]?.name.replace(/\s/g, '-').replace(/&/g, "and").toLowerCase()}`}>{d?.prefered_services[0]?.name}</a></span>
  </div>
</div>
<div className="vendor-card-footer">
  <h3 className="font-size-16 mb-1 dm-font" ><a href={`${process.env.REACT_APP_FrontURL}/supplier-details/${d.slug}`} onClick={()=>IncrementView(d._id,"profile")} className="purple-color">{d.store_name.substring(0,22)}</a></h3>
    <p className="font-size-12 color-black mb-1"><i class="bi bi-geo-alt-fill pink-color"></i> {`${d.cities.length>0?d.cities[0]?.name:""}${d.cities.length>0&&d.country.length>0?",":""} ${d.country.length>0?d.country[0]?.name:""}`}</p>
  <div className="d-flex justify-content-between align-items-center">
    <p className="font-size-12 color-black mb-0"><i class="bi bi-star-fill purple-color"></i> <span className="fw-medium">{d?.reviews?.average}</span> ({d?.reviews?.total})</p>
  </div>
</div> 
  </div>
  </SwiperSlide>
))}
      </Swiper>
    </div>
   </>
  )
}

export default WeddingSuppliers_Dashboard