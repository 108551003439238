import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";



const SignUpStep3 = ({ formData, handleFormUpdate }) => {
  const { t } = useTranslation();
  const guestAmountOptions = [
    "Less than 50",
    "50-200",
    "200-350",
    "350-500",
    "500-650",
    "450-800",
    "800-950",
    "More than 1000",
    "UNDECIDED",
  ];

  

const weddingCosts = ['1,00,000', '1,50,000',
'2,00,000',  '2,50,000',
'3,00,000',  '3,50,000',
'4,00,000',  '4,50,000',
'5,00,000',  '5,50,000',
'6,00,000',  '6,50,000',
'7,00,000',  '7,50,000',
'8,00,000',  '8,50,000',
'9,00,000',  '9,50,000',
'10,00,000'];


const [toggle,settoggle]=useState(false)







console.log('weddingCosts', weddingCosts)

  return (
    <>

<h3 className="font-size-20 dm-font pink-color mb-2">{t('ExpectedGuests')}?</h3>
        <p className="font-size-14 purple-color mb-2">{t('HowmanyguestsarecomingatyourEvent')}?</p>
        <div class="d-flex gap-2 flex-wrap mb-4">
        {guestAmountOptions.map((option) => (
            <>
            <input type="radio" checked={formData.no_of_guests === option} onChange={() => handleFormUpdate({ no_of_guests: option })} name="expected-guests" id={option.split(" ").join("-")} class="btn-check" />
            <label class="btn wf-select-btn" for={option.split(" ").join("-")}>{option}</label>
            </>
          ))}
        </div>

        <h3 className="font-size-20 dm-font pink-color mb-2">{t('EstimatedBudget')}?</h3>
        <p className="font-size-14 purple-color mb-2">{t('Howmuchareyouplanningtospend')}?</p>
        <div class="d-flex gap-2 flex-wrap mb-4">
        {weddingCosts?.length>0&&weddingCosts?.map((cost, index) => {
           console.log('formData.user_budget', formData.user_budget == cost.replace(/,/g, ''))
          
           const costUpdated=cost.replace(/,/g, '')
           return(<>
          <input
          type="radio"
          checked={formData.user_budget === costUpdated}
          onChange={(e) => handleFormUpdate({ user_budget: e.target.value })} id={`budget-${index}`} value={costUpdated} name="estimated-budget" className="btn-check"/>
        <label className="btn wf-select-btn" for={`budget-${index}`}>{cost} SAR</label>
        </>)})}
            <input checked={formData.user_budget === 'none'} onChange={() => handleFormUpdate({ user_budget: 'none' })} type="radio" name="estimated-budget" id="undecided1" class="btn-check" />
            <label class="btn wf-select-btn" for="undecided1">Undecided</label>
            <input type="radio" checked={formData.user_budget === 'other'} onChange={() => handleFormUpdate({ user_budget: 'other' })} name="estimated-budget" id="other" class="btn-check" />
            <label class="btn wf-select-btn" for="other">Other</label>
        </div>
        {!weddingCosts.map(cost => cost.replace(/,/g, '')).includes(formData.user_budget) && formData.user_budget !== "none" && formData.user_budget !== "" && (
    <div class="input-group wf-input-group wf-shadow-2 mb-4">
    <div class="form-floating wf-input">
      <input
        value={formData.user_budget}
        // onChange={(e) => handleFormUpdate({ user_budget: parseInt(e.target.value) })}
        onChange={(e) => {
          const enteredValue = parseInt(e.target.value);
          if (!isNaN(enteredValue) && enteredValue >= 0) {
            handleFormUpdate({ user_budget: enteredValue });
          } else {
            e.target.value = null
          }
        }}
        type="number"
        class="form-control"
        id="floatingInput"
        placeholder=""
        autocomplete="date"
        required
      />
      <label className="purple-color" for="floatingInput">
        {t('FixedBudget')}
      </label>
    </div>
    <button onClick={() => handleFormUpdate({ user_budget: "" })} class="input-group-text pink-color">
      <i class="fas fa-times"></i>
    </button>
  </div>
)}


        <div class="d-flex justify-content-center gap-3">
          <button onClick={()=>handleFormUpdate({ page: formData.page-1 })} class="btn wf-btn-2 wf-pink-outline-btn py-2 px-4 rounded-2">{t('Back')}</button>
          <button class="btn wf-btn-2 wf-purple-btn py-2 px-4 rounded-2"  onClick={()=>{if(formData.page === 2 && !formData.isquestion) {
                  handleFormUpdate({ page: formData.page+1 })
                }
                else if(formData.page === 2 && formData.isquestion) {
                  handleFormUpdate({ page: formData.page+2 })
                }
                else{
                  handleFormUpdate({ page: formData.page+1 })
                }}}>{t('Save')}</button>
        </div>  
 
    </>
  );
};
export default SignUpStep3;