import React from 'react'

const ListSkeleton = () => {
  return (
    <>
    <div className="w-100 shimmer rounded mb-3" style={{height:'100px'}}></div>
    </>
  )
}

export default ListSkeleton