import React, { useEffect, useState,useContext } from 'react'
import { context } from '../../context/context';
import {  useNavigate, useLocation, Link } from "react-router-dom";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { userLogout, getEventList, getProfile, getUserEventList, getSocket, getUnreadMessagesNotification, setMessages } from "../../store/slices/userSlice"
import ChangePassword from '../ChangePassword';
import Modal from 'react-modal';
import LanguageSwitcher from './LanguageSwitcher';
import { useTranslation } from 'react-i18next';
import i18n from '../i18n';

const customStyles = {
  content: {
    display: "block",
    '--bs-modal-width': '30%',
  },
};
Modal.setAppElement('#root');


const Nav = () => {
  const { t } = useTranslation();
  const location=useLocation();
  const { SetToggleButton } = useContext(context);
  const [modalIsOpen, setIsOpen] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const socket = useSelector(getSocket);
    const eventList = useSelector(getEventList);
    const unread = useSelector(getUnreadMessagesNotification);

    const [isScrolled, setIsScrolled] = useState(false);
    useEffect(() => {
      const handleScroll = () => {
        const scrolled = window.scrollY > 10;
        setIsScrolled(scrolled);
      };
  
      window.addEventListener('scroll', handleScroll);
  
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);
    
    console.log('eventLisssssssst', eventList)
    
    
    const profile=useSelector(getProfile)  


    const [eventNames,setEventNames]=useState([])
    const [load,setload]=useState(false)
    const [messageNotifications,setmessageNotifications]=useState([])

    function openModal() {
      setIsOpen(true);
    }
  
    function closeModal() {
      setIsOpen(false);
    }


    const GetEventNames = async () => {
        try {
            setEventNames(null);
          const response = await dispatch(getUserEventList()).unwrap();
          console.log("abdddddddddddddc", response?.data);
          setEventNames(response?.data);
        } catch (rejectedValueOrSerializedError) {
          console.log(rejectedValueOrSerializedError);
        }
      };

   


const handleLogout=async()=>{
    try {

        await dispatch(userLogout()).unwrap()
        navigate(profile?.role=="customer"?"/login":"/vendor/login")
      
      } catch (rejectedValueOrSerializedError) {
        console.log(rejectedValueOrSerializedError)
      }
}


const callMessage = () => {
  
  setload(true)
  socket?.emit("checknotification", {
    user_id: profile?._id, 
    role: profile?.role, 
  });
  
  socket?.on("response", (data) => {
    console.log("aaaaaaa", data);
    if (data?.object_type == "checknotification") {
      console.log('data?.data', data?.data)
    // setmessageNotifications(data?.data);
     dispatch(setMessages({data: data?.data}))
    }
});
  
  setload(false)
};

const handleClick=(e,c)=>{
  e.preventDefault();
  console.log("ttttttttttttttttt")

  // callMessage()

  
  if(!load){
  navigate("/chat", {
    state: {  data: c },
  });
}
  
}


useEffect(() => {
  
  
  callMessage();
  
  
}, [profile]);


  


console.log('unreadddddddddddddddd', unread)
const currentLanguage = i18n.language;

  return (
    <>
<header className={`${isScrolled ? 'wf-nav-sticky' : ''}`}>
  <div className="container">
  <nav className={`navbar navbar-expand-lg navbar-light pt-4 pb-4 wf-user-nav`}>

<div class="container-fluid">
  <Link class="navbar-brand mt-2 mt-lg-0" to={`${process.env.REACT_APP_FrontURL}`}>
    <img className="img-fluid" src={`${process.env.REACT_APP_FrontURL}/assets/images/${currentLanguage === 'ar' ? 'logo-arabic.png' : 'logo.svg'}`} alt="Weddified" width={currentLanguage === 'ar' ? '180px' : '200px'} />
  </Link>
  <Link class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <i class="bi bi-list"></i>
  </Link>

    <div className="collapse navbar-collapse navbar-light" id="navbarSupportedContent">
    <ul class="navbar-nav dashboard-nav m-lg-auto mb-2 mb-lg-0" style={{"gap":"15px"}}>
    <li class="nav-item">
        <Link className={location?.pathname=="/suppliers"?"nav-link active":"nav-link"} to="/suppliers">
        <img src="../assets/images/suppliers-icon-wf.svg" alt="" className="nav-image" />
        {t('Suppliers')}
        </Link>
      </li>
      <li class="nav-item">
        <Link className={location?.pathname=="/suppliers/venue"?"nav-link active":"nav-link"} to="/suppliers/venue">
        <img src="../assets/images/venue-icon-wf.svg" alt="" className="nav-image" />
        {t('Venues')}
        </Link>
      </li>
      <li class="nav-item">
        <Link className={location?.pathname=="/dashboard"?"nav-link active":"nav-link"} to="/dashboard">
        <img src="../assets/images/dashboard-icon-wf.svg" alt="" className="nav-image" />
        {t('Dashboard')}
        </Link>
      </li>
      <li class="nav-item">
        <Link className={location?.pathname=="/task-management"?"nav-link active":"nav-link"} to="/task-management">
        <img src="../assets/images/task-icon-wf.svg" alt="" className="nav-image" />
          {t('Task')}
          </Link>
      </li>
      <li class="nav-item">
        <Link className={location?.pathname=="/budget"?"nav-link active":"nav-link"} to="/budget">
        <img src="../assets/images/budget-icon-wf.svg" alt="" className="nav-image" />
        {t('Budget')}
        </Link>
      </li>
      <li class="nav-item">
        <Link className={location?.pathname=="/guest-list"?"nav-link active":"nav-link"} to="/guest-list">
        <img src="../assets/images/guest-icon-wf.svg" alt="" className="nav-image" />
          {t('Guests')}
          </Link>
      </li>
      <li class="nav-item">
        <Link className={location?.pathname=="/short-list"?"nav-link active":"nav-link"} to="/short-list">
        <img src="../assets/images/shortlist-icon-wf.svg" alt="" className="nav-image" />
        {t('Shortlist')}
        </Link>
      </li>
      <li class="nav-item">
        <Link className={location?.pathname=="/bookings"?"nav-link active":"nav-link"} to="/bookings">
        <img src="../assets/images/bookings-icon-wf.svg" alt="" className="nav-image" />
          {t('Bookings')}
          </Link>
      </li>
      <li class="nav-item">
        <Link className={location?.pathname=="/ideas-inspiration"?"nav-link active":"nav-link"} to="/ideas-inspiration">
        <img src="../assets/images/inspire-icon-wf.svg" alt="" className="nav-image" />
          {t('Inspire')}
          </Link>
      </li>
    </ul> 
    <div class="d-flex align-items-center" style={{"gap":"25px"}}>


  <div class="dropdown custom-dropdown notification">
    <Link class="dropdown-link" role="button" data-bs-toggle="dropdown" aria-expanded="false">
      <i class="far fa-bell icon-notifications"></i>
      <span class="number">5</span>          
    </Link>
    <div class="dropdown-menu" >
      <div class="d-flex align-items-center px-2 py-1">
        <p class="font-size-16 dm-font purple-color mb-0">{t('Notifications')}</p>
      </div>

      <ul class="custom-notifications">
        <li class="unread">
          <Link to="#" class="d-flex align-items-center">
            <div class="img mr-3">
              <img src="https://via.placeholder.com/50x50" alt="Image" class="img-fluid"/>
            </div>
            <p class="text mb-0"><span className="font-size-14 pink-color dm-font">Claudia Gideon</span><br/> <span>marked the task done a day ago</span></p>
            <span className="notification-date">Tue, 12:30am</span>
          </Link>
        </li>

        <li class="">
          <Link to="#" class="d-flex align-items-center">
            <div class="img mr-3">
              <img src="https://via.placeholder.com/50x50" alt="Image" class="img-fluid"/>
            </div>
            <p class="text mb-0"><span className="font-size-14 pink-color dm-font">Claudia Gideon</span><br/> <span>marked the task done a day ago</span></p>
            <span className="notification-date">Tue, 12:30am</span>
          </Link>
        </li>
        <li class="">
          <Link to="#" class="d-flex align-items-center">
            <div class="img mr-3">
              <img src="https://via.placeholder.com/50x50" alt="Image" class="img-fluid"/>
            </div>
            <p class="text mb-0"><span className="font-size-14 pink-color dm-font">Claudia Gideon</span><br/> <span>marked the task done a day ago</span></p>
            <span className="notification-date">Tue, 12:30am</span>
          </Link>
        </li>
        <li class="">
          <Link to="#" class="d-flex align-items-center">
            <div class="img mr-3">
              <img src="https://via.placeholder.com/50x50" alt="Image" class="img-fluid"/>
            </div>
            <p class="text mb-0"><span className="font-size-14 pink-color dm-font">Claudia Gideon</span><br/> <span>marked the task done a day ago</span></p>
            <span className="notification-date">Tue, 12:30am</span>
          </Link>
        </li>
        <li class="">
          <Link to="#" class="d-flex align-items-center">
            <div class="img mr-3">
              <img src="https://via.placeholder.com/50x50" alt="Image" class="img-fluid"/>
            </div>
            <p class="text mb-0"><span className="font-size-14 pink-color dm-font">Claudia Gideon</span><br/> <span>marked the task done a day ago</span></p>
            <span className="notification-date">Tue, 12:30am</span>
          </Link>
        </li>

      </ul>
    </div>
  </div>


    <div class="wf-profile dropdown ml-2">
            
      <Link class="d-flex align-items-center gap-2" to="#" role="button">
      {/* <p class="font-size-14 dm-font purple-color mb-0">{profile?.name}</p> */}
      <div className="position-relative rounded-circle">
      <img width="60" alt="avatar" src={profile?.imageName?`${process.env.REACT_APP_IMGURL}${profile?.imageName}`:"https://via.placeholder.com/50x50"} class="rounded-circle avatar" />
      {unread?.length>0?(<span class="badge pink-bg color-white rounded-circle position-absolute bottom-0 wf-profile-badge">{unread?.length}</span>):null}  
      </div>
      </Link> 
        
      <div class="dropdown-menu pb-2" aria-labelledby="dropdownUser">
      <div class="d-flex align-items-center gap-2 px-2" >
      <div className="position-relative rounded-circle">
      <img width="56" alt="avatar" src={profile?.imageName?`${process.env.REACT_APP_IMGURL}${profile?.imageName}`:"https://via.placeholder.com/50x50"} class="rounded-circle avatar" />
      </div>
      <p class="font-size-16 dm-font pink-color mb-0">{profile?.name}</p>
      </div> 
      <div style={{'--bs-dropdown-divider-bg': '#e7e7e7'}} class="dropdown-divider"></div>
      <ul class="list-unstyled mb-0">
        <li><Link className="dropdown-item" to="/chat">
        <i class="bi bi-envelope"></i> {t('Messages')} {unread?.length>0?(<span class="badge pink-bg color-white rounded-circle position-absolute end-0 mx-2">{unread?.length}</span>):null} 
        </Link></li>
        <li><a className="dropdown-item" onClick={()=>navigate(profile?.role=="customer"?"/settings":"/vendor/settings")}>
        <i class="bi bi-person"></i> {t('EditProfile')}
        </a></li>
        <li><Link className="dropdown-item" onClick={openModal}>
        <i class="bi bi-gear"></i> {t('ChangePassword')}
        </Link></li>
        <li><a className="dropdown-item" >
        <i class="bi bi-translate"></i> <LanguageSwitcher />
        </a></li>
        <li><Link className="dropdown-item" >
        <i class="bi bi-question-circle"></i> {t('Help&Support')}
        </Link></li>
        <li><Link className="dropdown-item" onClick={handleLogout}>
        <i class="bi bi-box-arrow-right"></i> {t('Logout')}
        </Link></li>
      </ul>
      </div>
    </div>

  </div>
  </div>

  

</div>

</nav>
  </div>
</header>


  <Modal
        closeTimeoutMS={500}
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Change Password"
        className="modal fade show wf-modal"
      >
        <ChangePassword closeModal={closeModal} />
      </Modal>
     
      </>
  )
}

export default Nav