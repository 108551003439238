import React from 'react'

const VendorCardSkeletonHome = () => {
  return (
    <>
        <div className="vendor-card col-md-3">
          <div className="vendor-card-body">
            <div className="vendor-image w-100 shimmer"></div>
          </div>
          <div className="vendor-card-footer">
          <div style={{width:'100%', height:'22px'}} className="shimmer rounded w-100 mb-1"></div>
            <div className="d-flex justify-content-between align-items-center">
            <div style={{width:'166px', height:'24px'}} className="shimmer rounded"></div>
            <div style={{width:'56px', height:'24px'}} className="shimmer rounded"></div>
            </div>
          </div> 
        </div>
    </>
  )
}

export default VendorCardSkeletonHome