import React, { useEffect, useState } from "react";
import { PinOff } from 'lucide-react';
import { useDispatch, useSelector } from "react-redux";

import {

  getMyBlogs,
  removeFromPin

} from "../../store/slices/userSlice";
import { useTranslation } from "react-i18next";

const SavedPins = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [blogs,setblogs]=useState([]);


  const getBlogs = async () => {
    try {
      setblogs(null);
      const response = await dispatch(
        getMyBlogs()
      ).unwrap();
      console.log("abc1", response?.data);
      setblogs(response?.data?response?.data:[]);
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };


  const UnpinBlog= async (id)=>{
    console.log('idddddddddd', id)
   const del= await dispatch(
      removeFromPin({id: id})
    ).unwrap();

    if(del){
      
      getBlogs()
    
    }
  }

  useEffect(() => {
    let mount = true;
    if (mount) {
      getBlogs();
    }
    return () => {
      mount = false;
    };
  }, [dispatch]);


  return (
    <section className="wf-saved-pins px-5 pb-5 pt-2"> 
    <div className="">
    <h2 className="font-size-26 dm-font purple-color mb-4">{t('Savedpins')}</h2>
    <div className="masnory-container">

    {blogs?.length>0?blogs?.map((b)=>(<div className="img-box">
     <img className="wf-shadow-2" src={`${b?.imageName}`}alt={'Image'} loading="lazy" />
      <div className="pin-off-icon" onClick={()=>UnpinBlog(b?._id)}>
        <PinOff />
      </div>
      </div>)):(<p className="text-center mb-5 mt-5 dm-font font-size-22 purple-color">No saved pins available.</p>)}
  </div>  
  </div>
</section>
  )
}

export default SavedPins