import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getQuestionsWeb, getProfile } from "../../store/slices/userSlice";
import { useTranslation } from "react-i18next";



const SignUpStep4 = ({ formData, handleFormUpdate}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const profile = useSelector(getProfile);
 
  const [questions, setQuestions] = useState(null);
  const [load, setload] = useState(false);

  const Questions = async () => {
    try {
      setload(true);
      setQuestions(null);
      const response = await dispatch(
        getQuestionsWeb(formData.prefered_services)
      ).unwrap();
      console.log("abc", response?.data);
      setload(false);
      if (response?.data.length < 1) {
        handleFormUpdate({ isquestion: true, page: formData.page+1 })
      }else{
        handleFormUpdate({ isquestion: false })
        console.log('response.dataaaaaaaaa', response.data)
        setQuestions(response?.data);
      }
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  // const handleSkillChange = (event, id) => {
   
    
     

  //     if (
  //       !formData.sub_services.some(
  //         (obj) =>
  //           obj.item === event && obj._id === id
  //       )
  //     ) {
  //       console.log('trueeeeeeeeeeeeeeeee')
  //       handleFormUpdate({
  //         sub_services: [
  //           ...formData.sub_services,
  //           { _id: id, item: event },
  //         ],
  //       });
      
  //   } else {
  //     handleFormUpdate({
  //       sub_services: formData.sub_services.filter(
  //         (obj) =>
  //           !(obj.item === event && obj._id === id)
  //       ),
  //     });
  //   }
  // };


  const handleSkillChange = (event, id, type,removeradioids) => {

    let updatedSubServices = [...formData.sub_services];


    if (type === "radio") {
      const existingObjectIndex = updatedSubServices.findIndex((obj) => obj._id === id);
  
      if (existingObjectIndex !== -1) {
          let idsFinal = removeradioids?.map((id)=>id._id);
          const ids=idsFinal.filter((service) => service !== event);
  
          const existingObject = updatedSubServices[existingObjectIndex];
  
          
          existingObject.item = existingObject.item.filter(itemId => !ids.includes(itemId));
  
      
          if (!existingObject.item.includes(event)) {
              existingObject.item.push(event);
          }
  
         
          if (existingObject.item.length === 0) {
              updatedSubServices.splice(existingObjectIndex, 1);
          }
      } else {
          console.log('yesssssssss')
          updatedSubServices.push({ _id: id, item: [event] });
      }
  
      console.log('updatedSubServices', updatedSubServices);
  }
  
    
    else{
    const existingObjectIndex = updatedSubServices.findIndex((obj) => obj._id === id);
    
  
    if (existingObjectIndex !== -1) {
     
      const existingObject = updatedSubServices[existingObjectIndex];
      const eventIndex = existingObject.item.indexOf(event);
  
      if (eventIndex !== -1) {
       
        existingObject.item.splice(eventIndex, 1);
  
        
        if (existingObject.item.length === 0) {
          updatedSubServices.splice(existingObjectIndex, 1);
        }
      } else {
        
        existingObject.item.push(event);
      }
    } else {
   
      updatedSubServices.push({ _id: id, item: [event] });
    }
  
  }

    handleFormUpdate({
      sub_services: updatedSubServices,
    });
  };

  

  useEffect(() => {
    let mount = true;
    if (mount) {
      Questions();
    }
    return () => {
      mount = false;
    };
  }, []);


  
  
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  
  console.log('questions', questions,currentQuestionIndex)
 

  return (
    <>
    {load? (<>
      <div className="shimmer rounded mb-2 w-100" style={{height:'22px'}}></div>
      <div class="d-flex gap-2 flex-wrap mb-4">
      {new Array(8).fill(null).map((_, index) => (
            <span key={index} className='shimmer rounded-3 d-inline' style={{height:'100px', width:'100px'}}></span>
      ))}
       </div>
       <div class="d-flex justify-content-center gap-3">
                
                <button class="btn wf-btn-2 wf-purple-outline-btn py-2 px-4 rounded-2">Back</button>
                
                <button class="btn wf-btn-2 wf-purple-btn py-2 px-4 rounded-2" >Save</button>
                
            </div>
    </>) : questions&&currentQuestionIndex<questions?.length?(
      <>
  
          
            
              <p className="font-size-20 dm-font pink-color text-capitalize mb-2">{questions[currentQuestionIndex]?.questionCustomer}</p>

 
              <div class="d-flex gap-2 flex-wrap mb-4">
              {questions[currentQuestionIndex]?.options.map((o) => (
                <>
                <input type={questions[currentQuestionIndex]?.type} checked={formData.sub_services.some((obj) => obj._id === questions[currentQuestionIndex]?._id && obj.item?.includes(o?._id))} onClick={() =>
                  handleSkillChange(o?._id,questions[currentQuestionIndex]?._id,questions[currentQuestionIndex]?.type,questions[currentQuestionIndex]?.options)
                } id={o._id} name="questions" class="btn-check" />
                <label key={o._id} class="btn wf-select-btn d-flex flex-column justify-content-center gap-2" style={{width:'100px'}} for={o._id}>
                  <img src={`${process.env.REACT_APP_IMGURL}${o?.imageName}`} className="mx-auto d-block color" height="35" />
                  <img src={`${process.env.REACT_APP_IMGURL}white-${o?.imageName}`} className="mx-auto d-block white" height="35" />
                  {o.name}</label>
                </>
                ))}
              </div>
              {/* {questions[currentQuestionIndex]?.options.map((o) => (<div class="col text-center">
                <input  type="checkbox" class="btn-check" id={o?.name} name="food-drink" autocomplete="off"/>
                <label class="btn btn-md" for={o?.name}><img src={o.imageName} width="45" /></label>
                <p>{o?.name}</p>
                </div>))} */}
    
              
    <div class="d-flex justify-content-center gap-3">
                
                <button onClick={()=>{if(currentQuestionIndex==questions?.length-1){setCurrentQuestionIndex(currentQuestionIndex-1)}
                   else{ handleFormUpdate({ page: formData.page-1 })
                }}} class="btn wf-btn-2 wf-pink-outline-btn py-2 px-4 rounded-2">Back</button>
                
                <button onClick={() => {
        
                  if(currentQuestionIndex<questions?.length-1){setCurrentQuestionIndex(currentQuestionIndex+1)}
                   else{ handleFormUpdate({ page: formData.page+1 })
                }
                }} class="btn wf-btn-2 wf-purple-btn py-2 px-4 rounded-2" >Save</button>
                
            </div>
  
            </>):null}
    </>
  );
};
export default SignUpStep4;