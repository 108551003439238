import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    addCouple,
    getUserEventList
  } from "../../store/slices/userSlice";
  import { useTranslation } from "react-i18next";
  

const AddCouple = ({closeModal,data,refreshEventList}) => {

    const dispatch= useDispatch()
    console.log('data', data)

    const [id,setid]=useState(data?._id||"")
    const [groomName,setgroomName]=useState(data?.person1?.name||"")
    const [groomEmail,setgroomEmail]=useState(data?.person1?.email||"")
    const [groomPhone,setgroomPhone]=useState(data?.person1?.phone||"")
    const [brideName,setbrideName]=useState(data?.person2?.name||"")
    const [brideEmail,setbrideEmail]=useState(data?.person2?.email||"")
    const [bridePhone,setbridePhone]=useState(data?.person2?.phone||"")
    const [checked,setchecked]=useState(false)

    
      const addPlannerCouple = async () => {

        try {
               
        const abc = await await dispatch(
            addCouple({
                id: id,
                groomName: groomName,
                groomEmail: groomEmail,
                groomPhone: groomPhone,
                brideName: brideName,
                brideEmail: brideEmail,
                bridePhone: bridePhone,
                checked: checked,
              
            })
          ).unwrap();
      
          if(abc){
          await refreshEventList()
          closeModal();
}
      
      } catch (error) {
      // Handle error
      console.error("Error occurred:", error);
      }
      
      
      };
    

 
  return (
    <>

<div class="modal-dialog modal-dialog-centered wf-modal-dialog">
<div className="modal-content">

<div class="modal-header border-0 pb-0">
  <h2 className="font-size-30 color-black dm-font">{!data?"Add Couple":"Edit Couple"}</h2>
  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={()=>closeModal()}></button>
</div>

<div className="modal-body  mt-0">
  <div className="row mb-4">
  <div className="col-md-6">
      <p className="dm-font font-size-20 purple-color mb-1">Groom Detail</p>
      <div class="input-group wf-input-group wf-shadow-2 mb-3">
        <span class="input-group-text purple-color"><i class="far fa-user"></i></span>
        <div class="form-floating wf-input">
          <input type="text" class="form-control" id="Groom-name" placeholder="Your Name" defaultValue={groomName} onChange={(e)=>setgroomName(e.target.value)} />
          <label for="Groom-name">Name</label>
        </div>
      </div>
      <div class="input-group wf-input-group wf-shadow-2 mb-3">
        <span class="input-group-text purple-color"><i class="far fa-envelope"></i></span>
        <div class="form-floating wf-input">
          <input type="email" class="form-control" id="Groom-email-address" placeholder="Email Address" defaultValue={groomEmail} onChange={(e)=>setgroomEmail(e.target.value)} />
          <label for="Groom-email-address">Email Address</label>
        </div>
      </div>
      <div class="input-group wf-input-group wf-shadow-2">
        <span class="input-group-text purple-color"><i class="bi bi-telephone"></i></span>
        <div class="form-floating wf-input">
          <input type="phone" class="form-control" id="Groom-phone" placeholder="Phone" defaultValue={groomPhone} onChange={(e)=>setgroomPhone(e.target.value)} />
          <label for="Groom-phone">Mobile Number</label>
        </div>
      </div>
    </div>
    <div className="col-md-6 mb-3 mb-md-0">
      <p className="dm-font font-size-20 pink-color mb-1">Bride Detail</p>
      <div class="input-group wf-input-group wf-shadow-2 mb-3">
        <span class="input-group-text pink-color"><i class="far fa-user"></i></span>
        <div class="form-floating wf-input">
          <input type="text" class="form-control" id="Bride-name" placeholder="Bride Name" defaultValue={brideName} onChange={(e)=>setbrideName(e.target.value)} />
          <label for="Bride-name">Name</label>
        </div>
      </div>
      <div class="input-group wf-input-group wf-shadow-2 mb-3">
        <span class="input-group-text pink-color"><i class="far fa-envelope"></i></span>
        <div class="form-floating wf-input">
          <input type="email" class="form-control" id="Bride-email-address" placeholder="Email Address" defaultValue={brideEmail} onChange={(e)=>setbrideEmail(e.target.value)} />
          <label for="Bride-email-address">Email Address</label>
        </div>
      </div>
      <div class="input-group wf-input-group wf-shadow-2">
        <span class="input-group-text pink-color"><i class="bi bi-telephone"></i></span>
        <div class="form-floating wf-input">
          <input type="phone" class="form-control" id="Bride-phone" placeholder="Phone" defaultValue={bridePhone} onChange={(e)=>setbridePhone(e.target.value)} />
          <label for="Bride-phone">Mobile Number</label>
        </div>
      </div>
    </div>
    
  </div>
  <div class="form-check wf-signup-checkbox ">
    <input onChange={()=>setchecked(!checked)} class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
    <label class="form-check-label" for="flexCheckDefault">Invite couples to Weddified for collaboration</label>
  </div>
</div>

<div class="modal-footer border-0 justify-content-center gap-2">
  <button class="btn wf-btn-2 wf-pink-btn py-2 px-4 rounded-2" onClick={()=>addPlannerCouple()}>{!data?"Add Couple":"Edit Couple"}</button>
</div>
</div>
</div>
    </>
  )
}

export default AddCouple