import React, { useState, useEffect } from 'react'
import BigCalendar from './BigCalendar';
import SmallCalendar from './SmallCalendar';
import { useLocation } from 'react-router-dom';
import CalendarData from './CalendarData'

const Calendar = () => {
    const location = useLocation();
    const initialDate = location.state?.date || new Date();
    // useEffect(() => {
    //     const body = document.body;
    //     if (location.pathname === '/calendar') {
    //         body.classList.add('overflow-x-hidden');
    //     } else {
    //         body.classList.remove('overflow-x-hidden');
    //     }
    //     // Clean up the class when the component unmounts
    //     return () => {
    //         body.classList.remove('overflow-x-hidden');
    //     };
    // }, [location.pathname]);

    const [selectedDate, setSelectedDate] = useState(initialDate);
    const [eventType, setEventType] = useState(''); // Default to show all events
    const [toggle, settoggle] = useState(true);

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };
    const handleEventTypeChange = (type) => {
        setEventType(type);
    };
    const events = CalendarData();
    const filteredEvents = events.filter(event => eventType === '' || event.type === eventType);

    console.log("TalhaCalendi", filteredEvents)

    return (
        <>
            <section className='wf-user-dashboard wf-background px-md-5 pb-5 pt-2'>
                <div className='container-fluid'>
                    <div className='row mb-4'>
                        <div className='col-12'>
                            <div class="d-flex gap-4 align-items-end mb-2">
                                <h2 class="font-size-40 dm-font color-black mb-0">Calendar</h2>
                            </div>

                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-xl-3 col-lg-12'>
                            <div className='row mb-4'>
                                <div className='col-12'>
                                    <div className='Wediffied-Calender'>
                                        <SmallCalendar selectedDate={selectedDate} onDateChange={handleDateChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-4">
                                <div className="col-12">
                                    <div className='font-size-16 color-black d-flex justify-content-between mb-3'>
                                        <span>My Calendars</span>
                                        <span className='cursor-pointer fw-bold color-black'
                                            onClick={() => settoggle(!toggle)}
                                        >
                                            {toggle ? (
                                                <i className="bi bi-chevron-up me-3"></i>
                                            ) : (
                                                <i className="bi bi-chevron-down me-3"></i>
                                            )}
                                        </span>
                                    </div>
                                    <div className={`wf-calender-filter ${toggle && "show"}`}>
                                        <div className="d-flex justify-content-md-start gap-3">
                                            <button
                                                className="btn wf-btn-2 py-2 px-3 rounded-2 wf-shadow-2 wf-pink-btn"
                                                onClick={() => handleEventTypeChange('')}
                                            >
                                                All
                                            </button>
                                            <button class="btn wf-btn-2 py-2 px-3 rounded-2 wf-shadow-2 wf-purple-btn"
                                                onClick={() => handleEventTypeChange('Event')}
                                            >Events</button>
                                            <button class="btn wf-btn-2 py-2 px-3 rounded-2 wf-shadow-2 wf-black-btn"
                                                style={{ backgroundColor: "black", color: "white" }}
                                                onClick={() => handleEventTypeChange('Task')}
                                            >Tasks</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-xl-9 col-lg-12'>
                            <BigCalendar
                                selectedDate={selectedDate}
                                onDateChange={handleDateChange}
                                events={filteredEvents}
                                eventType={eventType}
                                onEventTypeChange={handleEventTypeChange}

                            />
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Calendar