import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import {
  getEventList,
} from "../../store/slices/userSlice";
import { useTranslation } from "react-i18next";
import TaskContent from "./TaskContent";
import NoEventUI from "./noEventUI";


const TaskList_Dashboard = () => {
  const { t } = useTranslation();
  const myEvents = useSelector(getEventList);
  const navigate = useNavigate();

  return (
    <>
      <div className="wf-tasklist-dashboard mb-3">
        <div className="d-flex gap-4 align-items-end mb-2">
          <h2 className="font-size-30 dm-font purple-color mb-0">Tasks</h2>
          {myEvents?.some(event => event?.Event?.eventTasks?.length > 0) && (<a className="font-size-14 pink-color mb-0" href="/task-management"><u>{t('ViewAll')}</u></a>)}
        </div>
        {myEvents?.some(event => event?.Event?.eventTasks?.length > 0) ? (
          <TaskContent />
        ) : (
          <NoEventUI name={"Tasks"} />
        )}
      </div>
    </>
  );
};

export default TaskList_Dashboard;