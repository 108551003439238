import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../../src/components/Spinner";
import Header from "./HeaderLight";
import Footer from "./Footer";
import { Helmet } from "react-helmet";
import moment from "moment";
import axios from "axios";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import VendorCardSkeleton from "../LoadingSkeleton/VendorCardSkeleton";
import { getProfile } from "../../store/slices/userSlice";
import BlogCardSkeleton from "../LoadingSkeleton/BlogCardSkeleton";
import BlogTemplateSkeleton from "../LoadingSkeleton/BlogTemplateSkeleton";
import IdeasInspirationSkeleton from "../LoadingSkeleton/IdeasInspirationSkeleton";
import { useTranslation } from "react-i18next";

const IdeasInspiration = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const profile = useSelector(getProfile);

  const queryParams = new URLSearchParams(location.search);
  const savedLanguage = localStorage.getItem("selectedLanguage");
  const div1 = useRef(null);
  const div2 = useRef(null);
  const [content, setcontent] = useState(true);

  //get initial data
  const [categories, setcategories] = useState([]);
  const [contentType, setcontentType] = useState([]);
  const [tags, settags] = useState([]);
  const [routename, setroutename] = useState("");

  //data to filterrrrrrr
  const [cat, setcat] = useState([]);
  const [contype, setcontype] = useState([]);
  const [mytags, setmytags] = useState([]);
  const [catname, setcatname] = useState("");
  const [contypename, setcontypename] = useState("");
  const [mytagsname, setmytagsname] = useState("");
  const [mytagsid, setmytagsid] = useState("");
  const [load, setload] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const [sortby, setsortby] = useState("latest");

  //data achieved
  const [blogs, setblogs] = useState([]);
  const [click, setclick] = useState(false);

  //pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, settotalPages] = useState("");
  const [res, setres] = useState("");
  const [startResult, setStartResult] = useState(0);
  const [endResult, setEndResult] = useState(0);

  const pathname = window.location.pathname;
  const pathSegments = pathname.split("/").filter((segment) => segment !== "");

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchValue(value);
    if (value.length > 0) {
      setShowDropdown(true);
    } else {
      setShowDropdown(false);
    }
  };

  const IncrementBlogView = async (blog_id) => {
    try {
      await axios.post(`vendorpage/incrementblogview`, {
        blog_id: blog_id,
      });
    } catch (error) {
      console.error("Error incrementing view:", error);
    }
  };

  const handleOptionClick = (t) => {
    setSearchValue("");
    setmytagsid([t._id]);
    setmytagsname(t);
    setShowDropdown(false);
  };

  const getAllData = async () => {
    try {
      
        const [response1, response2, response3] = await Promise.all([
          axios.get(`user/getservices`, { params: { lang: savedLanguage } }),
          axios.get(`user/getcontenttype`, { params: { lang: savedLanguage } }),
          axios.get(`admin/getalltags`, { params: { lang: savedLanguage } }),
        ]);

        setcategories(response1?.data?.data);
        setcontentType(response2?.data?.data);
        settags(response3?.data?.data);
        
        getinitData(
          response1?.data?.data,
          response2?.data?.data,
          response3?.data?.data
        );
      
    } catch (rejectedValueOrSerializedError) {}
  };

  const Details = async (page, init = false) => {
    try {
      if (!content) {
       
        const response = await axios.get(`vendorpage/getfilterblogs`, {
          params: {
            user_id: profile ? profile?._id : "",
            page: page,
            tags:
              mytags?.length > 0
                ? JSON.stringify(mytags)
                : mytagsid?.length > 0
                ? JSON.stringify(mytagsid)
                : [],
            blog_category_id: JSON.stringify(contype),
            category_id: JSON.stringify(cat),
            sortby: sortby,
            lang: savedLanguage,
          },
        });

        console.log("abc", response);

        const {
          results: newResults,
          totalResults: newTotalResults,
          startResult: startResult,
          endResult: endResult,
        } = response?.data;

        setres(newTotalResults);
        setStartResult(startResult);
        setEndResult(endResult);

        if (!init) {
          setblogs((prevResults) => [...prevResults, ...newResults]);
        } else {
          setblogs(newResults);
        }
        const itemsPerPage = 18;
        const totalPages = Math.ceil(newTotalResults / itemsPerPage);
        settotalPages(totalPages);
        //   setPage(prevPage => prevPage + 1);
        setload(false);
        setcontent(true);
      }
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };



  const handleScroll = () => {
    div2.current.scrollIntoView({ behavior: "smooth" });
  };

  console.log("routename", routename);

  const NavigateClick = () => {
    setclick(!click);
    setmytags(mytagsid);

    let path = "";

    if (catname && contypename) {
      path = `/ideas-inspiration/${contypename?.slug}/${catname?.slug}`;
    } else if (catname && !contypename) {
      path = `/ideas-inspiration/${catname?.slug}`;
    } else if (!catname && contypename) {
      path = `/ideas-inspiration/${contypename?.slug}`;
    } else {
      path = `/ideas-inspiration`;
    }

    if (path) {
      console.log("mytagsname", mytagsname);

      if (mytagsname !== "") {
        const queryString = mytagsname?.slug;
        console.log("queryString", queryString);
        const newPath = `${path}?tags=${queryString}`;
        navigate(newPath.replace(/\s+/g, "-"));
      } else {
        navigate(path.replace(/\s+/g, "-"));
      }
    }
  };

  const removeTagsFromURL = () => {
    const filters = {};
    const queryParams = new URLSearchParams(window.location.search);
  
    const tagsfilterFromUrl = queryParams.get("tags");
    const pagefilterFromUrl = queryParams.get("page");
    const sortbyfilterFromUrl = queryParams.get("sortby");
  
    if (tagsfilterFromUrl) {
      if (tagsfilterFromUrl.includes(",")) {
        const tagsArray = tagsfilterFromUrl.split(",");
        setmytags(tagsArray);
      } else {
        setmytags([tagsfilterFromUrl]);
      }
    }
  
    // Handle 'page' filter
    if (pagefilterFromUrl) {
      filters.page = pagefilterFromUrl;
    }
  
    // Handle 'sortby' filter
    if (sortbyfilterFromUrl && sortby !== "") {
      filters.sortby = sortbyfilterFromUrl;
    }
  
    // Remove empty filters from the URL
    const nonEmptyFilters = Object.keys(filters)
      .map((key) => `${key}=${filters[key]}`)
      .join("&");
  
    const newSearch = nonEmptyFilters ? `?${nonEmptyFilters}` : "";
  
    // Update the URL with the non-empty filters
    const newUrl = `${location.pathname}${newSearch}`;
    window.history.replaceState({}, '', newUrl);
  };
  

  const getinitData = (catt, contypee, taggs) => {
    setcontent(true);
    setload(true)

    //get tags query params
    const tagsfilterFromUrl = queryParams.get("tags");
    const pagefilterFromUrl = queryParams.get("page");
    const sortbyfilterFromUrl = queryParams.get("sortby");

    if (tagsfilterFromUrl?.length > 0) {
      console.log("inside");
      if (tagsfilterFromUrl.includes(",")) {
        console.log("sssssss");
        const countryArray = tagsfilterFromUrl.split(",");
        setmytags(countryArray);
      } else {
        console.log("ssa");
        const catImage = taggs?.find((c) => c.slug == tagsfilterFromUrl);
        console.log("catImage", catImage);
        if (catImage) {
          setmytagsname(catImage);
          setmytagsid([catImage?._id]);
          setmytags([catImage?._id]);
        }
      }
    }
    if (pagefilterFromUrl) {
      setCurrentPage(parseInt(pagefilterFromUrl, 10));
    }
    if (sortbyfilterFromUrl) {
      const lc = queryParams.get("sortby").toLowerCase();
      if (lc.includes(",")) {
        const sortbyArray = lc.split(",");
        console.log("sortbyArray", sortbyArray);
        setsortby(sortbyArray.toLowerCase());
      } else {
        setsortby(lc);
      }
    }

    if (pagefilterFromUrl !== null) {
      setCurrentPage(parseInt(pagefilterFromUrl, 10));
    }

    // for /ideas-inspiration
    if (pathSegments.length == 1 && pathSegments[0] === "ideas-inspiration") {
      setcat([]);
      setcontype([]);
      setmytags([]);
      setcatname("");
      setcontypename("");
    } else {
      // for /ideas-inspiration/something
      if (pathSegments.length == 2 && pathSegments[0] === "ideas-inspiration") {
        const categoryExists = catt?.some((obj) => obj.slug == pathSegments[1]);

        if (categoryExists) {
          const catImage = catt?.find((c) => c.slug == pathSegments[1]);

          if (pathSegments[1].toLowerCase() !== catImage?.slug) {
            window.location.href = `/ideas-inspiration/${pathSegments[1].toLowerCase()}}`;
          }

          console.log("catImage", catImage);

          setcat([catImage?._id]);
          setcatname(catImage);
          setcontype([]);
          setcontypename("");
        } else {
          const catImage = contypee?.find((c) => c.slug == pathSegments[1]);

          if (pathSegments[1].toLowerCase() !== catImage?.slug) {
            window.location.href = `/ideas-inspiration/${pathSegments[1].toLowerCase()}`;
          }

          console.log("catImage", contypee, pathSegments[1]);

          if (catImage) {
            setcontype([catImage?._id]);
            setcontypename(catImage);
          }
          setcat([]);
          setcatname("");
          // setsortby("")
        }
      }

      // for /ideas-inspiration/blogType/category
      else if (
        pathSegments.length == 3 &&
        pathSegments[0] === "ideas-inspiration"
      ) {
        const catImage = catt?.find((c) => c.slug == pathSegments[2]);
        const catImage1 = contypee?.find((c) => c.slug == pathSegments[1]);

        setcat([catImage?._id]);
        setcatname(catImage);
        setcontype([catImage1?._id]);
        setcontypename(catImage1);
      }
    }
    setcontent(false);
  };

  //get static data
  useEffect(() => {
    if (
      pathSegments?.length == 2 &&
      pathSegments[1]?.toLowerCase() !== pathSegments[1]
    ) {
      console.log('yes')
      window.location.href = `/ideas-inspiration/${pathSegments[1]?.toLowerCase()}}`;
    }  if (
      (pathSegments?.length == 3 &&
        pathSegments[1]?.toLowerCase() !== pathSegments[1]) ||
        pathSegments[2]?.toLowerCase() !== pathSegments[2]
        ) {
      console.log('yes1')
      window.location.href = `/ideas-inspiration/${pathSegments[1]?.toLowerCase()}/${pathSegments[2]?.toLowerCase()}`;
    } 

    getAllData();
  }, [click]);

  useEffect(() => {
    Details(currentPage, true);
    // removeTagsFromURL()
  }, [cat, contype, mytags, sortby, currentPage]);

  // useEffect(() => {
  //   removeTagsFromURL();
  // }, []); //if issue then comment it

  useEffect(() => {
    renderPaginationLinks();
  }, [totalPages]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, [currentPage]);

  useEffect(() => {
    const handlePopState = (event) => {
      console.log("Back button clicked, URL changed to: " + document.location);

      window.history.back();
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  const handlePageChange = (page) => {
    setCurrentPage(page);

    queryParams.set("page", page);
    navigate(`${location.pathname}?${queryParams.toString()}`);
  };

  console.log("catsaasssss", cat);

  const renderPaginationLinks = () => {
    const pages = [];
    const maxPagesToShow = 5; // You can adjust this value based on your preference

    if (totalPages <= maxPagesToShow) {
      // If there are fewer pages than the maximum to show, display all pages
      for (let page = 1; page <= totalPages; page++) {
        pages.push(renderPageLink(page));
      }
    } else {
      // Calculate the range of pages to display based on the current page
      let startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
      const endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);

      // Adjust startPage if it goes beyond the total number of pages
      if (endPage === totalPages) {
        startPage = Math.max(1, endPage - maxPagesToShow + 1);
      }

      // Display the first page
      pages.push(renderPageLink(1));

      // Display '...' if there are more pages to the left of the current page and the first page is not included
      if (startPage > 2) {
        pages.push(renderEllipsis());
      }

      // Display pages in the calculated range, excluding page 1 if it's not the first page
      for (
        let page = startPage === 1 ? 2 : startPage;
        page <= endPage;
        page++
      ) {
        pages.push(renderPageLink(page));
      }

      // Display '...' if there are more pages to the right of the current page and the last page is not included
      if (endPage < totalPages - 1) {
        pages.push(renderEllipsis());
      }

      // Display the last page only if it's not already included
      if (endPage !== totalPages) {
        pages.push(renderPageLink(totalPages));
      }
    }

    return pages;
  };

  const renderPageLink = (page) => (
    <li
      key={page}
      className={`page-item ${currentPage === page ? "active" : ""}`}
    >
      <a className="page-link" onClick={() => handlePageChange(page)}>
        {page}
      </a>
    </li>
  );

  const renderEllipsis = () => (
    <li key="ellipsis" className="page-item disabled">
      <span className="page-link">...</span>
    </li>
  );

  console.log("tags", tags);
  console.log("catname", catname);
  console.log("contype", contype);
  console.log("mytags", mytags);
  console.log("mytagname", mytagsname);
  console.log("searchValue", searchValue);

  return (
    <>
      <Helmet>
        <title>Wedding Inspiration - Explore Unique Ideas with Weddified</title>
        <meta
          name="description"
          content="From trending themes to innovative decor, Weddified's Ideas & Inspiration section has it all. Fuel your wedding planning creativity and bring your vision to life."
        />
        <meta name="keywords" content="Weddified, wedding planning" />
      </Helmet>
      <section class="archives-hero ideas-inspiration">
        <div className="archive-img">
          <img
            src={`${process.env.REACT_APP_FrontURL}/assets/images/ideas-inspiration-background.jpg`}
            className="w-100"
            alt=""
          />
        </div>
        <Header theme="light" />
        <div class="container pb-3">
          <div className="pt-5 pb-5 ">
            <h1 class="h1-title dm-font color-white text-center">
              {t("GetIdeas&Inspirations")}
            </h1>
            <div className="search-wrapper search-wrapper-2">
              <div className="search-field-1 search-field-input">
                <div class="dropdown">
                  <div class="input-group search-field-input-group">
                    <input
                      type="text"
                      class="form-control"
                      disabled={mytagsname ? true : false}
                      placeholder={t("SelectTag")}
                      value={mytagsname?.tag ? mytagsname?.tag : searchValue}
                      onChange={handleInputChange}
                    />
                    <button
                      onClick={() => {
                        setmytagsid([]);
                        setmytagsname("");
                        setSearchValue("");
                      }}
                      class="input-group-text"
                      id="basic-addon1"
                    >
                      <i className="bi bi-x-circle"></i>
                    </button>
                  </div>
                  {showDropdown && (
                    <div
                      className="dropdown-menu show"
                      style={{ width: "100%" }}
                    >
                      <div className="list-autocomplete">
                        {tags
                          ?.filter((option) =>
                            option?.tag?.includes(searchValue)
                          )
                          .map((option, index) => (
                            <button
                              key={index}
                              type="button"
                              className="dropdown-item"
                              onClick={() => handleOptionClick(option)}
                            >
                              {option.tag}
                            </button>
                          ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="search-field-2">
                <div class="dropdown">
                  <a
                    class="btn btn-secondary dropdown-toggle"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {contypename?.name ? (
                      contypename?.name
                    ) : (
                      <>{t("SelectContentType")}</>
                    )}
                  </a>
                  <ul class="dropdown-menu">
                    <li>
                      <a
                        onClick={() => {
                          // setcontype([]);
                          setcontypename("");
                        }}
                        class="dropdown-item"
                      >
                        {t("SelectContentType")}
                      </a>
                    </li>
                    {contentType?.map((t) => (
                      <li>
                        <a
                          onClick={() => {
                            // setcontype([t._id]);
                            setcontypename(t);
                          }}
                          class="dropdown-item"
                        >
                          {t?.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="search-field-3">
                <div class="dropdown">
                  <a
                    class="btn btn-secondary dropdown-toggle"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {catname?.name ? catname?.name : <>{t("SelectCategory")}</>}
                  </a>
                  <ul class="dropdown-menu">
                    <li>
                      <a
                        onClick={() => {
                          // setcat([]);
                          setcatname("");
                        }}
                        class="dropdown-item"
                      >
                        {t("SelectCategory")}
                      </a>
                    </li>
                    {categories?.map((t) => (
                      <li>
                        <a
                          onClick={() => {
                            // setcat([t._id]);
                            setcatname(t);
                          }}
                          class="dropdown-item"
                        >
                          {t?.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="search-btn" onClick={() => NavigateClick()}>
                <i class="fas fa-search"></i>
              </div>
            </div>
          </div>
        </div>
      </section>
      {load ? (
        <IdeasInspirationSkeleton />
      ) : (
        <>
          <section className="archives-section pt-5 pb-4" ref={div1}>
            <div className="container">
              <div className="row mb-2">
                <div className="col-md-6">
                  <h2 class="font-size-36 dm-font purple-color mb-0">
                    {t("Ideas&InspirationFromOurFinest")}
                  </h2>
                </div>
              </div>
              <div className="row mb-4 gy-3">
                <div className="col-md-6">
                  <p className="font-size-16 color-black mb-0">
                    {res > 0
                      ? `Showing ${startResult} - ${endResult} (Total: ${res})`
                      : `Total: ${res}`}
                  </p>
                </div>
                <div className="col-md-6 d-flex justify-content-md-end">
                  <select
                    className="currency-select sorting"
                    onChange={(e) => {
                      const selectedValue = e.target.value; // Convert to lowercase
                      setsortby(selectedValue);
                      queryParams.set("sortby", selectedValue); // Set lowercase value to query params
                      navigate(
                        `${location.pathname}?${queryParams.toString()}`
                      );
                    }}
                  >
                    <option selected={sortby === "latest"} value="latest">
                      Latest
                    </option>
                    <option selected={sortby === "recent"} value="recent">
                      Recent Updated
                    </option>
                    <option selected={sortby === "popular"} value="popular">
                      Most Popular
                    </option>
                  </select>
                </div>
              </div>

              <div className="row gy-5">
                {load
                  ? new Array(18)
                      .fill(null)
                      .map((_, index) => <BlogCardSkeleton key={index} />)
                  : blogs &&
                    blogs?.map((b) => (
                      <div key={b.id} className="col-lg-4 col-md-6 blog-card">
                        <div className="blog-card-body">
                          <a
                            className="color-black"
                            onClick={()=> IncrementBlogView(b?._id)}
                            href={`/blog/${b?.slug}`}
                            target="_blank"
                          >
                            <img
                              alt={b.title}
                              src={
                                b?.image
                                  ? `${process.env.REACT_APP_IMGURL}${b?.image}`
                                  : "https://via.placeholder.com/415x268"
                              }
                              className="blog-image w-100"
                            />
                          </a>
                          {b.category_id?.name ? (
                            <div className="d-flex align-items-center top-box">
                              <span className="font-size-12 rounded-1 wf-purple-btn py-1 px-3 text-uppercase cursor-pointer">
                                <a
                                  style={{ color: "white" }}
                                  href={`/ideas-inspiration/${b?.category_id?.slug}`}
                                  target="_Blank"
                                >
                                  {b.category_id?.name}
                                </a>
                              </span>
                            </div>
                          ) : null}

                          {b.blog_category_id?.name ? (
                            <div className="d-flex align-items-center bottom-box">
                              <span className="font-size-12 rounded-1 wf-pink-btn py-1 px-3 text-uppercase cursor-pointer">
                                <a
                                  href={`${process.env.REACT_APP_FrontURL}/ideas-inspiration/${b.blog_category_id?.slug}`}
                                >
                                  {b.blog_category_id?.name}
                                </a>
                              </span>
                            </div>
                          ) : null}
                        </div>
                        <div className="blog-card-footer">
                          <div className="row mb-2">
                            <div className="col-6 d-flex align-items-center gap-2">
                              <img
                                width="40"
                                height="40"
                                alt="avatar"
                                src={`${process.env.REACT_APP_IMGURL}w-logo-placeholder.png`}
                                class="rounded-circle wf-shadow-2"
                                style={{ objectFit: "cover" }}
                              />
                              <p className="font-size-14 color-black mb-0 fw-medium mt-1">
                                {b.author}
                              </p>
                            </div>
                            <div className="col-6 d-flex align-items-center justify-content-end gap-4">
                              <p className="font-size-14 color-black mb-0 fw-medium">
                                <i class="bi bi-hand-thumbs-up font-size-18"></i>{" "}
                                {b?.likes}
                              </p>
                              <p className="font-size-14 color-black mb-0 fw-medium">
                                <i class="bi bi-chat font-size-18"></i>{" "}
                                {b?.comments}
                              </p>
                            </div>
                          </div>
                          <h3 className="font-size-18 fw-semibold mb-1">
                            <a
                              className="color-black"
                              onClick={()=> IncrementBlogView(b?._id)}
                              href={`/blog/${b?.slug}`}
                              target="_blank"
                            >
                              {b?.title}
                            </a>
                          </h3>
                          <p className="font-size-16 color-black mb-1">
                            {b.excerpt.substring(0, 64)}...
                          </p>
                          <div className="d-flex align-items-center justify-content-between">
                            <p className="font-size-14 color-black mb-0">
                              {moment(b?.createdAt).format("D MMMM YYYY")}
                            </p>
                            {/* <p className="font-size-14 color-black mb-0">5 mins read</p> */}
                          </div>
                        </div>
                      </div>
                    ))}
              </div>
              <nav aria-label="Page navigation example" className="mt-5">
                <ul class="pagination wf-pagination justify-content-between">
                  <li className="page-item">
                    {!totalPages <= 1 && currentPage > 1 && (
                      <a
                        onClick={() => handlePageChange(currentPage - 1)}
                        className="page-link"
                        aria-label="Previous"
                      >
                        <i className="bi bi-arrow-left"></i>
                      </a>
                    )}
                  </li>

                  <ul class="pagination wf-pagination-2 gap-1">
                    {renderPaginationLinks()}
                  </ul>
                  <li className="page-item">
                    {totalPages > 1 && currentPage !== totalPages && (
                      <a
                        onClick={() => handlePageChange(currentPage + 1)}
                        className="page-link"
                        aria-label="Next"
                      >
                        <i className="bi bi-arrow-right"></i>
                      </a>
                    )}
                  </li>
                </ul>
              </nav>
            </div>
          </section>
        </>
      )}
      <Footer />
    </>
  );
};

export default IdeasInspiration;
