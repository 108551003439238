import React from 'react'
import BookingsContent from './BookingsContent'
import { useNavigate, useLocation } from "react-router-dom";
import NoEventUI from '../UserDashboard/noEventUI';
import {
  getEventList,getProfile
} from "../../store/slices/userSlice";
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const Bookings_Dashboard = () => {

  const { t } = useTranslation();
  const myEvents = useSelector(getEventList);
  const navigate = useNavigate();
  const profile = useSelector(getProfile);

  console.log('profileee', profile)

  return (
    <>
                {/* Row 5 */}
                <div className='row mb-4'>
              <div className='col-12'>
                <div class="d-flex gap-4 align-items-end mb-2">
                  <h2 class="font-size-30 dm-font purple-color mb-0">Bookings</h2>
                  {myEvents?.some(event => event?.Event && event?.Event?.eventBookings?.length > 0) && (<a className="font-size-14 pink-color mb-0" href="/bookings"><u>{t('ViewAll')}</u></a>)}
                </div>
              </div>
            </div>

            {myEvents?.some(event => event?.Event && event?.Event?.eventBookings?.length > 0) ? (
          <BookingsContent />
        ) : (
          <NoEventUI name={"Bookings"} />
        )}
    </>
  )
}

export default Bookings_Dashboard