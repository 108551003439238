
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getProfile } from "../../store/slices/userSlice";
import { useDispatch, useSelector } from "react-redux";

const RequireAuth = ({ user, allowedRoles, children }) => {
  const navigate=useNavigate()

  const profile = useSelector(getProfile);

  console.log('getProfile', profile)

 if(profile&&allowedRoles?.includes(profile.role)){
  return children
 }
 else{
  return navigate("/unauthorized")
 }
};

export default RequireAuth;