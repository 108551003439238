import React, { useEffect, useState } from "react";
import { Settings, Settings2, UserPlus, Search } from 'lucide-react'
import ChatMessage from "./ChatMessage";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { getChats, getProfile, allRead, getSocket, getUnreadMessagesNotification, setMessages } from "../../store/slices/userSlice";
import {  useNavigate, useLocation } from "react-router-dom";
import { io } from "socket.io-client";
import Sidebar from "../../components/Layout/SidebarVendor";
import { ArrowLeft } from "lucide-react";
import { useTranslation } from "react-i18next";
const socket = io(process.env.REACT_APP_APIURL);




const MessageChat = () => {
  const { t } = useTranslation();
  const profile = useSelector(getProfile);
  const navigate = useNavigate();
  const location=useLocation();
  const isDashboard=location.pathname!=="/chat"
  // const socket = useSelector(getSocket);
  const unread = useSelector(getUnreadMessagesNotification);
  const dispatch = useDispatch();
  const [chat, setChat] = useState([]);
  const [searchtext, setsearchtext] = useState("");
  const [filteredData, setfilteredData] = useState();
  const [currentChat, setcurrentChat] = useState();
  const [chattype, setchattype] = useState(location?.state?location?.state?.chattype:"conversation");
  const [load, setload] = useState(true);

  const getAllChats = async (here) => {
    try {
      setChat(null);
      const response = await dispatch(getChats()).unwrap();
      setChat(response?.data)
      setload(false)
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  const updateChatSequence = async (newchat) => {
   
    var prevChats = chat || [];

    console.log('newchat', newchat, prevChats);
    
    var index = prevChats?.findIndex(chat => chat?.group_id === newchat?.group_id);
    console.log('index', index)

    if (index !== -1) {
      
        prevChats[index].message = newchat?.message;
        prevChats[index].createdAt = newchat?.createdAt;
        prevChats[index].messageType = newchat?.messageType;
    } else {
        
        prevChats.push(newchat);
    }

    console.log('prevChats', prevChats);
    
    
    prevChats.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

    
    setChat([...prevChats]); 
};


  console.log('chatcc', chat)

  const handleFilters = (c) => {
    
      if (c == "conversation") {
        setfilteredData(chat?.filter((x) => !x?.group_event?._id));
      }

      if (c == "booking") {
        setfilteredData(chat?.filter((x) => x?.group_event?._id));
      }

      
  
  };

  useEffect(() => {
    
    if(chat?.length>0){
      const findCurrentChat = chat?.filter((f)=>f?.group_id == location?.state?.data)
      console.log('trueee',findCurrentChat)
      if(findCurrentChat?.length>0){
        handleClick(findCurrentChat[0])
      }
    }
    }, [load]);


  useEffect(() => {
    handleFilters(chattype);
  }, [chattype,chat]);

  
  useEffect(() => {
    let mount = true;
    if (mount) {
      getAllChats();     
    }
    return () => {
      mount = false;
    };
  }, []);


  const handleClick = async (c) => {


    
    setcurrentChat(c)

    
    await dispatch(allRead({groupid: c?.group_id})).unwrap();

    var prevChats= filteredData

    var index = prevChats?.findIndex(chat => chat?.group_id === c?.group_id);

if (index !== -1) {
    prevChats[index].chatStatusCount = 0;
    setfilteredData(prevChats)
} 

    // getAllChats(1);

       socket?.emit("checknotification", {
      user_id: profile?._id, 
      role: profile?.role, 
    });
    
    socket?.on("response", (data) => {
      console.log("aaaaaaa", data);
      if (data?.object_type == "checknotification") {
        console.log('data?.datassssssssssssss', data?.data)
        dispatch(setMessages(data?.data))
      // setmessageNotifications(data?.data);
      }
  });



  };

  const handleSearch = () => {
    if (!searchtext) {
    
      handleFilters(chattype)
    } else {
      
      const filters = filteredData?.filter((i) => {
        const namematches = i?.sender_id?.name?.toLowerCase().includes(searchtext?.toLowerCase());
        return namematches;
      });
  
      setfilteredData(filters);
    }
  };

  


  useEffect(() => {
  handleSearch()
  }, [searchtext]);


  useEffect(() => {
    if(location.state){
      
      console.log('location.state.data', location.state.data)
 
  handleClick(location.state.data)
 }
  }, []);






  

 console.log('chat', chat)
 console.log('filteredData', filteredData)
 console.log('unread', unread)

 const [selectedMessage, setSelectedMessage] = useState(null);
 const isMobile = window.innerWidth <= 768; // Set your mobile threshold as needed

 const handleMessageClick = (message) => {
   setSelectedMessage(message);
 };

 const handleBackToMessages = () => {
   setSelectedMessage(null);
 };

 useEffect(() => {
   const handleResize = () => {
     // Reset the selected message when the screen width becomes larger (not mobile)
     if (!isMobile && currentChat) {
       setSelectedMessage(null);
     }
   };

   window.addEventListener('resize', handleResize);

   return () => {
     window.removeEventListener('resize', handleResize);
   };
 }, [isMobile, currentChat]);

 console.log('currentChatttttt', currentChat)

  return (
  
        <section class="wf-chat-enqiries">
    <div class="container-fluid">
        <div class="d-flex overflow-hidden flex-wrap rounded-4">
        <div className={`col-lg-3 col-md-4 grey-bg px-3 border ${isMobile ? 'vh-100' : ''} ${isMobile && selectedMessage ? 'd-none' : 'd-block'}`}>
            <div class="d-flex align-items-center gap-3 mb-0 py-3">
            <div className="purple-color cursor-pointer" onClick={() => { navigate("/dashboard"); }}>
                <ArrowLeft width="22" height="22" />
                </div>
                {/* <div class="dropdown wf-chat-dropdown">
                    <div class="purple-color cursor-pointer" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <Settings2 width="22" height="22" />
                    </div>

                    <div class="dropdown-menu">
                        <p className="font-size-14 mb-0"><a className="pink-color"><u>Mark as read</u></a></p>
                    </div>
                    </div> */}
                    <div class="flex-grow-1 wf-search-chats">
                        <Search width="18" height="18" className="wf-search-chats-icon" />
                        <input type="text" class="form-control wf-search-chats-field" defaultValue={searchtext} 
                           onChange={(e)=>setsearchtext(e.target.value)} placeholder={t('Searchconversations')} />
                    </div>
                
            </div>
            <div className="wf-messages-list-container">
            <ul class="nav mb-3 wf-tab-pills" id="pills-tab" role="tablist">
            <li
              onClick={() => setchattype("conversation")}
              class="nav-item"
              role="presentation"
            >
              <a
                className={`nav-link ${
                  chattype === "conversation" && "active"
                }`}
                id="pills-conversations-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-conversations"
                role="tab"
                aria-controls="pills-conversations"
                aria-selected="true"
              >
               {t('Conversations')} {" "}
               ({chat?.filter((f)=>f?.group_event==null)?.length||0})
              </a>
            </li>
            <li
              onClick={() => setchattype("booking")}
              class="nav-item"
              role="presentation"
            >
              <a
                className={`nav-link ${chattype === "booking" && "active"}`}
                id="`ills-bookings-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-bookings"
                role="tab"
                aria-controls="pills-bookings"
                aria-selected="false"
              >
                 {t('Bookings')} {" "}
                 ({chat?.filter(f => typeof f?.group_event === 'object' && f.group_event !== null).length||0})
              </a>
            </li>
          </ul>
          <div class="tab-content">
          <div class="">
          {filteredData?.slice(0, isDashboard ? 4 : filteredData?.length)?.map((c, i) => (
            <div key={i} class={`wf-message-user-list ${c?.group_id==currentChat?.group_id?"purple-bg":"white-bg"} wf-shadow-2 rounded px-2 py-3 d-flex align-items-center gap-2 cursor-pointer`} onClick={()=>handleClick(c)}>
                <img src={c?.vendor_store?.image? `${process.env.REACT_APP_IMGURL}${c?.vendor_store?.image}`: "https://via.placeholder.com/50x50"} class="rounded-circle wf-user-image" alt={c?.vendor_store?.store_name} />
                <div class="flex-grow-1">
                    <div className="d-flex align-items-center justify-content-between mb-1">
                    <p className={`font-size-14 ${c?.group_id==currentChat?.group_id?"color-white":"purple-color"} mb-0`}><b>{c?.group_event?.event_name
                              ? `${c?.vendor_store?.store_name} (${c?.group_event?.event_name})`
                              : !c?.group_event
                              ? `${c?.vendor_store?.store_name}`
                              : ""}</b> &nbsp; <span className="color-grey text-capitalize">vendor</span></p>
                    <p className={`font-size-12 ${c?.group_id==currentChat?.group_id?"color-white":"color-grey"}  mb-0`}>{moment(c?.createdAt).format("HH:mm")}</p>
                    </div>
                    <div className="d-flex align-items-center">
                    <p class={`font-size-14 mb-0 ${c?.group_id==currentChat?.group_id?"color-white":"color-black"}`}>
                            {c?.message ? (
                              c?.message.length < 25 ? (
                                c?.message
                              ) : (
                                <>{c?.message.substring(0, 25)}...</>
                              )
                            ) : c?.images?.length > 0 ? (
                              <>
                                <i class="fa-solid fa-paperclip"></i> {t('Attachment')}
                              </>
                            ) : (
                              <>
                                <i class="fa-solid fa-handshake"></i>{" "}
                                {t('RequestOffer')}
                              </>
                            )}{" "}
                          </p>
                    {c?.chatStatusCount>0&&c?.sender_id!==profile?._id&&currentChat?.group_id!==c?.group_id?(<span className="wf-message-count pink-bg dm-font color-white rounded-circle font-size-14">{c?.chatStatusCount}</span>):""}
                    </div>
                </div>
            </div>
      ))}
            </div>
          </div>
            
            </div>
        </div>
        {currentChat ? (
              <ChatMessage currentChat={currentChat} getAllChats={updateChatSequence} loadChats={getAllChats}/>
          ) : <div style={{backgroundColor:'#F4F5F5', height:'86.5vh'}} className='col-lg-9 col-md-8 align-items-center justify-content-center d-md-flex d-none'>
            <p className="color-black font-size-22 mb-0 text-center">{t('No Chat Selected')}</p>
            </div>}
        </div>
	</div>
</section>

  );
};
export default MessageChat;