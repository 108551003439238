import { initializeApp } from "firebase/app";
import Cookies from 'js-cookie';
import {
  getAuth,
  GoogleAuthProvider,
  FacebookAuthProvider,
  OAuthProvider,
} from "firebase/auth";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import moment from "moment";
import { GoogleLogin } from "react-google-login";
import { signinUser, socialLogin, getUserEventList } from "../../store/slices/userSlice";
import { signInWithPopup } from "firebase/auth";
// import "../css/dashboard.css";
import { ArrowRight } from 'lucide-react';
import { Eye } from 'lucide-react';
import { EyeOff } from 'lucide-react';
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "../../components/Layout/LanguageSwitcher";
import i18n from "../../components/i18n";
import { firebaseConfig } from '../../firebase'
import Modal from "react-modal";


// const firebaseConfig = {
//   apiKey: "AIzaSyCHcal0KVJC90hLhwzH6u5_8O_9k6aGD_w",
//   authDomain: "weddified.firebaseapp.com",
//   projectId: "weddified",
//   storageBucket: "weddified.appspot.com",
//   messagingSenderId: "68283495312",
//   appId: "1:68283495312:web:60bf559a2e650ef2237915"
//   };


const customStyles = {
  content: {
    display: "block",
  },

};
Modal.setAppElement("#root");


const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const google = new GoogleAuthProvider();
const facebook = new FacebookAuthProvider();
const apple = new OAuthProvider("apple.com");

const Login = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [rememberMe, setRememberMe] = useState(false);
  const [user_device_token, setuser_device_token] = useState("")

  const [modalIsOpen, setmodalIsOpen] = useState(false);



  console.log('location.state', location.state)




  const socialSignin = async (provider) => {
    try {
      const result = await signInWithPopup(getAuth(), provider);
      if (result) {
        console.log("aaaaa", result);
        const data = await dispatch(
          socialLogin({
            user_social_token: result?.user?.uid,
            user_social_type: result?.providerId,
            device_token: user_device_token,
            user_email: result?.user?.email,
            name: result?.user?.displayName,
            role: ["customer", "planner"]
          })
        ).unwrap();

        await dispatch(getUserEventList())

        if (data.data.signupstepcompleted) {
          navigate(location.state ? `${location.state}` : '/dashboard', { state: { user_id: data.data._id } });
        } else {
          navigate('/confirmation', { state: { user_id: data.data._id } });
        }
      } else {
        console.log("Caught error Popup closed");

      }
    } catch (error) {
      console.log(error.code, error.message);

    }
  };

  const handleGoogleSignIn = () => {
    socialSignin(new GoogleAuthProvider())
  }
  const handleFacebookSignIn = () => {
    socialSignin(new FacebookAuthProvider())
  }


  useEffect(() => {
    const data = Cookies.get("user_device_token");
    setuser_device_token(data);
  }, []);



  const handleSubmit = async (e) => {
    e.preventDefault();
    try {


      const data = await dispatch(
        signinUser({ email, password, role: ["customer", "planner"], device_token: user_device_token })
      ).unwrap();
      console.log('data', data)
      if (data.data.isVerify == false) {
        navigate('/verify-account', { state: { user_id: data.data._id } });
      }
      
      else if (data.data.signupstepcompleted == false) {
        navigate('/confirmation', { state: { user_id: data.data._id } });
      }
      else {
        localStorage.setItem('loggedInUser', rememberMe);
        await dispatch(getUserEventList())
        navigate(location.state ? `${location.state}` : data.data.role == "planner" ? "/dashboard-planner" : '/dashboard');
      }
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
      if (rejectedValueOrSerializedError.status == 2) {
        viewModal();
      } 
    }
  };

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isSecureEntry, setisSecureEntry] = useState(true)

  const currentLanguage = i18n.language;

  console.log('user_device_token', user_device_token)

  function closeModal() {
    setmodalIsOpen(false);
  }

  const viewModal = async () => {
    setmodalIsOpen(true);
  }

  return (
    <>
       <Modal
        closeTimeoutMS={500}
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Add Task"
        className={`modal fade show wf-modal wf-delete-modal`}
      >
        <div className="modal-dialog modal-dialog-centered wf-modal-dialog">
          <div className="modal-content" id="addbooking">
            <div className="modal-header border-0 pb-0">
              <button type="button" onClick={() => closeModal()} className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body text-center">
              <p className="font-size-36 mb-2">{t('User')} <span className="fw-bold">{t('Not Found')}</span></p>
              <p className="font-size-18 grey-color">{t('The credentials you entered are for a business account. Please use the link below to login.')}</p>
              <a className="btn wf-gradient-btn w-100" href="https://business.weddified.com/login">{t('Login to Business Portal')}</a>
            </div>
          </div>
        </div>
      </Modal>  

      <section class="authentication-bg">
        <div class="container py-5 h-100">
          <div class="row d-flex justify-content-center align-items-center h-100">
            <div class="col-12 col-md-8 col-lg-6 col-xl-5">
              <div class="card shadow-sm rounded border-0">
                <div class="card-body py-5 px-md-5 px-4">

                  <img onClick={() => navigate("/")} className="mx-auto d-block auth-logo mb-5 wf-logo-card" src={`${process.env.REACT_APP_FrontURL}/assets/images/${currentLanguage === 'ar' ? 'logo-arabic.png' : 'logo.svg'}`} alt="Weddified" width={currentLanguage === 'ar' ? '240px' : '270px'} />

                  <div class="d-grid gap-3 mb-3">
                    <button onClick={(e) => handleGoogleSignIn()} class="btn social-btn btn-lg " type="button"><i class="fab fa-google "></i>{t('LoginwithGoogle')}</button>
                    <button onClick={(e) => handleFacebookSignIn()} class="btn social-btn btn-lg " type="button"><i class="fab fa-facebook-f"></i>{t('LoginwithFacebook')}</button>
                  </div>
                  <p className="font-size-14 fw-semibold text-center">or</p>
                  <form onSubmit={handleSubmit}>
                    <div class="form-floating wf-input-101 mt-3 mb-3">
                      <input type="email" class="form-control" id="floatingInput" placeholder="name@weddifed.com"
                        onChange={(e) => setEmail(e.target.value)} autocomplete="email" maxLength={320} required />
                      <label for="floatingInput">{t('EmailAddress')}</label>
                      {/* <div class="invalid-feedback d-block">Incorrect Email Address</div> */}
                    </div>
                    <div class="form-floating wf-input-101 mb-3 ">
                      <input class="form-control" id="floatingPassword" placeholder="Password" onChange={(e) => setPassword(e.target.value)}
                        type={isSecureEntry ? "password" : "text"} autocomplete="password" maxLength={128} required />
                      <i onClick={() => { setisSecureEntry((prev) => !prev) }} className={`fa-solid ${isSecureEntry ? "fa-eye" : "fa-eye-slash"} eye-password`}></i>
                      <label for="floatingPassword">{t('Password')}</label>
                      {/* <div class="invalid-feedback d-block">Incorrect Password</div> */}
                    </div>



                    <div class="row justify-content-between mb-3">
                      <div class="col-6">
                        {/* <div class="form-check wf-login-checkbox">
              <input checked={rememberMe} onChange={() => setRememberMe(!rememberMe)} class="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
              <label class="form-check-label" for="flexCheckDefault">
              {t('Keepmelogin')}
              </label>
              </div> */}
                      </div>
                      <div class="col-6 text-end">
                        <a onClick={() => navigate("/forgetpassword")} className="reset-pass">{t('ResetPassword')}?</a>
                      </div>
                    </div>

                    <div class="d-grid gap-2">
                      <button class="btn btn-lg wf-purple-btn wf-btn" type="submit">{t('Login')}</button>
                    </div>

                  </form>
                </div>
                <div class="card-footer wf-auth-card-footer">
                  <div className="text-center">
                    <a>{t("Don't have a Weddified account")}? <b className="pink-color cursor-pointer" onClick={() => navigate("/signup")}>{t('Sign Up')}</b></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <p className="d-none"> <LanguageSwitcher /> </p>

    </>
  );
};

export default Login;