import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import moment from "moment";
import { newPassword } from "../../store/slices/userSlice";
import { ArrowLeft } from 'lucide-react';
import { ArrowRight } from 'lucide-react';
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "../../components/Layout/LanguageSwitcher";
import i18n from "../../components/i18n";

const NewPassword = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  // const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isSecureEntry, setisSecureEntry] = useState(true)
  const [isSecureEntry1, setisSecureEntry1] = useState(true)

  const handleClick = async (e) => {
    e.preventDefault();
    try {
      const abc = await dispatch(
        newPassword({
          _id: location.state.user_id,
          newPassword: password,
          confirmnewPassword: confirmPassword,
        })
      ).unwrap();
      navigate("/login");
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  useEffect(() => {
    const disableBackNavigation = (event) => {
      event.preventDefault();
      window.history.forward();
    };
  
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener('popstate', disableBackNavigation);
  
    return () => {
      window.removeEventListener('popstate', disableBackNavigation);
    };
  }, []);

  const currentLanguage = i18n.language;

  return (
    <>
    <section class="authentication-bg">
    <div class="container py-5 h-100">
      <div class="row d-flex justify-content-center align-items-center h-100">
        <div class="col-12 col-md-8 col-lg-6 col-xl-5">
          <div class="card shadow-2-strong border-0 rounded">
            <div class="card-body py-5 px-md-5 px-4 text-center">
  
            <img onClick={()=>navigate("/")} className="mx-auto d-block auth-logo mb-5 wf-logo-card" src={`${process.env.REACT_APP_FrontURL}/assets/images/${currentLanguage === 'ar' ? 'logo-arabic.png' : 'logo.svg'}`} alt="Weddified" width={currentLanguage === 'ar' ? '240px' : '270px'} />
            <h3 className="dm-font font-size-20 pink-color mb-2">{t('SetupNewPassword')}</h3>
              {/* <p className="color-black font-size-14 mb-3">Enter New Password</p> */}

                {/* <div class="form-floating wf-input wf-input-101 mb-3">
              <input type="email" class="form-control" id="floatingInput" placeholder="name@example.com" 
              onChange={(e) => setEmail(e.target.value)} autocomplete="email" maxLength={320} required />
              <label for="floatingInput">{t('YourEmailAddress')}</label>
            </div> */}
            <div class="form-floating wf-input wf-input-101 mb-3">
              <input class="form-control" id="floatingPassword" placeholder="Password" onChange={(e) => setPassword(e.target.value)}
                  type={isSecureEntry ? "password" : "text"} autocomplete="password" maxLength={128} required />
              <i onClick={() => { setisSecureEntry((prev) => !prev) }} className={`fa-solid ${isSecureEntry ? "fa-eye" : "fa-eye-slash"} eye-password`}></i>
              <label for="floatingPassword">{t('Password')}</label>
            </div>
            <div class="form-floating wf-input wf-input-101 mb-3">
              <input class="form-control" id="floatingPassword" placeholder="Password" onChange={(e) => setConfirmPassword(e.target.value)}
                  type={isSecureEntry1 ? "password" : "text"} autocomplete="password" maxLength={128} required />
              <i onClick={() => { setisSecureEntry1((prev) => !prev) }} className={`fa-solid ${isSecureEntry1 ? "fa-eye" : "fa-eye-slash"} eye-password`}></i>
              <label for="floatingPassword">{t('Confirm')} {t('Password')}</label>
            </div>
            <div class="row justify-content-between">
                {/* <div className="col d-grid">
                <button class="btn wf-white-btn wf-btn btn-lg wf-shadow-2">Back</button>
                </div> */}
                <div className="col d-grid">
                <button class="btn wf-purple-btn wf-btn btn-lg wf-shadow-2" onClick={handleClick} >{t('Save')}</button>
                </div>
            </div>

              </div>
            <div class="card-footer wf-auth-card-footer">
            <div className="text-center">
            <a
            className=""
            onClick={() => navigate("/login")}
          >
            <b className="pink-color">{t('Back to')} {t('Login')}</b>
          </a>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <p className="d-none"> <LanguageSwitcher /> </p>
              
    </>
  );
};

export default NewPassword;