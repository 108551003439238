import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addNewsLetter
} from "../../store/slices/userSlice";
import { useTranslation } from "react-i18next";
import i18n from "../../components/i18n";

export const Newsletter = () => {
  const { t } = useTranslation();
  const dispatch= useDispatch() 
  const [email, setemail] = useState("");


  const handleSave = async () => {
  
    try {

        const requestData = { email: email };
        
       await dispatch(addNewsLetter(requestData)).unwrap();
       setemail("")     
      
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  const currentLanguage = i18n.language;

  return (
    <div id="subscribe">
    <h2 className="font-size-26 dm-font color-black mb-2">{t('SubscribetoourNewsletter')}</h2>
      <p className="font-size-14 purple-color mb-2">{t('Exclusivediscountstrends&updates')}</p>
      <div className="wf-shadow-2 white-bg rounded py-4 px-4">
      <p className="font-size-12 color-black mb-2">{currentLanguage === 'ar' ? 'اطمئن، عنوان بريدك الإلكتروني في أمان معنا. سيتم استخدامه حصريًا لإرسال تحديثات ويدفايد إليك ولن يتم مشاركته أبدًا مع أطراف ثالثة.' : 'Rest assured, your email address is safe with us. It will be exclusively used to send you Weddified updates and will never be shared with third parties.'}</p>
      <div class="input-group wf-input-group">
      <div class="form-floating wf-input">
        <input value={email} onChange={(e)=>setemail(e.target.value)} type="email" class="form-control" id="email-address" placeholder="Email Address"/>
        <label for="email-address">{t('EmailAddress')}</label>
        </div>
        <button onClick={handleSave} class="btn wf-pink-btn wf-btn fw-bold">{t('Subscribe')}</button>
      </div>
      </div>
    </div>
  )
}
