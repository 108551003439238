import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import TaskContent from "./TaskContent";



const TaskManagement = () => {
  
  const { t } = useTranslation();

  return (
    <>
    

      <section className="wf-task-management wf-background px-md-5 pb-5 pt-2">
        <div className="container-fluid">
          

         <TaskContent />
         
             
        </div>
      </section>
    </>
  );
};

export default TaskManagement;
