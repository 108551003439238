import React from 'react'

const CaregoriesButtonSkeleton = () => {
  return (
    <>
    <div className='shimmer rounded-3 wf-select-cat-btn' style={{height:'100px', width:'11%'}}></div>
    </>
  )
}

export default CaregoriesButtonSkeleton