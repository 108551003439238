import React from 'react'
import Header from '../Public/HeaderLight'
import BlogCardSkeleton from './BlogCardSkeleton'

const IdeasInspirationSkeleton = () => {
  return (
    <>
              {/* <section class="archives-hero ideas-inspiration">
            <div className="archive-img">
              <img
                src={`${process.env.REACT_APP_FrontURL}/assets/images/ideas-inspiration-background.jpg`}
                className="w-100"
                alt=""
              />
            </div>
            <Header theme="light" />
            <div class="container pb-3">
              <div className="pt-5 pb-5 ">
                <h1 class="h1-title playfair-font color-white text-center">
                  Get Ideas & Inspiration
                </h1>
                <div className="search-wrapper search-wrapper-2">
                  <div className="search-field-1 search-field-input">
                    <div class="dropdown">
                      <div class="input-group search-field-input-group">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Select Tag"
                        />
                        <button
                          class="input-group-text"
                          id="basic-addon1"
                        >
                          <i className="bi bi-x-circle"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="search-field-2">
                    <div class="dropdown">
                      <a
                        class="btn btn-secondary dropdown-toggle"
                      >
                        Select Content Type
                      </a>
                    </div>
                  </div>
                  <div className="search-field-3">
                    <div class="dropdown">
                      <a
                        class="btn btn-secondary dropdown-toggle"
                      >
                        Select Category
                      </a>
                    </div>
                  </div>
                  <div className="search-btn" >
                    <i class="fas fa-search"></i>
                  </div>
                </div>
              </div>
            </div>
          </section> */}

          <section className="archives-section pt-5 pb-4" >
            <div className="container">
              <div className="row mb-2">
                <div className="col-md-6">
                <div style={{width:'300px', height:'36px'}} className="shimmer rounded"></div>
                </div>
              </div>
              <div className="row mb-4 gy-3">
                  <div className="col-md-6">
                  <div style={{width:'200px', height:'24px'}} className="shimmer rounded"></div>
                  </div>
                  <div className="col-md-6 d-flex justify-content-md-end">
                    <div style={{width:'154px', height:'24px'}} className="shimmer rounded"></div>
                  </div>
                </div>

              <div className="row gy-5">
                {new Array(18).fill(null).map((_, index) => <BlogCardSkeleton key={index} />)}
              </div>
            </div>
          </section>
    </>
  )
}

export default IdeasInspirationSkeleton