import React from 'react'

const CategoriesCardSkeleton = () => {
  return (
    <>
        <div className="category-box">
      <div className="category-card">
          <div className="category-img shimmer"></div>
      </div>
      </div>
    </>
  )
}

export default CategoriesCardSkeleton