import React from 'react'
import Footer from '../Public/Footer'
import Header from '../Public/HeaderLight'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const UnAuthorized = () => {
  const { t } = useTranslation();
  return (
    <>
    <Header theme="dark" />
    <section className="faqs-section" >
      <div className="container">
        <div class="border-top  mb-4 mt-3"></div>
          <h1 className="h1-title dm-font color-black text-center">{t('Unauthorized Access')}</h1>
          <p className="font-size-18 color-black text-center">{t('Were sorry, but you dont have permission to access this page')}</p>
          <div class="border-top  mb-3 mt-4"></div>
          <div className="text-center">
          <Link to="/" className="btn wf-btn-icon wf-gradient-btn text-uppercase d-inline-flex gap-4 px-4"> {t('Home')}  <i className={`bi bi-arrow-right-short font-size-24 d-flex`}></i></Link>
          </div>
      </div>
    </section>

    <Footer />
    </>
  )
}

export default UnAuthorized