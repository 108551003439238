import React, { useState } from 'react';

const Advice = () => {
    const [openPanel, setOpenPanel] = useState(null);

    const togglePanel = (panelNumber) => {
      if (openPanel === panelNumber) {
        // If the clicked panel is already open, close it.
        setOpenPanel(null);
      } else {
        // Otherwise, open the clicked panel.
        setOpenPanel(panelNumber);
      }
    };
    const isPanelOpen = (panelNumber) => openPanel === panelNumber;

    function ordinal(number) {
        const suffixes = ['st', 'nd', 'rd'];
        const remainder = number % 10;
        const suffix = suffixes[remainder - 1] || 'th';
        return `${number}${suffix}`;
      }
  return (
    <>
    <section className="wf-advice pb-5">
        <div className="container">
        <div className="mb-3">
        <img src="../assets/images/advice-img.png" className="advice-img w-100"  />
        </div>
        <div className="wf-shadow white-bg rounded p-4 mb-3">
        <p className="font-size-18 color-black mb-2 text-center fw-bold">Your Personal Wedding Advisors</p>
        <p className="font-size-16 color-black mb-0 text-center">We know that planning a wedding can be overwhelming, but we are here to help you make it as stress-free and enjoyable as possible. We will offer you support, encouragement, advice, and answer all your questions (big and small!).</p>
        <div className="row justify-content-center mt-3">
        <div className="col-md-2 d-grid">
        <button class="btn wf-purple-btn wf-btn btn-lg" type="button">Send an email</button>
        </div>
        <div className="col-md-2 d-grid">
        <button class="btn wf-purple-btn wf-btn btn-lg" type="button">Connect with &nbsp; <i class="fab fa-facebook"></i></button>
        </div>
        <div className="col-md-2 d-grid">
        <button class="btn wf-purple-btn wf-btn btn-lg" type="button">Connect with &nbsp; <i class="fab fa-instagram"></i></button>
        </div>
        </div>
        </div>
        <div className="wf-shadow white-bg rounded p-4 mb-3">
        <h1 className="font-size-36 playfair-font color-black mb-2 text-center">We Love Hearing From Our Couples Or Couples-To-Be!</h1>
        <p className="font-size-16 color-black mb-3 text-center">Here Are Some Of The Most Trending Questions We’ve Been Getting Lately:</p>
        <div className="wf-toggle-advice accordion" id="accordionPanelsStayOpenExample">
      {Array.from({ length: 3 }, (_, index) => (
        <div className="accordion-item" key={index}>
          <h2 className="accordion-header" id={`panelsStayOpen-heading${index + 1}`}>
            <button
              className={`accordion-button ${isPanelOpen(index) ? '' : 'collapsed'}`}
              type="button"
              data-bs-toggle="collapse"
              onClick={() => togglePanel(index)}
              aria-expanded={isPanelOpen(index)}
              aria-controls={`panelsStayOpen-collapse${index + 1}`}
            >
              Accordion Item #{index + 1}
            </button>
          </h2>
          <div
            id={`panelsStayOpen-collapse${index + 1}`}
            className={`accordion-collapse collapse ${isPanelOpen(index) ? 'show' : ''}`}
            aria-labelledby={`panelsStayOpen-heading${index + 1}`}
          >
            <div className="accordion-body">
              <strong>This is the {ordinal(index + 1)} item's accordion body.</strong> It is{' '}
              {isPanelOpen(index) ? 'shown' : 'hidden'} by default, until the collapse plugin adds
              the appropriate classes that we use to style each element. These classes control the
              overall appearance, as well as the showing and hiding via CSS transitions. You can
              modify any of this with custom CSS or overriding our default variables. It's also
              worth noting that just about any HTML can go within the <code>.accordion-body</code>,
              though the transition does limit overflow.
            </div>
          </div>
        </div>
      ))}
    </div>
        </div>
        <div className="wf-topic-advice wf-shadow rounded white-bg p-4">
        <h1 className="font-size-36 playfair-font color-black mb-3">Browse By Topic</h1>
        <div className="row mt-3">
        <div className="col-md-3 d-grid">
        <button class="btn wf-pink-btn wf-btn-icon btn-lg" type="button"><i class="fas fa-file-alt"></i> The First Look Report</button>
        </div>
        <div className="col-md-3 d-grid">
        <button class="btn wf-pink-btn wf-btn-icon btn-lg" type="button"><i class="fab fa-facebook"></i> Invites + Paper</button>
        </div>
        <div className="col-md-3 d-grid">
        <button class="btn wf-pink-btn wf-btn-icon btn-lg" type="button"><i class="fab fa-instagram"></i> Registry</button>
        </div>
        <div className="col-md-3 d-grid">
        <button class="btn wf-pink-btn wf-btn-icon btn-lg" type="button"><i class="fab fa-instagram"></i> Planning 101</button>
        </div>
        </div>
        </div>
        </div>
    </section>
    </>
  )
}

export default Advice