import React, {useEffect,useState} from 'react'
import Header from './HeaderLight'
import Footer from './Footer'
import { Helmet } from 'react-helmet';
import i18n from '../../components/i18n';
import { useTranslation } from 'react-i18next';


// const questions = [
//   {
//     id: 1,
//     question: 'What wedding planning tools are available on your website?',
//     answer: 'Our website offers a variety of wedding planning tools, including: <ul><li>A wedding budget calculator and analysis</li><li>A wedding checklist</li><li>A wedding venue directory</li><li>A wedding vendor directory</li><li>Booking Vendor</li><li>A wedding planning blog',
//   },
//     {
//     id: 2,
//     question: 'How do I use the wedding planning tools on your website?',
//     answer: 'The wedding planning tools on our website are easy to use. Simply click on the Sign-up, create an account and you are good to go! <br/>What are the benefits of using wedding planning tools?<br/>There are many benefits to using wedding planning tools. These tools can help you:',
//   },
//     {
//     id: 3,
//     question: 'How can I get help with using the wedding planning tools on your website?',
//     answer: 'If you need help with using the wedding planning tools on our website, please contact our customer support team. They will be happy to help you.',
//   },
//       {
//     id: 4,
//     question: 'How do I create a store on your supplier listing platform?',
//     answer: 'To create a store on our supplier listing platform, simply click on the "Sign-up as Supplier" button and follow the instructions. You will need to provide some basic information about your business, such as your name, contact information, and the products or services that you offer.',
//   },
//   {
//     id: 5,
//     question: 'How do I add products and services to my store?',
//     answer: 'Once you have created a store, you can add products and services to your store by clicking on the "Add Services" button. You will need to provide some information about your Service.',
//   },
//   {
//     id: 6,
//     question: 'How do I find the right wedding venue?',
//     answer: 'There are a few different ways to find the right wedding venue. One way is to search online wedding venue directories. Another way is to ask for recommendations from friends, family, or wedding planners. Once you have a few potential venues, it is important to visit them in person to see if they are a good fit.',
//   },
//   {
//     id: 7,
//     question: 'How do I choose the right wedding vendors?',
//     answer: 'There are a few different ways to choose the right wedding vendors. One way is to ask for recommendations from friends, family, or wedding planners. Another way is to search online wedding vendor directories. Once you have a few potential vendors, it is important to get quotes from them and to read reviews before making a decision.',
//   },
//   {
//     id: 8,
//     question: 'How do I create a wedding budget?',
//     answer: 'The first step in creating a wedding budget is to determine how much money you have to spend. Once you know your budget, you can start to allocate money to different aspects of the wedding. It is important to be realistic about your budget and to be prepared to make some compromises.',
//   },
// ]

const questions = [
  {
    id: 1,
    question: 'What wedding planning tools are available on your website?',
    questionArabic: 'ما هي أدوات تخطيط الزفاف المتاحة على موقعك؟',
    answer: 'Our website offers a variety of wedding planning tools, including: <ul><li>A wedding budget calculator and analysis</li><li>A wedding checklist</li><li>A wedding venue directory</li><li>A wedding vendor directory</li><li>Booking Vendor</li><li>A wedding planning blog',
    answerArabic: 'يقدم موقعنا مجموعة من أدوات تخطيط الزفاف، بما في ذلك: <ul><li>آلة حاسبة وتحليل ميزانية الزفاف</li><li>قائمة تحقق للزفاف</li><li>دليل أماكن الزفاف</li><li>دليل موردي الزفاف</li><li>حجز الموردين</li><li>مدونة تخطيط الزفاف',
  },
  {
    id: 2,
    question: 'How do I use the wedding planning tools on your website?',
    questionArabic: 'كيف يمكنني استخدام أدوات تخطيط الزفاف على موقعك؟',
    answer: 'The wedding planning tools on our website are easy to use. Simply click on the Sign-up, create an account and you are good to go! <br/>What are the benefits of using wedding planning tools?<br/>There are many benefits to using wedding planning tools. These tools can help you:',
    answerArabic: 'أدوات تخطيط الزفاف على موقعنا سهلة الاستخدام. ما عليك سوى النقر على زر التسجيل، إنشاء حساب وأنت جاهز للبداية! <br/>ما هي فوائد استخدام أدوات تخطيط الزفاف؟<br/>هناك العديد من الفوائد لاستخدام أدوات تخطيط الزفاف. يمكن لهذه الأدوات مساعدتك:',
  },
  {
    id: 3,
    question: 'How can I get help with using the wedding planning tools on your website?',
    questionArabic: 'كيف يمكنني الحصول على مساعدة في استخدام أدوات تخطيط الزفاف على موقعك؟',
    answer: 'If you need help with using the wedding planning tools on our website, please contact our customer support team. They will be happy to help you.',
    answerArabic: 'إذا كنت بحاجة إلى مساعدة في استخدام أدوات تخطيط الزفاف على موقعنا، يرجى الاتصال بفريق دعم العملاء لدينا. سيسعدون بمساعدتك.',
  },
  {
    id: 4,
    question: 'How do I create a store on your supplier listing platform?',
    questionArabic: 'كيف يمكنني إنشاء متجر على منصة قائمة موردينك؟',
    answer: 'To create a store on our supplier listing platform, simply click on the "Sign-up as Supplier" button and follow the instructions. You will need to provide some basic information about your business, such as your name, contact information, and the products or services that you offer.',
    answerArabic: 'لإنشاء متجر على منصة قائمة موردينا، انقر ببساطة على زر "التسجيل كمورد" واتبع التعليمات. ستحتاج إلى تقديم بعض المعلومات الأساسية حول عملك، مثل اسمك ومعلومات الاتصال، والمنتجات أو الخدمات التي تقدمها.',
  },
  {
    id: 5,
    question: 'How do I add products and services to my store?',
    questionArabic: 'كيف يمكنني إضافة منتجات وخدمات إلى متجري؟',
    answer: 'Once you have created a store, you can add products and services to your store by clicking on the "Add Services" button. You will need to provide some information about your Service.',
    answerArabic: 'بمجرد أن تقوم بإنشاء متجر، يمكنك إضافة منتجات وخدمات إلى متجرك عن طريق النقر على زر "إضافة خدمات". ستحتاج إلى تقديم بعض المعلومات حول خدمتك.',
  },
  {
    id: 6,
    question: 'How do I find the right wedding venue?',
    questionArabic: 'كيف يمكنني العثور على مكان الزفاف المناسب؟',
    answer: 'There are a few different ways to find the right wedding venue. One way is to search online wedding venue directories. Another way is to ask for recommendations from friends, family, or wedding planners. Once you have a few potential venues, it is important to visit them in person to see if they are a good fit.',
    answerArabic: 'هناك بعض الطرق المختلفة للعثور على مكان الزفاف المناسب. أحد الطرق هو البحث في دلائل الأماكن عبر الإنترنت للزفاف. وسيلة أخرى هي طلب توصيات من الأصدقاء أو العائلة أو مخططي الزفاف. بمجرد العثور على بعض الأماكن المحتملة، يتعين عليك زيارتها شخصياً لمعرفة ما إذا كانت مناسبة.',
  },
  {
    id: 7,
    question: 'How do I choose the right wedding vendors?',
    questionArabic: 'كيف يمكنني اختيار البائعين المناسبين لحفل الزفاف؟',
    answer: 'There are a few different ways to choose the right wedding vendors. One way is to ask for recommendations from friends, family, or wedding planners. Another way is to search online wedding vendor directories. Once you have a few potential vendors, it is important to get quotes from them and to read reviews before making a decision.',
    answerArabic: 'هناك بعض الطرق المختلفة لاختيار البائعين المناسبين لحفل الزفاف. إحدى الطرق هي طلب التوصيات من الأصدقاء أو العائلة أو مخططي الزفاف. وسيلة أخرى هي البحث في دلائل البائعين عبر الإنترنت. بمجرد العثور على بعض البائعين المحتملين، يجب الحصول على عروض منهم وقراءة التقييمات قبل اتخاذ قرار.',
  },
  {
    id: 8,
    question: 'How do I create a wedding budget?',
    questionArabic: 'كيف يمكنني إعداد ميزانية الزفاف؟',
    answer: 'The first step in creating a wedding budget is to determine how much money you have to spend. Once you know your budget, you can start to allocate money to different aspects of the wedding. It is important to be realistic about your budget and to be prepared to make some compromises.',
    answerArabic: 'الخطوة الأولى في إعداد ميزانية الزفاف هي تحديد كمية الأموال التي لديك للإنفاق. بمجرد معرفتك بميزانيتك، يمكنك البدء في تخصيص الأموال لمختلف جوانب الزفاف. من المهم أن تكون واقعيًا بشأن ميزانيتك وأن تكون مستعدًا لاتخاذ بعض التنازلات.',
  },
];


function FAQ(props) {    
  const currentLanguage = i18n.language;
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const handleSearchChange = e => {
    setSearchTerm(e.target.value);
  };
  
  useEffect(() => {
    const results = props.data.filter(item=>
      item.question.toLowerCase().includes(searchTerm)
    );
    setSearchResults(results);
    window.scrollTo({ top: 0, left: 0})
  }, [searchTerm]);
  
  return (  
    <>
      <Searchbar onSearchChange={handleSearchChange}/> 
      {searchResults.map(item => (
        <Question
          question={currentLanguage === 'ar' ? item.questionArabic : item.question}
          answer={currentLanguage === 'ar' ? item.answerArabic : item.answer}
        />
      ))}
      </>     
  )
}

const Searchbar = props => {
  const [value, setValue] = useState('')
  const handleChange = (e) => {
    setValue(e.target.value)
    props.onSearchChange(e)
  }
  return(
    <form className="faq-search">    
      <div className="search-wrapper">
      <input className='searchbar' type='text' placeholder='Search' onChange={handleChange} value={value}/> 
        <div className="search-btn">
        <i class="fas fa-search"></i>
        </div>
      </div>
    </form>    
  )
}

const Question = props => {
 const [isActive, setActive] = React.useState(false);
 const handleClick = (id) => {
  setActive(!isActive)
}
  return(
    <div className="question-wrapper" onClick={() => handleClick(props.id)}>
    <div className='question' id={props.id}>
      <h3 className="font-size-22 font-semibold-calibre black-color">{props.question}</h3>
     <div className="toggle-faq"> {isActive? <i class="fas fa-minus"></i> : <i class="fas fa-plus"></i>}</div>
    </div>
    <div className={isActive? 'answer active' : 'answer'}>
      <p className="font-size-16 black-color" dangerouslySetInnerHTML={{ __html: props.answer }}></p>
      </div>
    </div>
  )
}

const FAQs = () => {
  const { t } = useTranslation();
  return (
    <>
    <Helmet>
        <title>Weddified FAQ - Answers to Your Wedding Planning Questions</title>
        <meta
          name="description"
          content="Explore the comprehensive FAQ section of Weddified, the leading wedding planning app. Find answers to common questions about our app's features, services, and wedding planning tips."
        />
        <meta
          name="keywords"
          content="Weddified, wedding planning"
        />
      </Helmet>
      <Header theme="dark" />
    <section className="faqs-section" >
      <div className="container">
      <div class="border-top  mb-4 mt-3"></div>
          <h1 className="h1-title dm-font color-black text-center">{t('FrequentlyAskedQuestions')}</h1>
          <div class="border-top  mb-3 mt-4"></div>
          <FAQ data={questions}/>
      </div>
    </section>

    <Footer />
    </>
  )
}

export default FAQs