import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getEventList, getProfile } from "../../store/slices/userSlice";
import moment from "moment";

const CalendarData = () => {
  const profile = useSelector(getProfile);
  const myEvents = useSelector(getEventList);

  const [calendarData, setCalendarData] = useState([]);

  useEffect(() => {
    if (myEvents) {
      handleCalendarContent();
    }
  }, [myEvents]);

  const handleCalendarContent = () => {
    const mergedEvents = myEvents?.map((event) => ({
      id: event?.Event?._id,
      type: "Event",
      title: event?.Event?.event_name,
      start: moment(event?.Event?.event_date, "YYYY-MM-DDTHH:mm").toDate(),
      end: moment(event?.Event?.event_date, "YYYY-MM-DDTHH:mm").toDate(),
      description: `Event: ${event?.Event?.event_name}`,
      data: event
    }));

    const mergedTasks = myEvents?.flatMap((event) => {
      if (event.Event?.eventTasks && event.Event?.eventTasks.length > 0) {
        return event.Event.eventTasks?.filter((f) => f.assignedTo == profile?._id).map((t) => ({
          id: t._id,
          type: "Task",
          title: t.name,
          start: moment(t.date, "YYYY-MM-DDTHH:mm").toDate(),
          end: moment(t.date, "YYYY-MM-DDTHH:mm").toDate(),
          description: `Task: ${t.name}`,
          data: t
        }));
      } else {
        return []; // Return an empty array if eventTasks is undefined or has length 0
      }
    });

    const mergedData = mergedEvents.concat(mergedTasks);

    const finalCalendarData = mergedData.filter((item) => item !== undefined && item !== null);

    console.log('finalCalendarData', finalCalendarData)

    setCalendarData(finalCalendarData);
    return finalCalendarData; // Return the merged array
  };


  return calendarData;
};

export default CalendarData;
