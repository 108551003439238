import React, { useState, useEffect, useRef } from "react";
import { CheckCheck, Contact, FileAudio, FileText, FileVideo, Link, PanelRight, Paperclip, Search, Send, SmilePlus, Star, X, Image, Info, UserPlus, ArrowDown } from 'lucide-react'
import { Settings, Settings2, PlusCircle, DownloadCloud } from 'lucide-react'
import Lightbox from 'react-image-lightbox';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import { useDispatch, useSelector } from "react-redux";
import {
  getChatMembers,
  getSocket,
  getProfile,
  saveAttachment,
  getEventList,
  addChatMember,
  addManualBookings,
  rejectOffer,
  rejectRequest,
  allRead
} from "../../store/slices/userSlice";
import { toast } from "react-toastify";
import moment from "moment";
import { io } from "socket.io-client";
import Sidebar from "../../components/Layout/SidebarVendor";
import Modal from "react-modal";
import { useTranslation } from "react-i18next";
const customStyles = {
  content: {
    display: "block",
    "--bs-modal-width": "30%"
  },
};
Modal.setAppElement("#root");
const socket = io(process.env.REACT_APP_APIURL);

const ChatMessage = ({ currentChat, getAllChats, loadChats }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const myEvents = useSelector(getEventList);

  const [showAllImages, setShowAllImages] = useState(false);
  const [showArrowDown, setShowArrowDown] = useState(false);
  const [message, setMessage] = useState([]);
  const [chatmembers, setChatMembers] = useState([]);
  const [selectedEvent, setselectedEvent] = useState(null);
  const [requestId, setrequestId] = useState(null);
  const [price, setprice] = useState(null);
  const [initialdeposit, setinitialdeposit] = useState(null);
  const [initialdepositdate, setinitialdepositdate] = useState(null);
  const [finaldepositdate, setfinaldepositdate] = useState(null);
  const [remainingAmount, setremainingAmount] = useState(0);
  const [toggle, settoggle] = useState(false);
  const [searchText, setsearchText] = useState(false);
  const [searchClick, setsearchClick] = useState(false);
  const [load, setload] = useState(false);
  const [load1, setload1] = useState(false);


  const [toggle1, settoggle1] = useState(false);
  const [currentMessage, setCurrentMessage] = useState("");
  const profile = useSelector(getProfile);
  // const socket = useSelector(getSocket);
  const lastMessageRef = useRef(null);
  const [messageText, setmessageText] = useState({ text: "", images: [] });
  // const [messageText, setMessageText] = useState({ text: '', emojis: [] });

  const [attachedImages, setAttachedImages] = useState([]);

  const [comments, setcomments] = useState("");
  
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalType, setModalType] = useState();
  
  
  //data for acceptoffer to save temporary
  const [idd, setidd] = useState("");
  const [group_idd, setgroup_idd] = useState("");
  const [event_idd, setevent_idd] = useState("");
  const [offerpricee, setofferpricee] = useState("");
  const [initdep, setinitdep] = useState("");
  const [finaldep, setfinaldep] = useState("");
  const [initdepdate, setinitdepdate] = useState("");
  const [finaldepdate, setfinaldepdate] = useState("");
  const [store_idd, setstore_idd] = useState("");
  const [store_category_idd, setstore_category_idd] = useState("");
  const [store_namee, setstore_namee] = useState("");

  let membersToMap=profile?.role=="customer"?myEvents:myEvents?.filter((f)=> f?.Event?._id==currentChat?.group_event?._id)


  const handleTextChange = (e) => {
    const text = e.target.value;
    setmessageText({ ...messageText, text });
  };

  function getCurrentDate() {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}

  const handleImageChange = (e) => {
    e.preventDefault()
    const files = e.target.files;
    const images = [...messageText.images];

    for (let i = 0; i < files.length; i++) {
      const image = files[i];
      images.push(image);
    }

    setmessageText({ ...messageText, images });
    setAttachedImages([...attachedImages, ...images]);
  };

  const handleRemoveImage = (e,index) => {
    e.preventDefault()
    const images = [...messageText.images];
    images.splice(index, 1);

    const newAttachedImages = [...attachedImages];
    newAttachedImages.splice(index, 1);

    setmessageText({ ...messageText, images });
    setAttachedImages(newAttachedImages);
  };

  function viewModal(type) {
    setIsOpen(true);
    
    setModalType(type);
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const handleAcceptOffer = async (id, group_id, event_id, initialDepo, initialDepoDate, finalDepo, finalDepoDate, price, store_id, store_category_id, store_name, route) => {
    
    console.log('group_idddd', group_id)

    if(route!=="modal"){
      
    setidd(id)
    setgroup_idd(group_id)
    setevent_idd(event_id)
    setofferpricee(price)
    setinitdep(initialDepo)
    setinitdepdate(initialDepoDate)
    setfinaldep(price-initdep)
    setfinaldepdate(finalDepoDate)
    setstore_idd(store_id)
    setstore_category_idd(store_category_id)
    setstore_namee(store_name)

  }

  setload(true)
      const response = await dispatch(
        addManualBookings({ id: route=="modal"?idd:id, group_id: route=="modal"?group_idd:group_id, event_id: route=="modal"? event_idd:event_id, price: route=="modal"?offerpricee:price, store_id: route=="modal"?store_idd:store_id, store_category_id: route=="modal"?store_category_idd:store_category_id, store_name: route=="modal"?store_namee:store_name, remainingAmount: remainingAmount>0?remainingAmount:null, force: route=="modal"?true:false, 
        paymentStatus: route == "modal" && initdep > 0 ? "partiallypaid" : initialDepo > 0 ? "partiallypaid" : "fullypaid", 
        finalDeposit: route == "modal"? offerpricee - initdep:price-initialDepo, 
        finalDepositDate: route == "modal"?finaldepdate:finalDepoDate, 
        initialDeposit: route == "modal"?initdep:initialDepo,
        initDepositDate: route == "modal"?initdepdate:initialDepoDate})
      ).unwrap();

setload(false)
      console.log('response', response.data.remainingAmount)
      // return
      if (response.data.remainingAmount>0&&response.status==0) {
        setremainingAmount(response.data.remainingAmount)
        viewModal("add")
      }
      else{
        callMessage();
        loadChats()
        closeModal()
      }

  
  };


  const handleRejectOffer = async (id, group_id, type) => {
 
    setload1(true)
      const abc = await dispatch(
        rejectOffer({ id: id, group_id: group_id })
      ).unwrap();
      if (abc) {
        setload1(false)
        callMessage();
      }
    
  };

  const handleRejectRequest = async (id, group_id) => {

    console.log('firstdddddddd', id)
    console.log('first', group_id)
    
      const abc = await dispatch(
        rejectRequest({ id: id, groupid: group_id })
      ).unwrap();
      if (abc) {
        callMessage();
      }
    
  };

  const handleAcceptRequest = async (e, id) => {
  
    //set event details from request
    setselectedEvent(e);
    setrequestId(id);
    settoggle1(true)

  };


  let lastFoundIndex = -1; // Initialize the index of the last found message

function highlightAndScrollToMessage() {
  
  if(searchText){
    setsearchClick(false)
    const searchText = document.getElementById('search').value.toLowerCase(); 
    const messages = document.querySelectorAll('.wf-message-text');

    let messageFound = false;

   
    let startIndex = lastFoundIndex !== -1 ? lastFoundIndex - 1 : messages.length - 1;

 
    for (let i = startIndex; i >= 0; i--) {
        const messageText = messages[i].textContent.toLowerCase(); 

        if (messageText.includes(searchText)) {

            messages[i].style.backgroundColor = '#804099'; 
            messages[i].style.color = '#ffffff';
            messages[i].style.fontWeight = 'bold';
            console.log("Message highlighted:", messages[i]);
            messages[i].scrollIntoView({ behavior: 'smooth', block: 'center' }); 
            lastFoundIndex = i; 
            messageFound = true;
            break; 
        }
    }

    if (!messageFound) {
        toast.error("No occurrences found");
        lastFoundIndex = -1;
    }
  }
  else{
    setsearchClick(true)
  }
}



  const sendMessage = async (e, type) => {
    e.preventDefault();
    let response = [];

    if (type == "message") {
      if (messageText.text == "" && messageText.images.length < 1) {
        toast.error("Message is empty");
      } else {
        if (messageText?.images?.length > 0) {
          const data = new FormData();

          messageText.images.forEach((item) => {
            data.append("imageName", item);
          });

          response = await dispatch(saveAttachment(data)).unwrap();

          console.log("response33", response);
        }

        socket?.emit("send_message", {
          sender_id: profile?._id,
          group_id: currentChat?.group_id,
          receiver_id: currentChat?.title?._id,
          message: messageText.text,
          images: attachedImages?.length>0?response?.data:null,
          messageType: "message",
          chatmembers: chatmembers,
          connection: true
        });

        // toast.success("Message sent successfully");

        setmessageText({ text: "", images: [] });
        setAttachedImages([]);
      }
    } else if (type == "send_req") {
      if (selectedEvent == null) {
        toast.error("Please select an event");
      } else {
        socket?.emit("send_message", {
          sender_id: profile?._id,
          group_id: currentChat?.group_id,
          receiver_id: currentChat?.title?._id,
          event_id: selectedEvent?._id,
          comments: comments,
          messageType: "request",
          chatmembers: chatmembers,
          connection: true
        });

        toast.success("Request sent successfully");

        setselectedEvent({});
        settoggle1(false);
        setmessageText("");
      }
    } else if (type == "send_offer") {

      console.log('priceee', price,initialdeposit)
      if (selectedEvent == null) {  
        toast.error("Please select an event");
      }
      else if(parseInt(initialdeposit)>parseInt(price)){
        toast.error("Initial deposit should be less than the total price");
      }

      else if(parseInt(price - initialdeposit)+parseInt(initialdeposit)>parseInt(price)){
        toast.error("Price cannot be greater then total price")
      }

      else {
        socket?.emit("send_message", {
          id: requestId, //for change request status to accept i.e "1"
          sender_id: profile?._id,
          group_id: currentChat?.group_id,
          receiver_id: currentChat?.title?._id,
          request_id: requestId,
          event_id: selectedEvent?._id,
          messageType: "offer",
          connection: true,
          price: {
            totalPrice: parseInt(price),
            initialDeposit: parseInt(initialdeposit),
            initialDepositDate: initialdepositdate,
            finalDeposit: price - initialdeposit,
            finalDepositDate: finaldepositdate,
            paymentMode: "cash"
          },
          comments: comments
        });

        toast.success("Offer sent successfully");
        setselectedEvent({});
        settoggle1(false);
        setmessageText("");
      }
    } else {
    }
  };

  // const handleFileAttachment = async (e) => {

  //   const data = new FormData();

  //     data.append("imageName", e.target.files);

  //   const response = await dispatch(
  //     saveAttachment(data)
  //   ).unwrap();

  //   console.log('response2222222', response)

  // }


console.log('membersToMap', membersToMap)

  const getChatMember = async (groupid) => {
    try {
      setChatMembers(null);
      const response = await dispatch(
        getChatMembers({ groupid: groupid })
      ).unwrap();
      console.log("response111", response);
      setChatMembers(response?.data);

      console.log("myEvents", myEvents);
      console.log("response.data", chatmembers);
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  const handleaddmember = async (name, userid, type) => {
    await dispatch(
      addChatMember({
        name: name,
        userid: userid,
        groupid: currentChat?.group_id,
        type: type,
      })
    ).unwrap();

    getChatMember(currentChat?.group_id);
  };

  const callMessage = () => {
    socket?.emit("get_messages", {
      group_id: currentChat?.group_id,
      
    });

    socket?.on("response", async (data) => {
      console.log("ajeebsa", data);
      if (data?.object_type == "get_messages") {
        console.log("dataaaaaaaaaaaa", data);
        setMessage(data?.data);
      } else if (data?.object_type == "get_message") {
        console.log("dataaaaaaaaaaaassss", data);
        if (!message?.find((arr) => arr._id === data?.data[0]?._id)) { //&&data?.data[0].group_id==currentChat?.group_id
          getAllChats(data?.data[0]);
          if(data?.data[0]?.group_id==currentChat?.group_id){
            setMessage((prevMessages) => [...prevMessages, data?.data[0]]);
            await dispatch(allRead({groupid: currentChat?.group_id})).unwrap();
            setCurrentMessage("");
          }
        } else {
          setMessage(message);
        }
      }
    });
  };

  useEffect(() => {
    getChatMember(currentChat?.group_id);
    callMessage();
    return () => socket?.off("response");
  }, [socket, currentChat?.group_id]);

  useEffect(() => {
    if (currentChat) {
      lastMessageRef.current.scrollTop = lastMessageRef?.current?.scrollHeight;
    }
  }, [message, currentChat?.group_id, toggle1]);


  function scrollToBottom(element, duration) {
    const start = element.scrollTop;
    const end = element.scrollHeight - element.clientHeight;
    const distance = end - start;
    const startTime = performance.now();

    function scroll(timestamp) {
        const elapsed = timestamp - startTime;
        const progress = Math.min(elapsed / duration, 1);
        element.scrollTop = start + distance * progress;

        if (progress < 1) {
            requestAnimationFrame(scroll);
        }
    }

    requestAnimationFrame(scroll);
  }

  

  useEffect(() => {
    if (searchClick) {
      scrollToBottom(lastMessageRef.current, 500);
    }
  }, [searchClick]);

  console.log('socket', socket)

  console.log("currentChat", currentChat);
  console.log("myEvents", myEvents);
  console.log("selectedEvent", selectedEvent);
  console.log("toggle1", toggle1);
  console.log("message", message);
  console.log("chatmembers", chatmembers);
  console.log("messageTExt", messageText);


  const [sidepanel, setsidepanel] = useState(false);
  const [searchtoggle, setsearchtoggle] = useState(false);
  const [toggle2, settoggle2] = useState(false);
  const [RequestOffer, setRequestOffer] = useState(false);
  const [AcceptOffer, setAcceptOffer] = useState(false);
  const [photoIndex, setphotoIndex] = useState(0)
  const [videoIndex, setvideoIndex]=useState(0)
  const [isOpen, setisOpen]=useState()
  const [photoFiles, setPhotoFiles] = useState([]);
  const [otherFile, setOtherFile] = useState(null);
  
  const handleFileChange = (event, fileType) => {
    const selectedFile = event.target.files[0];
  
    if (fileType === 'image') {
      setPhotoFiles((prevFiles) => [...prevFiles, ...event.target.files]);
    } else {
      setOtherFile(selectedFile);
    }
  };
  
  // Map file extensions to corresponding icons
  const fileIcons = {
    image: <Image width={22} height={22} stroke={"#3A5CD6"} />,
    document: <FileText width={22} height={22} stroke={"#3A5CD6"} />,
    audio: <FileAudio width={22} height={22} stroke={"#3A5CD6"} />,
    video: <FileVideo width={22} height={22} stroke={"#3A5CD6"} />,
  };
  
  const getFileType = (file) => {
    const extension = file.name.split('.').pop().toLowerCase();
  
    if (extension.match(/(jpg|jpeg|png|gif|bmp)$/)) {
      return 'image';
    } else if (extension.match(/(doc|docx|ppt|pptx|txt|pdf|xls|xlsx)$/)) {
      return 'document';
    } else if (extension.match(/(mp3)$/)) {
      return 'audio';
    } else if (extension.match(/(mp4|mov|avi|mkv)$/)) {
      return 'video';
    } else {
      return 'other';
    }
  };
  
  console.log(toggle1,"toggle1");
  
  // const [selectedEmojis, setSelectedEmojis] = useState([]);
  //     const handleEmojiSelect = (emoji) => {
  //       setSelectedEmojis((prevEmojis) => [...prevEmojis, emoji.native]);
  // };

  // const handleEmojiSelect = (emoji) => {
  //   setmessageText((prevMessageText) => {
  //     // Check if the emoji is already present
  //     if (!prevMessageText.emojis.includes(emoji.native)) {
  //       // Add the emoji only if it's not present
  //       return {
  //         ...prevMessageText,
  //         emojis: [...prevMessageText.emojis, emoji.native],
  //       };
  //     }
  //     // If the emoji is already present, return the previous state
  //     return prevMessageText;
  //   });
  // };
  
  const fileUrl = 'https://images.weddified.com/images/accessories_banner.jpg';
  
  const handleDownload = async () => {
      try {
        const response = await fetch(fileUrl);
        const blob = await response.blob();
  
        // Create a link and set its href to the Blob
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'file.jpg'; // Specify the desired file name
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error('Error downloading the file:', error);
      }
    };
  
    


    useEffect(() => {
      const handleScroll = () => {
        if (lastMessageRef.current) {
          const { scrollTop, scrollHeight, clientHeight } = lastMessageRef.current;
          setShowArrowDown(scrollTop < scrollHeight - clientHeight); 
        }
      };
  
      if (lastMessageRef.current) {
        lastMessageRef.current.addEventListener("scroll", handleScroll);
      }
  
      return () => {
        // Cleanup the event listener when the component unmounts
        if (lastMessageRef.current) {
          lastMessageRef.current.removeEventListener("scroll", handleScroll);
        }
      };
    }, []);
  
    const scrollToLastMessage = () => {
      if (lastMessageRef.current) {
        lastMessageRef.current.scrollTop = lastMessageRef.current.scrollHeight;
      }
    };
  
    const handleArrowDownClick = () => {
      scrollToLastMessage();
    };

    return currentChat  ? (
      <>
  
  <Modal
    // shouldCloseOnEsc={true}
    shouldCloseOnOverlayClick={true}
    closeTimeoutMS={500}
    isOpen={modalIsOpen}
    onRequestClose={closeModal}
    style={customStyles}
    contentLabel="Add Guest"
    className="modal fade show wf-modal wf-delete-modal"
  >
    <div className="modal-dialog modal-dialog-centered wf-modal-dialog">
      <div className="modal-content">
        {modalType === "add" ? (
         <>
         <div class="modal-header border-0 pb-0">
          <h1 class="font-size-22 dm-font purple-color" id="staticBackdropLabel">{t('Confirmation')}</h1>
          <button type="button" onClick={closeModal} class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
            <div className="modal-body">
            <p className="color-black font-size-14 mb-2">{t('The price of offer that you are accepting is greater than your current budget. Would you like to proceed?')}</p>
            </div>
            <div class="modal-footer border-0 justify-content-center gap-2">
                <button
                  class="btn wf-btn-2 wf-purple-outline-btn py-2 px-4 rounded-2"
                  onClick={closeModal}
                >
                  {t('Cancel')}
                </button>
                <button
                  onClick={() => handleAcceptOffer("","","","","","","","","","","","modal")}
                  class="btn wf-btn-2 wf-pink-btn py-2 px-4 rounded-2"
                >
                  {t('Accept')}
                </button>
              </div>
          </>
        ) : modalType === "delete" ? (
          <>
         <div class="modal-header border-0 pb-0">
          <h1 class="font-size-36 playfair-font color-black" id="staticBackdropLabel">{t('Delete')} {t('Category')}</h1>
          <button type="button" onClick={closeModal} class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
            <div className="spacer-20"></div>
            <div className="modal-body">
            <p className="color-black font-size-14 mb-2">{t('Areyousureyouwanttodeletethis')} {t('Category')}</p>
            <div class="modal-footer border-0 justify-content-center gap-2">
            <button class="btn purple-color wf-btn btn-lg" onClick={closeModal}><i class="far fa-cross"></i> {t('Cancel')}</button>
            <button class="btn purple-color wf-btn btn-lg" ><i class="far fa-trash-alt"></i> {t('Delete')} {t('Category')}</button>
          </div>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  </Modal>
  
  <div style={{backgroundColor:'#F4F5F5', transition: 'all 0.5s ease'}} className={"col-lg-9 col-md-8 col-12 position-relative"}>
              <div class="py-2 px-4 border  grey-bg">
                  <div class="d-flex align-items-center gap-3 flex-wrap">
                      <div className="flex-grow-1 d-flex flex-wrap">
                      
                      <div class="flex-grow-1 d-flex gap-2 align-items-center ">
                      <img src={ currentChat?.vendor_store?.image
                                ? `${process.env.REACT_APP_IMGURL}${currentChat?.vendor_store?.image}`
                                : "https://via.placeholder.com/50x50"
                            } class="rounded-circle wf-user-image" alt={currentChat?.vendor_store?.store_name} width="40" height="40"/>
                      <div className="flex-grow-1">
                      <p className="font-size-16 purple-color mb-0 fw-bold">{currentChat?.vendor_store?.store_name}</p>
                      <p className="font-size-14 pink-color mb-0 text-capitalize fw-semibold">{currentChat?.group_membersvendor?.user_purpose}</p>
                      </div>
                      </div>
  
                      {chatmembers?.planner && profile?.role!=="planner" ? (<>
                        <div class="flex-grow-1 d-flex gap-2 align-items-center">
                      <img src={ chatmembers?.planner?.imageName ? `${process.env.REACT_APP_IMGURL}${chatmembers?.planner?.imageName}`
                        : "https://via.placeholder.com/50x50"} class="rounded-circle wf-user-image" alt={chatmembers?.planner?.name} width="40" height="40"/>
                      <div className="flex-grow-1">
                      <p className="font-size-16 purple-color mb-0 fw-bold">{chatmembers?.planner?.name}</p>
                      <p className="font-size-14 pink-color mb-0 fw-semibold">{t('WeddingPlanner')}</p>
                      </div>
                      </div>
                        </>
                      ) : null}
  
                      
                        <div className='wf-up-avatar d-flex mb-2'>
                                          <div className='avatar-couple-first'>
                                              <img width="57" height="57" alt=""  src={currentChat?.group_membersperson1?.imageName?`${process.env.REACT_APP_IMGURL}${currentChat?.group_membersperson1?.imageName}`:"https://via.placeholder.com/50x50"} class="rounded-circle avatar" style={{ border: '3px solid #804099', objectFit: 'cover' }} />
                                          </div>
                                          <div className='avatar-couple-sec' style={{ marginLeft: '-30px' }}>
                                              <img width="57" height="57" alt="" src={currentChat?.group_membersperson2?.imageName?`${process.env.REACT_APP_IMGURL}${currentChat?.group_membersperson2?.imageName}`:"https://via.placeholder.com/50x50"} class="rounded-circle avatar" style={{ border: '3px solid #EE0E7B', objectFit: 'cover' }} />
                                          </div>
                                          <div>
            <p className="font-size-14 color-black mb-0">Couple</p>
            <p className="font-size-16 purple-color mb-0 fw-medium">{currentChat?.group_membersperson1?.name||"partner"} & {currentChat?.group_membersperson2?.name||"partner"}</p>
          </div>
                                      </div>
                      
  
                      {(<>
                     <div style={{marginLeft:"100px"}} class="flex-grow-1 d-flex gap-2 align-items-center">
                      <img src={ profile?.imageName ? `${process.env.REACT_APP_IMGURL}${profile?.imageName}`: "https://via.placeholder.com/50x50"} 
                      class="rounded-circle wf-user-image" alt={profile?.name} width="40" height="40"/>
                      <div className="flex-grow-1">
                      <p className="font-size-16 purple-color mb-0 fw-bold">{profile?.name}</p>
                      <p className="font-size-14 pink-color mb-0 fw-semibold">You</p>
                      </div>
                      </div>
                      </>
                    )}
                  </div>
                  <div className="d-flex gap-3">
  
                  {profile?.role=="customer"||profile?.role=="planner"&&currentChat?.group_event!==null?(<div class="dropdown wf-chat-dropdown">
                  <div onClick={() => settoggle2(!toggle2)} className={`${toggle2==true ? "pink-color" : "color-grey"} cursor-pointer`} role="button" data-bs-toggle="dropdown" aria-expanded="false">
                      <UserPlus width="22" height="22"/>
                  </div>
  
                  <div className="dropdown-menu">
                      <div className="d-flex align-items-center gap-2 mb-3">
                          <div className="pink-color">
                          <UserPlus width="22" height="22" />
                          </div>
                          <p className="font-size-14 pink-color mb-0"><u>{t('Add')} {t('User')}</u></p>
                      </div> 
                      <div className="d-flex align-items-center gap-3 px-2">   
                      {membersToMap?.length > 0 ? (
                      <>
                      {["person1", "person2", "planner"].map((key) =>
                        membersToMap[0]?.[key] && !chatmembers?.[key] ? (
                          <>
                          <input onClick={() => handleaddmember( membersToMap[0]?.[key]?.name,  membersToMap[0]?.[key]?._id,
                             membersToMap[0]?.[key]?.user_purpose)} type="checkbox" name="add-user" class="btn-check" id={membersToMap[0]?.[key]?.user_purpose ? membersToMap[0]?.[key]?.user_purpose : "Partner"} />
                          <label key={key} class="btn wf-add-user" for={membersToMap[0]?.[key]?.user_purpose ? membersToMap[0]?.[key]?.user_purpose : "Partner"}>
                          <img src={ membersToMap[0]?.[key]?.imageName ? `${process.env.REACT_APP_IMGURL}${membersToMap[0]?.[key]?.imageName}`
                           : "https://via.placeholder.com/50x50"} class="rounded-circle wf-add-user-image" alt="Partner"/>
                          <p className="font-size-12 mb-0">{membersToMap[0]?.[key]?.name? membersToMap[0]?.[key]?.name: "Partner"}</p>
                          <p className="font-size-12 mb-0">{membersToMap[0]?.[key]?.user_purpose ? membersToMap[0]?.[key]?.user_purpose : "Partner"}</p>
                          <UserPlus width="22" height="22" stroke="#ffffff" className="wf-add-user-selected" />
                          </label>
                          </>
                          ) : null
                          )}
                        </>
                      ) : null}
                      </div>
                  </div>
                  </div>):null}
  
                  <div onClick={() => setsearchtoggle(!searchtoggle)} className={`${searchtoggle==true ? "pink-color" : "color-grey"} cursor-pointer`}>
                  <Search width="22" height="22" />
                  </div>
                  {/* <div onClick={() => setsidepanel(!sidepanel)} className={`${sidepanel==true ? "pink-color" : "color-grey"} cursor-pointer`}>
                  <PanelRight width="22" height="22" />
                  </div> */}
                  </div>
              </div>
              </div>
              {searchtoggle==true ? <div class="py-2 px-4 border wf-search-panel grey-bg">
              <div class="input-group">
              <input onChange={(e)=>setsearchText(e.target.value)} type="text" class="form-control" placeholder={t('Searchconversations')} id="search"/>
              <button onClick={highlightAndScrollToMessage} class="input-group-text" id="basic-addon1"><Search width="22" height="22" /></button>
              </div>
              </div> :null}
              
  
              <div class="wf-chat-messages p-4" ref={lastMessageRef}>
              {showArrowDown && (
          <ArrowDown className="arrow-button purple-color" onClick={handleArrowDownClick}/>
          
          
        )}
              {message?.map((c, i) =>
                c?.messageType == "message" ? (
                  <>
                  
                    <div className={ c?.sender_id?._id == profile?._id ? `wf-chat-message-right` : `wf-chat-message-left`}>
                    <img src={c?.sender_id?.imageName?`${process.env.REACT_APP_IMGURL}${c?.sender_id?.imageName}`:"https://via.placeholder.com/50x50"} class="rounded-circle wf-message-user-image" alt={profile?.name}/>
                    <div class="flex-shrink-1">
                    {c?.sender_id?._id === profile?._id ? (
                      <p className="font-size-14 mb-0 wf-message-user-name">
    <b>{profile?.name}</b>
    {c?.sender_id?.role !== "vendor" && (
      <>&nbsp;<span className="color-black text-capitalize">{profile?.user_purpose}</span></>
    )}
  </p>
                    ) : (
                      <p className="font-size-14 mb-0 wf-message-user-name"><b>{c?.sender_id?.name} {c?.byAdmin&&`(By Admin)`}</b> {c?.sender_id?.role !== "vendor" && (
                        <>&nbsp; <span className="color-black text-capitalize">{c?.sender_id?.user_purpose}</span></>
                        )}</p>
                    )}
                      <div className="wf-message-text ">
  
                      {/* {c?.images?.length > 0 &&<div className="d-flex flex-wrap gap-2 wf-message-images">
                      {c?.images?.map((image) => (
                      <div className="wf-message-image">
                      <img src={`${process.env.REACT_APP_IMGURL}${image}`} alt="media"/>
                      </div>
                      ))}
                      <div onClick={() => setisOpen(true)} className="wf-message-image wf-image-count"><span>+5</span></div>
                      {isOpen && (
                          <Lightbox
                          mainSrc="https://images.unsplash.com/photo-1580489944761-15a19d654956"
                          nextSrc="https://images.unsplash.com/photo-1580489944761-15a19d654956"
                          prevSrc="https://images.unsplash.com/photo-1580489944761-15a19d654956"
                          onCloseRequest={() => setisOpen(false)}
                          // onMovePrevRequest=""
                          // onMoveNextRequest=""
                          />
                      )}
                      </div>} */}
                      {c?.images?.length > 0 && (
                      <div className="d-flex flex-wrap gap-2 wf-message-images">
                        {c?.images?.slice(0, 4)?.map((image, index) => (
                          <div key={index} className="wf-message-image">
                            <img src={`${process.env.REACT_APP_IMGURL}${image}`} alt={`media-${index}`} />
                          </div>
                        ))}
                        {c?.images.length > 4 && (
                          <div onClick={() => setisOpen(true)} className="wf-message-image wf-image-count">
                            <span>+{c?.images.length - 4}</span>
                          </div>
                        )}
                        {/* {isOpen && (
                          <Lightbox
                            mainSrc={`${process.env.REACT_APP_IMGURL}${c?.images[0]}`}
                            nextSrc={`${process.env.REACT_APP_IMGURL}${c?.images[0]}`}
                            prevSrc={`${process.env.REACT_APP_IMGURL}${c?.images[0]}`}
                            onCloseRequest={() => setisOpen(false)}
                          />
                        )} */}
  
                      {isOpen && (
                        <Lightbox
                          mainSrc={`${process.env.REACT_APP_IMGURL}${c?.images[photoIndex]}`}
                          nextSrc={
                            c?.images[
                              (photoIndex + 1) % c?.images?.length
                            ]
                          }
                          prevSrc={
                            c?.images[
                              (photoIndex + c?.images?.length - 1) %
                                c?.images?.length
                            ]
                          }
                          onCloseRequest={() => setisOpen(false)}
                          onMovePrevRequest={() =>
                            setphotoIndex(
                              (photoIndex + c?.images?.length - 1) %
                                c?.images?.length
                            )
                          }
                          onMoveNextRequest={() =>
                            setphotoIndex(
                              (photoIndex + 1) % c?.images?.length
                            )
                          }
                        />
                      )}
  
                      </div>
                    )}
                      {/* {c?.images?.length > 0 ? c?.images?.map((image) => (
                        <div className="img-bg-size">
                          <img
                          
                            src={`${process.env.REACT_APP_IMGURL}${image}`}
                            
                          />
                        </div>
                        ))
                      : null} */}
                      {c?.message}
                      </div>
                      <div className="d-flex gap-3 align-items-center wf-text-bottom">
                      <p className="font-size-10 mb-0 color-black">{moment(c?.createdAt).format("dddd, MMMM D, YYYY [at] HH:mm")}</p>
                      {c?.sender_id?._id === profile?._id&&(<p className="font-size-10 mb-0 wf-seen-tick"><CheckCheck width="10" height="10" /></p>)}
                      </div>
                  </div>
                    </div>
                  </>
                ) : c?.messageType == "request" ? (
                  <>
                  <div id={profile?.role == "customer" ||profile?.role=="planner"&&profile?._id==currentChat?.group_membersplanner?._id? "sent-request": "recived-request"} className={ c?.sender_id?._id == profile?._id ? `wf-chat-message-right` : `wf-chat-message-left`}>
              <img src={c?.sender_id?.imageName?`${process.env.REACT_APP_IMGURL}${c?.sender_id?.imageName}`:"https://via.placeholder.com/50x50"} class="rounded-circle wf-message-user-image" alt={profile?.name}/>
                  <div class="flex-shrink-1">
                  {c?.sender_id?._id === profile?._id ? (
                      <p className="font-size-14 mb-0 wf-message-user-name"><b>{profile?.name}</b> {c?.sender_id?.role !== "vendor" && (
                        <>&nbsp; <span className="color-black text-capitalize">{profile?.user_purpose}</span></>
                        )}</p>
                    ) : (
                      <p className="font-size-14 mb-0 wf-message-user-name"><b>{c?.sender_id?.name}</b> {c?.sender_id?.role !== "vendor" && (
                        <>&nbsp; <span className="color-black text-capitalize">{c?.sender_id?.user_purpose}</span></>
                        )}</p>
                    )}
                      <div className="wf-message-text">
                      <div className="d-flex flex-wrap mb-2">
                          <div className="col-md-4">
                          <p className="font-size-16 purple-color mb-0">{t('Event')} {t('Name')}: <span className="color-black">{c?.request?.event_id?.event_name}</span></p>
                          </div>
                          <div className="col-md-4">
                          <p className="font-size-16 purple-color mb-0">{t('Guests')}: <span className="color-black">{c?.request?.event_id?.no_of_guests}</span></p>
                          </div>
                          <div className="col-md-4">
                          <p className="font-size-16 purple-color mb-0">{t('Date')}: <span className="color-black">{moment(c?.request?.event_id?.event_date).format("DD-MM-YYYY")}</span></p>
                          </div>
                      </div>
                      <p className="font-size-16 purple-color mb-1">{t('Request')} {t('Details')}:</p>
                      <p className="font-size-16 color-black mb-2">{c?.request?.comments}</p>
                      {c?.request?.request_status==2&&profile?.role=="vendor"||profile?.role=="planner"&&profile?._id==currentChat?.group_membersvendor?._id?(
                      <div className="d-flex justify-content-end gap-3">
                      <p onClick={() => handleAcceptRequest(c?.request?.event_id, c?.request?._id,)} className="font-size-16 purple-color mb-0 cursor-pointer"><u>Accept</u></p>
                      <p onClick={() => handleRejectRequest( c?.request?._id, c?.group_id?._id,)} className="font-size-16 pink-color mb-0 cursor-pointer"><u>Decline</u></p>
                      </div>):c?.request?.request_status==1?(<p className="font-size-16 purple-color mb-2 mt-1 text-end">{`Offer Accepted On ${moment(c?.updatedAt).format("dddd, MMMM D, YYYY [at] h:mm A")}`}</p>)
                      :c?.request?.request_status==0?(<p className="font-size-16 purple-color mb-2 mt-1 text-end">{`Offer Rejected On ${moment(c?.updatedAt).format("dddd, MMMM D, YYYY [at] h:mm A")}`}</p>):null
                    }
                      </div>
                      <div className="d-flex gap-3 align-items-center wf-text-bottom">
                      <p className="font-size-10 mb-0 color-black">{moment(c?.createdAt).format("dddd, MMMM D, YYYY [at] HH:mm")}</p>
                      {c?.sender_id?._id === profile?._id&&(<p className="font-size-10 mb-0 wf-seen-tick"><CheckCheck width="10" height="10" /></p>)}
                      </div>
                  </div>
              </div>
                  </>
                ) : c?.messageType == "offer" ? (
                  <>
                  <div id={profile?.role == "customer" ||profile?.role=="planner"&&profile?._id==currentChat?.group_membersplanner?._id? "offer-received": "offer-sent"} className={ c?.sender_id?._id == profile?._id ? `wf-chat-message-right` : `wf-chat-message-left`}>
              <img src={c?.sender_id?.imageName?`${process.env.REACT_APP_IMGURL}${c?.sender_id?.imageName}`:"https://via.placeholder.com/50x50"} class="rounded-circle wf-message-user-image" alt={profile?.name}/>
                  <div class="flex-shrink-1">
                  {c?.sender_id?._id === profile?._id ? (
                      <p className="font-size-14 mb-0 wf-message-user-name"><b>{profile?.name}</b> {c?.sender_id?.role !== "vendor" && (
                        <>&nbsp; <span className="color-black text-capitalize">{profile?.user_purpose}</span></>
                        )}</p>
                    ) : (
                      <p className="font-size-14 mb-0 wf-message-user-name"><b>{c?.sender_id?.name}</b> {c?.sender_id?.role !== "vendor" && (
                        <>&nbsp; <span className="color-black text-capitalize">{c?.sender_id?.user_purpose}</span></>
                        )}</p>
                    )}
                      <div className="wf-message-text">
                      <div className="d-flex flex-wrap mb-2">
                          <div className="col-md-6">
                          <p className="font-size-16 purple-color mb-0">{t('TotalPrice')}: <span className="color-black">SAR {c?.offer?.price?.totalPrice}</span></p>
                          </div>
                          <div className="col-md-6">
                          <p className="font-size-16 purple-color mb-0">{t('Initial Deposit')}: <span className="color-black">SAR {c?.offer?.price?.initialDeposit}</span></p>
                          </div>
                          <div className="col-md-6">
                          <p className="font-size-16 purple-color mb-0">{t('Deposit')} {t('Date')}: <span className="color-black">{c?.offer?.price?.initialDepositDate?moment(c?.offer?.price?.initialDepositDate).format("DD-MM-YYYY"):""}</span></p>
                          </div>
                          <div className="col-md-6">
                          <p className="font-size-16 purple-color mb-0">{t('Final Deposit')}: <span className="color-black">SAR {c?.offer?.price?.finalDeposit}</span></p>
                          </div>
                          <div className="col-md-6">
                          <p className="font-size-16 purple-color mb-0">{t('Final Deposit')} {t('Date')}: <span className="color-black">{c?.offer?.price?.finalDepositDate?moment(c?.offer?.price?.finalDepositDate).format("DD-MM-YYYY"):""}</span></p>
                          </div>
                      </div>
                      <p className="font-size-16 purple-color mb-1">{t('Offer')} {t('Details')}:</p>
                      <p className="font-size-16 color-black mb-2">{c?.offer?.comments}</p>
                      {profile?.role == "customer" ||profile?.role=="planner"&&profile?._id==currentChat?.group_membersplanner?._id ? (
                        <div className="d-flex justify-content-end gap-3">
                          {c?.offer?.request_status == 2 ? (
                            <>
                              <p onClick={() => handleAcceptOffer( c?.offer?._id, c?.group_id?._id, c?.offer?.event_id?._id, c?.offer?.price?.initialDeposit , c?.offer?.price?.initialDepositDate , c?.offer?.price?.finalDeposit, c?.offer?.price?.finalDepositDate,  c?.offer?.price?.totalPrice, currentChat?.vendor_store?._id, currentChat?.vendor_store?.prefered_services[0], currentChat?.vendor_store?.store_name, "")}
                           className="font-size-16 purple-color mb-0 cursor-pointer">{load ? (
                            <div className="spinner-border wf-spinner-border" role="status">
                              <span className="visually-hidden">Loading...</span>
                            </div>
                          ) : (<u>{t('Accept')}</u>)}</p>
                           <p onClick={() => handleRejectOffer( c?.offer?._id, c?.group_id )} className="font-size-16 pink-color mb-0 cursor-pointer">{load1 ? (
            <div className="spinner-border wf-spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          ) : (<u>{t('Decline')}</u>)}</p>
                            </>
                          ) : c?.offer?.request_status == 3 ? (
                            <>
                              <p className="font-size-16 purple-color mb-0">{t('Another offer has been accepted')}</p>
                            </>
                          ) : c?.offer?.request_status == 1 ? (
                            <>
                            <p className="font-size-16 purple-color mb-0">{t('This offer has been accepted')}</p>
                            </>
                          ) : null}
                        </div>
                      ) : c?.offer?.request_status==1?(<p className="font-size-16 purple-color mb-0 text-end">{`Offer Accepted On ${moment(c?.updatedAt).format("dddd, MMMM D, YYYY [at] h:mm A")}`}</p>)
                      :c?.offer?.request_status==0?(<p className="font-size-16 purple-color mb-0 text-end">{`Offer Rejected On ${moment(c?.updatedAt).format("dddd, MMMM D, YYYY [at] h:mm A")}`}</p>):null}
                      </div>
                      <div className="d-flex gap-3 align-items-center wf-text-bottom">
                      <p className="font-size-10 mb-0 color-black">{moment(c?.createdAt).format("dddd, MMMM D, YYYY [at] HH:mm")}</p>
                      {c?.sender_id?._id === profile?._id&&(<p className="font-size-10 mb-0 wf-seen-tick"><CheckCheck width="10" height="10" /></p>)}
                      </div>
                  </div>
              </div>
                  </>
                ) : null
              )}
              
              {/* <div class="wf-chat-message-right">
              <img src="https://images.unsplash.com/photo-1580489944761-15a19d654956" class="rounded-circle wf-message-user-image" alt="Chris Wood"/>
                  <div class="flex-shrink-1">
                      <p className="font-size-14 mb-0 wf-message-user-name"><b>John D.</b> &nbsp; <span className="color-black">Florist</span></p>
                      <div className="wf-message-text">
                      <div className="d-flex gap-2 wf-message-media">
                      <FileAudio width={22} height={22} stroke={"#804099"} />
                      <div style={{marginRight:'10px'}}>
                      <p className="font-size-14 mb-0 color-black fw-semibold">Weddified Audio</p>
                      <p style={{color:'#646464'}} className="font-size-12 mb-0">Audio</p>
                      </div>
                      <div className="cursor-pointer">
                      <DownloadCloud width={22} height={22} stroke={"#646464"} />
                      </div>
                      </div>
                      </div>
                      <div className="d-flex gap-3 align-items-center wf-text-bottom">
                      <p className="font-size-10 mb-0 color-black">9:54 PM</p>
                      <p className="font-size-10 mb-0 wf-seen-tick"><CheckCheck width="10" height="10" /> Seen</p>
                      </div>
                  </div>
              </div>
              <div class="wf-chat-message-right">
              <img src="https://images.unsplash.com/photo-1580489944761-15a19d654956" class="rounded-circle wf-message-user-image" alt="Chris Wood"/>
                  <div class="flex-shrink-1">
                      <p className="font-size-14 mb-0 wf-message-user-name"><b>John D.</b> &nbsp; <span className="color-black">Florist</span></p>
                      <div className="wf-message-text">
                      <div className="d-flex gap-2 wf-message-media">
                      <FileText width={22} height={22} stroke={"#804099"} />
                      <div style={{marginRight:'10px'}}>
                      <p className="font-size-14 mb-0 color-black fw-semibold">Weddified Doc</p>
                      <p style={{color:'#646464'}} className="font-size-12 mb-0">Doc</p>
                      </div>
                      <div className="cursor-pointer">
                      <DownloadCloud width={22} height={22} stroke={"#646464"} />
                      </div>
                      </div>
                      </div>
                      <div className="d-flex gap-3 align-items-center wf-text-bottom">
                      <p className="font-size-10 mb-0 color-black">9:54 PM</p>
                      <p className="font-size-10 mb-0 wf-seen-tick"><CheckCheck width="10" height="10" /> Seen</p>
                      </div>
                  </div>
              </div>
              <div class="wf-chat-message-right">
              <img src="https://images.unsplash.com/photo-1580489944761-15a19d654956" class="rounded-circle wf-message-user-image" alt="Chris Wood"/>
                  <div class="flex-shrink-1">
                      <p className="font-size-14 mb-0 wf-message-user-name"><b>John D.</b> &nbsp; <span className="color-black">Florist</span></p>
                      <div className="wf-message-text">
                      <div className="d-flex gap-2 wf-message-media">
                      <FileVideo width={22} height={22} stroke={"#804099"} />
                      <div style={{marginRight:'10px'}}>
                      <p className="font-size-14 mb-0 color-black fw-semibold">Weddified Video</p>
                      <p style={{color:'#646464'}} className="font-size-12 mb-0">Video</p>
                      </div>
                      <div onClick={handleDownload} className="cursor-pointer">
                      <DownloadCloud width={22} height={22} stroke={"#646464"} />
                      </div>
                      </div>
                      </div>
                      <div className="d-flex gap-3 align-items-center wf-text-bottom">
                      <p className="font-size-10 mb-0 color-black">9:54 PM</p>
                      <p className="font-size-10 mb-0 wf-seen-tick"><CheckCheck width="10" height="10" /> Seen</p>
                      </div>
                  </div>
              </div> */}
          </div>
  
          
          {toggle1 && profile?.role=="customer" || toggle1 && profile?.role=="planner"&&profile?._id==currentChat?.group_membersplanner?._id ? (<>
            <div className="wf-request-offer-box rounded p-3">
            <div className="d-flex flex-wrap mb-2">
            <div className="col-md-12 mb-2">
            <div class="dropdown wf-filter-dropdown">
              <a
                onClick={() => settoggle(!toggle)}
                className="btn dropdown-toggle"
                show
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {selectedEvent?.event_name
                  ? selectedEvent?.event_name
                  : "Event Name"}
              </a>
  
              {myEvents && profile?.role=="customer" ||  profile?.role=="planner" ? (
                <div className={`dropdown-menu ${toggle && "show"}`}>
                  {myEvents?.map((e) => (
                    <div
                      onClick={() => {
                        setselectedEvent(e.Event);
                        settoggle(false);
                      }}
                      className="event-list d-flex cursor-pointer mb-2"
                    >
                      <div
                        style={{
                          backgroundColor: e?.Event?.event_color,
                          width: "5px",
                          borderRadius: "3px",
                          marginRight: "12px",
                        }}
                      ></div>
                      <div className="">
                        <p className="font-size-14 color-black mb-0">
                          <b>{e?.Event?.event_name}</b>
                        </p>
                        <p className="font-size-10 color-black mb-0">
                          {moment(e?.Event?.event_date).format(
                            "DD-MM-YYYY"
                          )}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                null
              )}
            </div>
            </div>
                {/* <div className="col-md-4">
                <p className="font-size-16 purple-color mb-0">Event Name: <span className="color-black">Engagement</span></p>
                </div> */}
                <div className="col-md-6">
                <p className="font-size-16 purple-color mb-0">{t('Guests')}: <span className="color-black">{ selectedEvent ? selectedEvent?.no_of_guests : ""}</span></p>
                </div>
                <div className="col-md-6">
                <p className="font-size-16 purple-color mb-0">{t('Date')}: <span className="color-black">{selectedEvent ? selectedEvent?.event_date : ""}</span></p>
                </div>
            </div>
            <p className="font-size-16 purple-color mb-1">{t('Request')} {t('Details')}:</p>
            <textarea class="form-control mb-2" placeholder={t('Type your message here')} onChange={(e) => setcomments(e.target.value)} style={{boxShadow:'none',border:0}}></textarea>
            
            <div className="d-flex justify-content-end gap-3">
            <p className="font-size-16 color-black mb-0 text-end cursor-pointer" onClick={() => settoggle1(false)}><u>{t('Cancel')}</u></p>
            <p className="font-size-16 pink-color mb-0 text-end cursor-pointer" onClick={(e) => sendMessage(e, "send_req")}><u>{t('Send')}</u></p>
            </div>
        </div>
                </>):toggle1 && profile?.role=="vendor"|| toggle1 && profile?.role=="planner"&&profile?._id==currentChat?.group_membersvendor?._id ?(<>
                  <div className="wf-request-offer-box rounded p-3">
                  <div className="row mb-2">
                            <div className="col-md-6">
                            <div class="form-floating mb-3 wf-input wf-input-101">
                                <input onChange={(e) => {
                                  const enteredValue = parseInt(e.target.value);
                                  if (!isNaN(enteredValue) && enteredValue >= 0) {
                                    setprice(enteredValue);
                                  } else {
                                    e.target.value = null
                                  }
                                }}  type="number" class="form-control" id="price-number" placeholder="" autocomplete="price" />
                                <label for="price-number">{t('TotalPrice')}</label>
                            </div>
                            </div>
                            
                            <div className="col-md-6">
                            <div class="form-floating mb-3 wf-input wf-input-101">
                                <input onChange={(e) => {
                                const enteredValue = parseInt(e.target.value);
                                if (!isNaN(enteredValue) && enteredValue >= 0) {
                                  setinitialdeposit(enteredValue);
                                } else {
                                  e.target.value = null
                                }
                              }}  type="number" class="form-control" id="price-number" placeholder="" autocomplete="price" />
                                <label for="price-number">{t('InitialDeposit')}</label>
                            </div>
                            </div>
  
                            <div className="col-md-6">
                      <div class="form-floating mb-3 wf-input wf-input-101">
                        <input
                          min={getCurrentDate()}
                          onChange={(e) => setinitialdepositdate(e.target.value)}
                          type="date"
                          class="form-control"
                          id="event-date"
                          placeholder=""
                          autocomplete="date"
                          required
                        />
                        <label for="event-date">{t('InitialDeposit')} {t('Date')}</label>
                      </div>
                    </div>
                            
                            <div className="col-md-6">
                            <div class="form-floating mb-3 wf-input wf-input-101">
                                <input disabled value={price-initialdeposit} type="number" class="form-control" id="price-number" placeholder="" autocomplete="price" />
                                <label for="price-number">{t('FinalPayment')}</label>
                            </div>
                            </div>
  
                            <div className="col-md-6">
                      <div class="form-floating mb-3 wf-input wf-input-101">
                        <input
                          min={getCurrentDate()}
                          onChange={(e) =>setfinaldepositdate(e.target.value)}
                          type="date"
                          class="form-control"
                          id="event-date"
                          placeholder=""
                          autocomplete="date"
                          required
                        />
                        <label for="event-date">{t('Final Deposit')} {t('Date')}</label>
                      </div>
                    </div>
                  </div>
              <p className="font-size-16 purple-color mb-1">{t('Offer')} {t('Details')}:</p>
              <textarea class="form-control mb-2" onChange={(e) => setcomments(e.target.value)} placeholder={t('Type your message here')} style={{boxShadow:'none',border:0}}></textarea>
              <div className="d-flex justify-content-end gap-3">
            <p className="font-size-16 color-black mb-0 text-end cursor-pointer" onClick={() => settoggle1(false)}><u>{t('Cancel')}</u></p>
            <p className="font-size-16 pink-color mb-0 text-end cursor-pointer" onClick={(e) => sendMessage(e, "send_offer")}><u>{t('Send')}</u></p>
            </div>
          </div>
           </>)
              
              :null}
  
          <div class="py-2 px-3 wf-shadow-2 mx-3 rounded grey-bg wf-write-message mb-1 position-relative">
          {/* {(photoFiles.length > 0 || otherFile) && (<div className="wf-upload-box grey-bg rounded">
          {photoFiles.map((file, index) => (
          <div key={index} className="wf-uploaded-image-size">
              <img src={URL.createObjectURL(file)} alt={file.name} />
              <div className="crox-icon">
              <X width="18" height="18" />
              </div>
          </div>
          ))}
  
          {otherFile && (
          <div className="wf-selected-file">
              {fileIcons[getFileType(otherFile)]}
              <div>
              <p className="font-size-14 black-color titilium-font mb-0">{otherFile.name}</p>
              <p className="font-size-10 color-grey titilium-font mb-0">{otherFile.type}</p>
              </div>
              <div className="crox-icon">
              <X width="18" height="18" />
              </div>
          </div>
          )}
          </div>)} */}
   
   {attachedImages && attachedImages.length > 0 && (
        <div className="wf-upload-box grey-bg rounded">
          {attachedImages?.map((image, index) => (
          <div key={index} className="wf-uploaded-image-size">
              <img src={URL.createObjectURL(image)} alt={image.name} />
              <div onClick={(e) => handleRemoveImage(e, index)} className="crox-icon">
              <X width="18" height="18" />
              </div>
          </div>
          ))}
        </div>)}
      
          <form action="#" method="get" accept-charset="utf-8" onSubmit={(e) => {e.preventDefault(); sendMessage(e, "message"); }}>
          <textarea 
          // value={selectedEmojis.join(' ')} onChange={(e) => setSelectedEmojis(e.target.value.split(' '))} 
          value={messageText.text} 
          // value={`${messageText?.text}${messageText?.emojis?.join('')}`}
          onChange={(e) => handleTextChange(e)} 
          // onChange={(e) => setmessageText({ ...messageText, text: e.target.value })}
          onKeyDown={(e) => {if (e.key === 'Enter') { e.preventDefault();  sendMessage(e, "message");} }}
          style={{boxShadow:'none',border:0}} class="form-control" placeholder={t('Type your message here')}></textarea>
          <div className="d-flex justify-content-between mt-3">
              <div className="d-flex align-items-center gap-3">
              <div class="dropdown wf-chat-dropdown">
                  <div class="purple-color cursor-pointer" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                      <PlusCircle width="22" height="22"/>
                  </div>
  
                  <div class="dropdown-menu">
                      <div className="d-flex align-items-center gap-2 mb-3">
                          <div className="pink-color">
                          <Paperclip width="22" height="22" />
                          </div>
                          <p className="font-size-14 pink-color mb-0"><u>{t('Attachment')}</u></p>
                      </div>
                      <div className="d-flex align-items-center gap-3 px-2">
                      <label for="doc-attachment" className="d-flex flex-column align-items-center gap-2">
                      <FileText width={22} height={22} stroke={"#B1B1B1"} />
                      <p style={{color:'#B1B1B1'}} className="font-size-12 mb-0">{t('Doc')}</p>
                      </label>
                      {/* <input max={1} type="file" class="btn-check" id="doc-attachment" accept=".xlsx,.xls,.doc, .docx,.ppt, .pptx,.txt,.pdf"/> */}
  
                      <label for="gallery-attachment" className="d-flex flex-column align-items-center gap-2 cursor-pointer">
                      <Image width={22} height={22} stroke={"#804099"} />
                      <p className="font-size-12 purple-color mb-0">{t('Gallery')}</p>
                      </label>
                      <input multiple onChange={(e) => handleImageChange(e)} type="file" class="btn-check" id="gallery-attachment" accept="image/*"/>
  
                      <label for="audio-attachment" className="d-flex flex-column align-items-center gap-2 ">
                      <FileAudio width={22} height={22} stroke={"#B1B1B1"} />
                      <p style={{color:'#B1B1B1'}} className="font-size-12 mb-0">{t('Audio')}</p>
                      </label>
                      {/* <input max={1} type="file" class="btn-check" id="audio-attachment" accept=".mp3,audio/*"/> */}
  
                      <label for="video-attachment" className="d-flex flex-column align-items-center gap-2 ">
                      <FileVideo width={22} height={22} stroke={"#B1B1B1"} />
                      <p style={{color:'#B1B1B1'}} className="font-size-12 mb-0">{t('Video')}</p>
                      </label>
                      {/* <input max={1} type="file" class="btn-check" id="video-attachment" accept="video/mp4,video/x-m4v,video/*"/> */}
  
                      <div className="d-flex flex-column align-items-center gap-2">
                      <Contact width={22} height={22} stroke={"#B1B1B1"} />
                      <p style={{color:'#B1B1B1'}} className="font-size-12 mb-0">{t('Contact')}</p>
                      </div>
                      </div>
                  </div>
              </div>
              {/* <div class="dropdown wf-chat-dropdown">
                  <div class="purple-color cursor-pointer" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                      <SmilePlus width="22" height="22"/>
                  </div>
  
                  <div class="dropdown-menu">
                      <div className="d-flex align-items-center gap-2 mb-3">
                          <div className="pink-color">
                          <SmilePlus width="22" height="22"/>
                          </div>
                          <p className="font-size-14 pink-color mb-0"><u>Emojis</u></p>
                      </div>
                      <div><Picker data={data} onEmojiSelect={handleEmojiSelect} /></div>
                  </div>
              </div> */}
              {!currentChat?.group_event&&(<p onClick={() => settoggle1(!toggle1)}className="font-size-16 purple-color mb-0 cursor-pointer">
                {!currentChat?.group_event&&(<u>{t('Request an offer')}</u>)}
              </p>)}
                  {/* <p onClick={() => setRequestOffer(!RequestOffer)} className=""></p> */}
              </div>
              <button type="submit" className="purple-color cursor-pointer wf-message-send-btn">
                  <Send width="22" height="22" />
              </button>
          </div>
          </form>
          </div>
  
          </div> 
      
      </>
    ) : 
      null

};

export default ChatMessage;