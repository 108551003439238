import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Header from "./HeaderLight";
import Footer from "./Footer";
import { Heart } from "lucide-react";
import moment from "moment";
import Page404 from "./404";
import { MapContainer, TileLayer, Marker, Popup, GeoJSON } from "react-leaflet";
import {
  getSocket,
  getProfile,
  getEventList,
} from "../../store/slices/userSlice";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  EffectFade,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { toast } from "react-toastify";
import Modal from "react-modal";
import VendorCardSkeleton from "../LoadingSkeleton/VendorCardSkeleton";
import { io } from "socket.io-client";
import VendorCardSkeletonHome from "../LoadingSkeleton/VendorCardSkeletonHome";
import VendorDetailSkeleton from "../LoadingSkeleton/VendorDetailSkeleton";
import { useTranslation } from "react-i18next";
const socket = io(process.env.REACT_APP_APIURL);
const customStyles = {
  content: {
    display: "block",
    "--bs-modal-width": "40%",
  },
};
Modal.setAppElement("#root");

const Storefront = () => {
  const { t } = useTranslation();
  const savedLanguage = localStorage.getItem('selectedLanguage');
  // const socket = useSelector(getSocket);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const profile = useSelector(getProfile);
  const myEvents = useSelector(getEventList);
  const [event, setevent] = useState([]);
  const [category_id, setcategory_id] = useState("");
  const [store_id, setstore_id] = useState("");
  const [modalIsOpen, setmodalIsOpen] = useState(false);
  const [load, setload] = useState(false);
  const [ready, setready] = useState(false);
  const [data, setdata] = useState(location?.state?.data);
  const [suggvendors, setsuggvendors] = useState([]);
  const [showfull, setshowfull] = useState(false);
  const [questions, setquestions] = useState([]);
  const limit = 500;
  const [photoIndex, setphotoIndex] = useState(0);
  const [videoIndex, setvideoIndex] = useState(0);
  const [isOpen, setisOpen] = useState();
  const [markers, setmarkers] = useState([]);
  const [initialPosition, setinitialPosition] = useState([]);
  const [checkimglength, setcheckimglength] = useState([]);
  const [message, setmessage] = useState("");
  const [toggle1, settoggle1] = useState([]);
  const [modalType, setmodalType] = useState("");
  const [storetofav, setstoretofav] = useState("");



  //Claim Store
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [contactNumber, setContactNumber] = useState('');
  const [relationship, setrelationship] = useState('');
  const [website, setWebsite] = useState('');
  
  //Report Store
  const [reason, setReason] = useState('');
  const [comment, setComment] = useState('');



  const zoomLevel = 13;
  const mapRef = useRef(null);
  useEffect(() => {
    if (mapRef.current) {
      const map = mapRef.current.leafletElement;
    }
  }, [initialPosition]);

  const sendMessage = (e) => {
    e.preventDefault();

    if (profile && profile?.role == "customer" || profile?.role == "planner") {
      if (message == "") {
        toast.error("Message cannot be empty");
      } else {
        socket?.emit("send_message", {
          role: profile?.role, //new key
          sender_id: profile?._id,
          receiver_id: data?.user_id,
          message: message,
          connection: false
        });
        toast.success("Message sent successfully");
        setmessage("");
      }
    } else {
      navigate("/login",{state: location.pathname});
    }
  };
  console.log("socket", socket);

  const IncrementView = async (store_id, type) => {
    try {
      console.log("store_id", store_id);
      await axios.post(`vendorpage/incrementview`, {
        store_id: store_id,
        type: type,
      });
    } catch (error) {
      console.error("Error incrementing view:", error);
    }
  };

  useEffect(() => {
    if (data) {
      if (data?.location !== null)
        setmarkers([
          {
            lat: data?.location?.coordinates[1],
            lng: data?.location?.coordinates[0],
            name: data?.store_name,
          },
        ]);
      setinitialPosition([
        data?.location?.coordinates[1],
        data?.location?.coordinates[0],
      ]);
    }
  }, [data]);

  const viewModal = async (store_id, category_id, type, storeToFav, events) => {
    console.log("firsttttttt", store_id, category_id, type, storeToFav, events);

    if (profile?.role == "customer" && myEvents?.length < 1) {
      toast.error("You don't have any event to shortlist this store");
    } else {
      setmodalIsOpen(true);
      setstore_id(store_id);
      setcategory_id(category_id);
      setevent(events)
      setmodalType(type);
      setstoretofav(storeToFav == "thisStore" ? "thisStore" : "suggestedStore");
    }
  };

  function closeModal() {
    setmodalIsOpen(false);
  }

  console.log("suggvendors", suggvendors);

  const mapIcon = new L.Icon({
    iconUrl: "../assets/images/map-pin-logo.svg",
    iconRetinaUrl: "../assets/images/map-pin-logo.svg",
    iconAnchor: new L.Point(0, 0),
    popupAnchor: new L.Point(16, 0),
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(32, 32),
    className: "weddified-map-icon",
  });

  console.log("EventList", myEvents);

  const [showFullContent, setShowFullContent] = useState([]);

  const toggleReadMore = (index) => {
    setShowFullContent((prev) => {
      const newState = [...prev];
      newState[index] = !newState[index];
      return newState;
    });
  };

  const handlePin = async () => {

    try {
    
    const finalIndex= store_id
  
    
   
    console.log('event', event)
    console.log('store_id', store_id)
    console.log('category_id', category_id)
    const abc = await axios.post("/vendorpage/favourite",{ id: profile?._id, user_events: myEvents?.map((evv)=>evv.Event._id),store_id:store_id, event_id:event?.map((e)=>e.event_id),category_id:category_id })
  
    if (abc) {
      closeModal();
      toast.success(abc?.data?.message);
      

      if (storetofav == "thisStore") {
        if (data._id === finalIndex) {
          const newData = { ...data, isfavourite: event };

          // Update the state with the new object
          setdata(newData);
        }
      }

      if (storetofav == "suggestedStore") {
        const index = data.suggested_vendors.findIndex((item) => item._id == finalIndex);
        console.log("index", index);
        let val = event;
        console.log("val", val);

        // If the object with the specified _id is found, update its name
        if (index !== -1) {
          const newData = [...data.suggested_vendors];
          newData[index].isfavourite = val;

          
          const updatedData = { ...data, suggested_vendors: newData };
          setdata(updatedData);
        }
      }
    }

    setload(false);

  } catch (error) {
    // Handle error
    console.error("Error occurred:", error);
  }
  };

  // const [data, setdata] = useState([]);
  const Details = async (name) => {
    try {
      console.log('name', name);
  
      setload(true);
      setdata(null);
  
      const response = await axios.post(`vendorpage/getdetailedstore`, {
        slug: name,
        user_id: profile && profile?._id,
        lang: savedLanguage
      });
  
      console.log('response2332', response);
  
      if (response.data.status==1) {
        setdata(response.data.data);
        setcheckimglength(response.data?.data?.imageName?.length);
        setload(false);
      } else {
        // Handle case where no store was found
        console.log('No Store Found');
        setready(true)
        setload(false);
        // You can set an error state or display a message here
      }
    } catch (error) {
      console.log(error);
      // Handle other errors such as network issues or server errors
    }
  };




  // const abcd = () => {
  //   var dash = "";
  //   const p = location.pathname.split("/");
  //   const p1 = p[p.length - 1];

  //   for (var i = p1.length - 1; i >= 0; i--) {
  //     if (p1[i] == "_") {
  //       console.log("trueeeeeeeee");
  //       dash = i;
  //       break;
  //     }
  //   }

  //   const t = p1
  //   .replace(/-/g, ' ').replace(/and/g, '&').replace(/’s\s*/gi, "").toLowerCase();

  //   console.log("aa", t);
  //   Details(p1);

  //   console.log("t", t);
  // };

  
  useEffect(() => {
    const p = location.pathname.split("/");
    const p1 = p[p.length - 1];
    Details(p1);
  }, []);

  const [isActive, setActive] = useState(false);
  const handleClick = (id) => {
    setActive(!isActive);
  };

  const shareCurrentPage = async () => {
    try {
      if (navigator.share) {
        await navigator.share({
          title: "Share this link",
          url: window.location.href,
        });
      } else {
        const fallbackUrl = `${
          process.env.REACT_APP_IMGURL
        }/share?url=${encodeURIComponent(window.location.href)}`;
        window.open(fallbackUrl, "_blank");
      }
    } catch (error) {
      console.error("Error sharing:", error);
    }
  };

  const shareOnFacebook = () => {
    const url = window.location.href;
    const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      url
    )}`;
    window.open(facebookShareUrl, "_blank");
  };

  const shareOnWhatsApp = () => {
    const url = window.location.href;
    const whatsappShareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
      url
    )}`;
    window.open(whatsappShareUrl, "_blank");
  };

  const reason_for_reporting = [
    {
      id: 1,
      option: "Inaccurate Information",
    },
    {
      id: 2,
      option: "Misleading Information",
    },
    {
      id: 3,
      option: "Poor Quality Service",
    },
    {
      id: 4,
      option: "Fraud Activity",
    },
    {
      id: 5,
      option: "Spam Content",
    },
    {
      id: 6,
      option: "Safety Concern",
    },
    {
      id: 7,
      option: "Violation of Terms of Service",
    },
    {
      id: 8,
      option: "Duplicate Listing",
    },
    {
      id: 9,
      option: "Out of Business",
    },
  ];
  const relationship_listing = [
    {
      id: 1,
      option: "Owner / Founder",
    },
    {
      id: 2,
      option: "Investor",
    },
    {
      id: 3,
      option: "Manager",
    },
    {
      id: 4,
      option: "Supervisor",
    },
    {
      id: 5,
      option: "Digital Team",
    },
  ];

  const pricingbyGuest = [
    {
      id: 1,
      service_name: "For Less Than 50 Guests",
      price: "15,000",
    },
    {
      id: 2,
      service_name: "For 50-100 Guests",
      price: "10,000",
    },
    {
      id: 3,
      service_name: "For 100-150 Guests",
      price: "12,000",
    },
    {
      id: 4,
      service_name: "For 150-250 Guests",
      price: "18,000",
    },
    {
      id: 5,
      service_name: "For 250-350 Guests",
      price: "18,000",
    },
    {
      id: 6,
      service_name: "For 350-500 Guests",
      price: "18,000",
    },
    {
      id: 7,
      service_name: "For 500-750 Guests",
      price: "18,000",
    },
    {
      id: 8,
      service_name: "For 750-1000 Guests",
      price: "18,000",
    },
    {
      id: 9,
      service_name: "For More Than 1000 Guests",
      price: "18,000",
    },
  ];

  const toggleFAQ = (id) => {
    if (!toggle1.includes(id)) {
      settoggle1([...toggle1, id]);
    } else {
      const updatedEventIds = toggle1.filter((itemId) => itemId !== id);
      settoggle1(updatedEventIds);
    }
  };


  const handleShotlistEventChange = (id) => {
    console.log('idddddddddddddddd', id);
    
    const existingEventIndex = event.findIndex(event => event.event_id == id);

    console.log('existingEventIndex', existingEventIndex)
    
    if (existingEventIndex === -1) {

        const newEvent = {        
            event_id: id,
        
        };
        setevent([...event, newEvent]);
    } else {
     
        const updatedEvents = event.filter(event => event.event_id !== id);
        setevent(updatedEvents);
    }
};


const handleSubmitClaim = async (e) => {
  
    e.preventDefault();
    try {

      const requestData = { user_id: profile?._id, store_id: data?._id, name: name, email: email, phone: contactNumber, relationship: relationship, website: website };
      
    
     const abc = await axios.post("/vendorpage/addclaimstore", requestData)
   
     
     if(abc){
      toast.success(abc?.data?.message)

      closeModal()
      setName("")     
      setEmail("")     
      setContactNumber("")     
      setrelationship("")     
      setWebsite("")  

     }
    
  } catch (rejectedValueOrSerializedError) {
    console.log(rejectedValueOrSerializedError);
  }
  
};

const handleSubmitReport = async (e) => {
  
    e.preventDefault();
    try {

      const requestData = { user_id: profile?._id, store_id: data?._id, reason: reason, comment: comment};
      
    
     const abc = await axios.post("/vendorpage/addreportstore", requestData)
   
     
     if(abc){
      toast.success(abc?.data?.message)

      closeModal()
      setReason("")   
      setComment("")   
     }
    
  } catch (rejectedValueOrSerializedError) {
    console.log(rejectedValueOrSerializedError);
  }
  
};

  

  console.log("data", data,load,ready);



  const addProtocol = (url) => {
    if (!url.startsWith('http://') && !url.startsWith('https://')) {
      return `https://${url}`;
    }
    return url;
  };

  
  return (
  
    <>
    
      {myEvents?.length < 1 && modalType == "favourite" ? (
        toast.error("You don't have any event to shortlist this store")
      ) : (
        <Modal
          closeTimeoutMS={500}
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Add Task"
          className="modal fade show wf-modal vendor-detail"
        >
          <div class="modal-dialog wf-modal-dialog modal-dialog-centered">
            {modalType == "favourite" ? (
              <div class="modal-content">
              <div class="modal-header border-0 pb-0">
                <h1 class="font-size-26 dm-font purple-color" id="staticBackdropLabel" >{t('SelectAnEvent')}</h1> 
                <button type="button" onClick={closeModal} class="btn-close"></button>
              </div>
              <div class="modal-body">
                <p class="font-size-16 color-black" id="" >{t('Pleaseselecttheeventsforwhichyouwanttoshortlistthisstore')}</p>
                <div className="row">
                  <div className="d-flex flex-wrap gap-2 mb-3">
                  {myEvents?.length>0&&myEvents?.map((e)=>(<>
                  <input onChange={()=>handleShotlistEventChange(e.Event._id)} checked={event?.some((ev) => ev.event_id === e.Event._id)}  type="checkbox" class="btn-check" name="event-select" id={e.Event._id} autocomplete="off"/>
                  <label style={{"--bs-btn-active-bg":`${e.Event.event_color}`}} className="btn wf-select-btn" for={e.Event._id}>{e.Event.event_name}</label>
                  </>))}
                  </div>
                </div> 
              </div>
              <div class="modal-footer border-0 justify-content-center">
                <button class="btn wf-btn-2 wf-pink-btn py-2 px-4 rounded-2 wf-shadow-2" onClick={()=>handlePin()}>{t('Add')}</button>
              </div>
            </div>
            ) : modalType == "claim" ? (
              <div className="modal-content" id="claim-this-listing">
      <div className="modal-header border-0">
        <h1 className="font-size-26 dm-font purple-color" id="staticBackdropLabel">
          Claim This Listing
        </h1>
        <button type="button" onClick={closeModal} className="btn-close"></button>
      </div>
      <div className="modal-body">
        <div className="row gy-3">
          <div className="col-md-6">
            <div className="form-floating wf-input wf-input-101">
              <input
                type="text"
                className="form-control"
                id="Name"
                placeholder="name"
                autoComplete="name"
                required
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <label htmlFor="Name">Name</label>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-floating wf-input wf-input-101 ">
              <input
                type="email"
                className="form-control"
                id="email"
                placeholder="email"
                autoComplete="email"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <label htmlFor="email">Email</label>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-floating wf-input wf-input-101 ">
              <input
                type="tel"
                className="form-control"
                id="Contact-Number"
                placeholder="Contact Number"
                autoComplete="phone"
                required
                value={contactNumber}
                onChange={(e) => setContactNumber(e.target.value)}
              />
              <label htmlFor="Contact-Number">Contact Number</label>
            </div>
          </div>
          <div className="col-md-6">
            <div className="dropdown country-city-dropdown">
              <a
                className="btn dropdown-toggle"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {relationship?relationship:"Relationship to this listing"}
              </a>
              <ul className="dropdown-menu">
                <li className="country cursor-pointer mt-0 mb-0">
                  <a>
                    <span>Relationship to this listing</span>
                  </a>
                </li>
                {relationship_listing.map((data) => (
                  <li
                    key={data.id}
                    className="country cursor-pointer mt-0 mb-0"
                  >
                    <a onClick={()=>setrelationship(data.option)}>
                      <span>{data.option}</span>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-floating wf-input wf-input-101">
              <input
                type="url"
                className="form-control"
                id="Website"
                placeholder="Website"
                autoComplete="website"
                required
                value={website}
                onChange={(e) => setWebsite(e.target.value)}
              />
              <label htmlFor="Website" className="text-uppercase">
                Business Website
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-footer border-0 justify-content-center">
        <button
          className="btn wf-btn-2 wf-pink-btn py-2 px-4 rounded-2 wf-shadow-2"
          onClick={handleSubmitClaim}
        >
          Send
        </button>
      </div>
    </div>
            ) : modalType == "report" ? (
              <div class="modal-content" id="report-this-listing">
                <div class="modal-header border-0">
                  <h1
                    class="font-size-26 dm-font purple-color"
                    id="staticBackdropLabel"
                  >
                    Report This Listing
                  </h1>
                  <button
                    type="button"
                    onClick={closeModal}
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div class="modal-body">
                  <div class="dropdown mb-3 country-city-dropdown">
                    <a
                      className="btn dropdown-toggle"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {reason?reason:"Reason for reporting"}
                    </a>
                    <ul className="dropdown-menu">
                      <li className="country cursor-pointer mt-0 mb-0">
                        <a>
                          <span>Reason for reporting</span>
                        </a>
                      </li>
                      {reason_for_reporting.map((data) => (
                        <li
                          key={data.id}
                          className="country cursor-pointer mt-0 mb-0"
                        >
                          <a onClick={()=>setReason(data.option)}>
                            <span>{data.option}</span>
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div class="form-floating wf-textarea wf-textarea-101">
                    <textarea
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                      class="form-control"
                      placeholder="Comment"
                      id="Comment"
                      style={{ height: "150px" }}
                    ></textarea>
                    <label for="Comment" className="">
                      Comment
                    </label>
                  </div>
                </div>
                <div class="modal-footer border-0 justify-content-center">
                  <button
                    class="btn wf-btn-2 wf-pink-btn py-2 px-4 rounded-2 wf-shadow-2"
                    onClick={handleSubmitReport}
                  >
                    Send
                  </button>
                </div>
              </div>
            ) : null}
          </div>
        </Modal>
      )}

      <Header theme="dark" />

      {!ready && load && data === null ? (
  <VendorDetailSkeleton />
) : ready && data === null ? (
  <Page404 />
) : (
        <>
          <section className="vendor-storefront-1 pb-1 pt-3">
            <div className="container">
              <div class="border-top  mb-3 mt-3"></div>
              <div className="d-md-flex align-items-center justify-content-between mb-2">
                <h1 className="font-size-46 playfair-font color-black mb-3">
                  {data?.store_name}
                </h1>
                <div className="d-flex align-items-center gap-3 flex-wrap">
                  {parseInt(data?.discount) > 0 && (
                    <span className="font-size-14 rounded-1 wf-pink-btn py-2 px-3 text-uppercase">
                      {data?.country[0]?.currency} {data?.discount}% {t('Discount')}
                    </span>
                  )}
                  {/* <span className="font-size-14 rounded-1 black-bg color-white py-2 px-3 text-uppercase">Top Pick</span> */}
                  {data?.isfeatured == 1 && (
                    <span className="font-size-14 rounded-1 green-bg color-white py-2 px-3 text-uppercase">
                      {t('FeaturedVendor')}
                    </span>
                  )}
                </div>
              </div>
              <div className="row gy-3">
                <div className="col-lg-9 col-md-12 d-md-flex align-items-center gap-3">
                  <p className="color-black font-size-18 mb-0">
                    <i class="bi bi-geo-alt-fill"></i>&nbsp;
                    {data?.cities?.length > 0 &&
                      data?.cities?.map((c, i) => (
                        <span>
                          {c?.name}
                          {i < data?.cities?.length && ", "}
                        </span>
                      ))}{" "}
                    <span>
                      {data?.country?.length > 0 && data?.country[0]?.name}
                    </span>
                  </p>
                  {data?.prefered_services?.length > 0 && (
                    <span className="font-size-12 rounded-1 wf-purple-btn py-1 px-3 text-uppercase cursor-pointer">
                      {data?.prefered_services?.map((p, i) => (
                        <a
                          style={{ color: "var(--color1)" }}
                          href={`${
                            process.env.REACT_APP_FrontURL
                          }/suppliers/${p?.name
                            .replace(/\s/g, "-")
                            .replace(/&/g, "and")
                            .toLowerCase()}`}
                        >
                          {p?.name}{" "}
                          {i < data?.prefered_services?.length - 1 && ", "}
                        </a>
                      ))}
                    </span>
                  )}
                  {data?.prefered_typeofservices?.length > 0 && (
                    <span className="font-size-12 rounded-1 wf-pink-btn py-1 px-3 text-uppercase cursor-pointer">
                      {data?.prefered_typeofservices?.map((p, i) => (
                        <a
                          style={{ color: "var(--color1)" }}
                          href={`${
                            process.env.REACT_APP_FrontURL
                          }/suppliers/${p?.name
                            .replace(/\s/g, "-")
                            .replace(/&/g, "and")
                            .toLowerCase()}`}
                        >
                          {p?.name}{" "}
                          {i < data?.prefered_typeofservices?.length - 1 &&
                            ", "}
                        </a>
                      ))}
                    </span>
                  )}
                </div>
                {data?.reviews[0]?.reviews?.length > 0 && (
                  <div className="col-lg-3 col-md-12 d-flex align-items-center justify-content-md-end">
                    <p className="font-size-16 color-black mb-0">
                      <i class="bi bi-star-fill pink-color"></i>{" "}
                      <span className="fw-medium">
                        {data?.reviews[0] && data?.reviews[0]?.overallAverage}
                      </span>{" "}
                      - {data?.reviews[0]?.reviews?.length} {t('Reviews')}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </section>
          {data?.imageName?.length > 0 && (
            <section className="vendor-storefront-2 py-3">
              <div className="container">
                <div className="storefront-gallery position-relative">
                  {data?.imageName?.length > 0 && (
                    <div className="row gy-4 gx-3">
                      <div className="col-md-5">
                        <div className="gallery-card">
                          <img
                            src={`${process.env.REACT_APP_IMGURL}${data?.imageName[0]}`}
                            className="gallery-img big"
                          />
                        </div>
                      </div>
                      <div className="col-md-7 d-md-block d-none">
                        <div className="row mb-4 gy-4 gx-3">
                          {data?.imageName?.length > 1 && (
                            <div className="col-md-6">
                              <div className="gallery-card">
                                <img
                                  src={`${process.env.REACT_APP_IMGURL}${data?.imageName[1]}`}
                                  className="gallery-img"
                                />
                              </div>
                            </div>
                          )}
                          {data?.imageName?.length > 2 && (
                            <div className="col-md-6">
                              <div className="gallery-card">
                                <img
                                  src={`${process.env.REACT_APP_IMGURL}${data?.imageName[2]}`}
                                  className="gallery-img"
                                />
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="row gy-4">
                          {data?.imageName?.length > 3 && (
                            <div className="col-md-6">
                              <div className="gallery-card">
                                <img
                                  src={`${process.env.REACT_APP_IMGURL}${data?.imageName[3]}`}
                                  className="gallery-img"
                                />
                              </div>
                            </div>
                          )}
                          {data?.imageName?.length > 4 &&
                            checkimglength > 3 && (
                              <div className="col-md-6">
                                <div className="gallery-card">
                                  <img
                                    src={`${process.env.REACT_APP_IMGURL}${data?.imageName[4]}`}
                                    className="gallery-img"
                                  />
                                </div>
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                  )}

                  <a
                    className="see-all-photos-btn py-2 font-size-18"
                    onClick={() => setisOpen(true)}
                  >
                    <i class="bi bi-image"></i> {t('Seeallphotos')}
                  </a>
                </div>
                <div>
                  {isOpen && (
                    <Lightbox
                      mainSrc={`${process.env.REACT_APP_IMGURL}${data?.imageName[photoIndex]}`}
                      nextSrc={
                        data?.imageName[
                          (photoIndex + 1) % data?.imageName?.length
                        ]
                      }
                      prevSrc={
                        data?.imageName[
                          (photoIndex + data?.imageName?.length - 1) %
                            data?.imageName?.length
                        ]
                      }
                      onCloseRequest={() => setisOpen(false)}
                      onMovePrevRequest={() =>
                        setphotoIndex(
                          (photoIndex + data?.imageName?.length - 1) %
                            data?.imageName?.length
                        )
                      }
                      onMoveNextRequest={() =>
                        setphotoIndex(
                          (photoIndex + 1) % data?.imageName?.length
                        )
                      }
                    />
                  )}
                </div>
              </div>
            </section>
          )}
          <section className="vendor-storefront-3 py-3">
            <div className="container">
              <div className="d-flex justify-content-between row-gap-3 flex-md-row flex-lg-nowrap flex-md-wrap flex-column">
                <div className="d-flex align-items-center gap-2">
                  <i class="bi bi-cash font-size-26 purple-color"></i>
                  <div>
                    <p className="font-size-14 color-black mb-0 fw-bold">
                      {t('PriceRange')}
                    </p>
                    {data?.max_price && data?.min_price ? (
                      <p className="font-size-16 color-black mb-0">
                        {data?.country[0]?.currency} {data?.min_price} -{" "}
                        {data?.country[0]?.currency} {data?.max_price}
                      </p>
                    ) : (
                      <p className="font-size-16 color-black mb-0">
                        {t('NotApplicable')}
                      </p>
                    )}
                  </div>
                </div>
                <div className="d-flex align-items-center gap-2">
                  <i class="bi bi-briefcase font-size-26 purple-color"></i>
                  <div>
                    <p className="font-size-14 color-black mb-0 fw-bold">
                      {t('OperatingSince')}
                    </p>
                    <p className="font-size-16 color-black mb-0">
                      {data?.business_startdate
                        ? moment(data?.business_startdate).format("DD-MM-YYYY")
                        : "Not Available"}
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-center gap-2">
                  <i class="bi bi-gem font-size-26 purple-color"></i>
                  <div>
                    <p className="font-size-14 color-black mb-0 fw-bold">
                      {t('ExperienceinWedding')}
                    </p>
                    <p className="font-size-16 color-black mb-0">
                      {data?.weddings_hosted ? (
                        `${data?.weddings_hosted} Weddings`
                      ) : (
                        <>0 Weddings</>
                      )}
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-center gap-2">
                  <i class="bi bi-people font-size-26 purple-color"></i>
                  <div>
                    <p className="font-size-14 color-black mb-0 fw-bold">
                      {t('TeamSize')}
                    </p>
                    <p className="font-size-16 color-black mb-0">
                      {data?.team_members ? (
                        `${data?.team_members} Members`
                      ) : (
                        <>0 Members</>
                      )}
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-center gap-2">
                  <i class="bi bi-shield-check font-size-26 purple-color"></i>
                  <div>
                    <p className="font-size-14 color-black mb-0 fw-bold">
                      {t('Insurance')}
                    </p>
                    <p className="font-size-16 color-black mb-0">
                      {data?.insurance == "yes"
                        ? "Yes"
                        : "No"}
                    </p>
                  </div>
                </div>
                <div className="d-flex justify-content-md-end gap-2 align-self-md-center">
                  <div className="options-vendor-storefront">
                    {profile && profile?.role == "customer" && (
                      <div
                        className="favourite-option"
                        onClick={() =>
                          data?.isfavourite
                            && viewModal(
                                data?._id,
                                data?.prefered_services[0]?._id,
                                "favourite",
                                "thisStore",
                                data?.isfavourite
                              )
                        }
                      >
                        {data?.isfavourite?.length>0 ? (
                          <Heart fill="#804099" stroke="#804099" />
                        ) : (
                          <Heart />
                        )}
                      </div>
                    )}
                  </div>
                  <div className="options-vendor-storefront">
                    <div onClick={shareCurrentPage} className="share-option">
                      <i class="fas fa-share-alt"></i>
                    </div>
                  </div>
                  <div className="options-vendor-storefront">
                    <div onClick={shareOnFacebook} className="share-option">
                      <i class="bi bi-facebook"></i>
                    </div>
                  </div>
                  <div className="options-vendor-storefront">
                    <div onClick={shareOnWhatsApp} className="share-option">
                      <i class="bi bi-whatsapp"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="vendor-storefront-4 pt-3">
            <div className="container">
              <div className="row">
                <div className="col-lg-8 col-md-12">
                  <div id="about-content">
                    <div class="border-top  mb-3 mt-0"></div>
                    {data?.overview ? (
                      <p className="font-size-16 color-black breif-description">
                        {data?.overview}
                      </p>
                    ) : ""}
                    {data?.description ? (
                      <p className="font-size-14 color-black detailed-description">
                        {data?.description}
                      </p>
                    ) : ""}
                  </div>
                  {data?.sub_services?.length > 0 && (
                    <div id="what-place-offers">
                      <div class="border-top mb-4 mt-5"></div>
                      <h3 className="font-size-36 dm-font color-black mb-3">
                        {t('WhatThisPlaceOffers')}
                      </h3>
                      <div className="row gy-3">
                        {data?.sub_services?.length > 0 &&
                          data?.sub_services?.map((d) => (
                            <div key={d._id} className="col-md-6">
                              <h4 className="font-size-20 color-black mb-2">
                                {d?._id?.question}
                              </h4>
                              <div className="d-flex align-items-center gap-4">
                                {d?._id?.options?.map(
                                  (answer, index) =>
                                    d?.item?.includes(answer?._id) && (
                                      <div
                                        className="d-flex align-items-center gap-2 flex-wrap"
                                        key={answer?._id}
                                      >
                                        <img
                                          width="16"
                                          src={`${process.env.REACT_APP_IMGURL}${answer?.imageName}`}
                                          alt={`Answer ${index + 1}`}
                                          className="place-offer-img"
                                        />
                                        <p className="font-size-16 color-black mb-0">
                                          {answer?.name}
                                        </p>
                                      </div>
                                    )
                                )}
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  )}

                  {data?.attributes?.length > 0 && (
                    <div id="attributes">
                      <div class="border-top  mb-4 mt-5"></div>
                      <h3 className="font-size-36 dm-font color-black mb-3">
                        {t('Attributes')}
                      </h3>
                      <div className="row gy-3">
                        {data?.attributes.map((data) => (
                          <div
                            key={data.id}
                            className="col-md-4 d-flex align-items-center gap-2"
                          >
                            <img
                              width="26"
                              src={`${process.env.REACT_APP_IMGURL}${data.imageName}`}
                              alt={data.imageName}
                              className="place-offer-img"
                            />
                            <p className="font-size-16 color-black mb-0">
                              {data.name}
                            </p>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}

                  {data?.prefered_services &&
                    data?.prefered_services[0]?.pricing_type == "deal" &&
                    data?.store_deal?.data?.length > 0 && (
                      <div id="pricing-details">
                        <div class="border-top  mb-4 mt-5"></div>
                        <h3 className="font-size-36 playfair-font color-black mb-3">
                          {t('Pricing&Details')}
                        </h3>

                        {data?.prefered_services &&
                          data?.prefered_services[0]?.pricing_type == "deal" &&
                          data?.store_deal?.data?.length > 0 && (
                            <div className="wf-shadow white-bg rounded p-3 mb-3">
                              <div className="row gy-3">
                                {data?.store_deal?.data?.map((d) => (
                                  <div className="col-md-12 d-flex align-items-center justify-content-between ">
                                    <p className="font-size-14 pink-color mb-0">
                                      {d?.service_name}
                                    </p>
                                    <p className="font-size-14 purple-color mb-0">
                                      {data?.country[0]?.currency} {d.price}
                                    </p>
                                  </div>
                                ))}
                              </div>
                            </div>
                          )}
                        {data?.prefered_services[0]?.pricing_type ==
                          "pricing" && (
                          <div className="wf-shadow white-bg rounded p-3">
                            <div className="row gy-3">
                              {pricingbyGuest?.map((d) => (
                                <div
                                  className="col-md-6 d-flex align-items-center justify-content-between"
                                  key={d.id}
                                >
                                  <p className="font-size-14 pink-color mb-0">
                                    {d.service_name}
                                  </p>
                                  <p className="font-size-14 purple-color mb-0">
                                    {data?.country[0]?.currency}{" "}
                                    {d.id === 1 &&
                                      data?.store_pricing?.lessthan50}
                                    {d.id === 2 &&
                                      data?.store_pricing?.from50to100}
                                    {d.id === 3 &&
                                      data?.store_pricing?.from100to150}
                                    {d.id === 4 &&
                                      data?.store_pricing?.from150to250}
                                    {d.id === 5 &&
                                      data?.store_pricing?.from250to350}
                                    {d.id === 6 &&
                                      data?.store_pricing?.from350to500}
                                    {d.id === 7 &&
                                      data?.store_pricing?.from500to750}
                                    {d.id === 8 &&
                                      data?.store_pricing?.from750to1000}
                                    {d.id === 9 &&
                                      data?.store_pricing?.morethan1000}
                                  </p>
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  {data?.faqs?.data?.length > 0 && (
                    <div id="faqs">
                      <div class="border-top  mb-4 mt-5"></div>
                      <h3 className="font-size-36 playfair-font color-black mb-3">
                        {t('FAQs')}
                      </h3>
                      <div className="row gy-3 px-3">
                        {data?.faqs?.data?.map((faqItem, i) => (
                          <div
                            key={faqItem.id}
                            className="wf-shadow white-bg rounded p-4 faq-list faq-list-2"
                          >
                            <div
                              onClick={() => toggleFAQ(i)}
                              className="d-flex align-items-center justify-content-between cursor-pointer"
                            >
                              <p className="font-size-16 color-black mb-0">
                                {faqItem.question}
                              </p>
                              <div className="arrow-toggle pink-color">
                                {toggle1.includes(i) ? (
                                  <i className="fas fa-chevron-up"></i>
                                ) : (
                                  <i className="fas fa-chevron-down"></i>
                                )}
                              </div>
                            </div>
                            <div
                              className={`answer ${
                                toggle1.includes(i) && "show"
                              }`}
                            >
                              <p
                                className="font-size-14 mb-0"
                                style={{ color: "#5D5D5D" }}
                              >
                                {faqItem.answer}
                              </p>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                  {data?.video?.length > 0 && (
                    <div id="video">
                      <div class="border-top  mb-5 mt-5"></div>
                      <h3 className="font-size-36 playfair-font color-black mb-3">
                        {t('Video')}
                      </h3>
                      <div id="carouselExample" class="carousel slide">
                        <div class="carousel-inner">
                          <div class="carousel-item active">
                            <div class="o-video">
                              <iframe
                                src={`https://www.youtube.com/embed/${
                                  data?.video[videoIndex]?.split("v=")[1]
                                }`}
                                allowfullscreen
                                width="100%"
                                height={400}
                              ></iframe>
                            </div>
                          </div>
                        </div>
                        <button
                          class="carousel-control-prev"
                          type="button"
                          data-bs-target="#carouselExample"
                          data-bs-slide="prev"
                          onClick={() => {
                            if (videoIndex > 0) {
                              setvideoIndex(videoIndex - 1);
                            }
                          }}
                        >
                          <span
                            class="carousel-control-prev-icon"
                            aria-hidden="true"
                          ></span>
                          <span class="visually-hidden">Previous</span>
                        </button>
                        <button
                          class="carousel-control-next"
                          type="button"
                          data-bs-target="#carouselExample"
                          data-bs-slide="next"
                          onClick={() => setvideoIndex(videoIndex + 1)}
                        >
                          <span
                            class="carousel-control-next-icon"
                            aria-hidden="true"
                          ></span>
                          <span class="visually-hidden">Next</span>
                        </button>
                      </div>
                    </div>
                  )}

                  {data?.location?.location ? (
                    <div id="location">
                      <div class="border-top  mb-3 mt-5"></div>
                      <h3 className="font-size-36 playfair-font color-black mb-3">
                        {t('Location')}
                      </h3>
                      <div>
                        {initialPosition?.length == 0 ? null : (
                          <MapContainer
                            center={initialPosition}
                            zoom={zoomLevel}
                            style={{
                              height: "400px",
                              width: "100%",
                              zIndex: "0",
                            }}
                          >
                            <TileLayer url="https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png" />
                            {markers.map((marker, index) => {
                              console.log("marker", markers, initialPosition);
                              return (
                                <Marker
                                  key={index}
                                  position={[marker.lat, marker.lng]}
                                  icon={mapIcon}
                                >
                                  <Popup>{marker.name}</Popup>
                                </Marker>
                              );
                            })}
                          </MapContainer>
                        )}
                      </div>

                      <div className="row mt-4">
                        <div className="col-md-7 d-flex gap-2">
                          <i class="bi bi-geo-alt-fill font-size-22 color-black d-flex"></i>
                          <div>
                            <h4 className="font-size-16 color-black fw-semibold mb-0">
                              {t('Address')}
                            </h4>
                            <p className="font-size-14 color-black">
                              {data?.location?.location}
                            </p>
                          </div>
                        </div>
                        <div className="col-md-3 d-flex gap-2">
                          <i class="bi bi-globe-americas font-size-22 color-black d-flex"></i>
                          <div>
                            <h4 className="font-size-16 color-black fw-semibold mb-0">
                              {t('Country')}
                            </h4>
                            <p className="font-size-14 color-black">
                              {data?.country[0]?.name}
                            </p>
                          </div>
                        </div>
                        {data?.pobox_no && 
                        <div className="col-md-2 d-flex gap-2">
                          <i class="bi bi-mailbox font-size-22 color-black d-flex"></i>
                          <div>
                            <h4 className="font-size-16 color-black fw-semibold mb-0">
                              {t('Postcode')}
                            </h4>
                            <p className="font-size-14 color-black">
                              {data?.pobox_no}
                            </p>
                          </div>
                        </div>
                        }
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className="col-lg-4 col-md-12">
                  <div className="vendor-storefront-sidebar">
                    {profile&&profile?.role!=="vendor"&&(<div className="vendor-enquire-box wf-shadow border px-3 py-3 rounded-3 mt-3">
                      <h3 className="font-size-26 playfair-font purple-color text-center mb-3">
                        {t('SendAMessageToThisVendor')}
                      </h3>
                      <textarea
                        value={message}
                        onChange={(e) => setmessage(e.target.value)}
                        className="enquire-box-text mb-3"
                        cols="20"
                        rows="7"
                        placeholder="Type your Message.."
                      ></textarea>
                      <div className="text-end">
                        {/* <p className="font-size-12 pink-color mb-2">0/300 words</p> */}
                        <button
                          onClick={sendMessage}
                          class="btn btn-lg wf-purple-btn wf-btn fw-bold text-uppercase py-2"
                        >
                          {t('SendMessage')}
                        </button>
                      </div>
                    </div>)}
                    <div className="d-flex justify-content-between px-3 mt-3 mb-2">
                      <p
                        onClick={() => {profile?viewModal("", "", "claim"):navigate("/login",{state: location.pathname})}}
                        className="font-size-14 text-center purple-color cursor-pointer"
                      >
                        <u>{t('ClaimthisListing')}</u>
                      </p>
                      <p
                        onClick={() =>{profile?viewModal("", "", "report"):navigate("/login",{state: location.pathname})}}
                        className="font-size-14 text-center purple-color cursor-pointer"
                      >
                        <u>{t('ReportThisListing')}</u>
                      </p>
                    </div>
                      {/* {data?.contact_person !== "" ||
                      data?.email !== "" ||
                      data?.phone !== "" ||
                      data?.website !== "" ||
                      data?.whatsapp !== "" ||
                      data?.facebook !== "" ||
                      data?.twitter !== "" ||
                      data?.linkedin !== "" ||
                      data?.instagram !== "" ||
                      data?.youtube !== "" ||
                      data?.pinterest !== "" ||
                      (data?.mobile !== "" && ( */}
                        <div className="wf-shadow border px-3 py-3 rounded-3">
                          <div id="contact-details">
                            <h3 className="font-size-26 playfair-font pink-color mb-3">
                              {t('ContactDetails')}
                            </h3>
                            {data?.contact_person && (
                              <p className="font-size-16 color-black mb-0 ">
                                <i class="bi bi-person pink-color font-size-18"></i>
                                &nbsp;&nbsp; {t('ContactPerson')}:{" "}
                                {data?.contact_person}
                              </p>
                            )}
                            {data?.email && (
                              <p className="font-size-16 color-black mb-0 ">
                                <i class="bi bi-at pink-color font-size-18"></i>
                                &nbsp;&nbsp; {t('Email')}:{" "}
                                <a
                                  className="pink-color fw-medium"
                                  href={`mailto:${data?.email}`}
                                >
                                  <u>{data?.email}</u>
                                </a>
                              </p>
                            )}
                            {data?.phone && (
                              <p className="font-size-16 color-black mb-0 ">
                                <i class="bi bi-telephone pink-color font-size-18"></i>
                                &nbsp;&nbsp; {t('Phone')}:{" "}
                                <a
                                  className="pink-color fw-medium"
                                  onClick={() =>
                                    IncrementView(data._id, "phone")
                                  }
                                  href={`tel:${data?.phone}`}
                                >
                                  <u>{t('ClickHere')}</u>
                                </a>
                              </p>
                            )}
                            {data?.website && (
                              <p className="font-size-16 color-black mb-0 ">
                                <i class="bi bi-globe2 pink-color font-size-18"></i>
                                &nbsp;&nbsp; {t('Website')}:{" "}
                                <a
                                  className="pink-color fw-medium"
                                  onClick={() =>
                                    IncrementView(data._id, "website")
                                  }
                                  href={data?.website}
                                  rel="noopener noreferrer"
                                >
                                  <u>{t('ClickHere')}</u>
                                </a>
                              </p>
                            )}
                            {data?.whatsapp && (
                              <p className="font-size-16 color-black mb-0 ">
                                <i class="bi bi-whatsapp pink-color font-size-18"></i>
                                &nbsp;&nbsp; {t('Whatsapp')}:{" "}
                                <a
                                  className="pink-color fw-medium"
                                  href={`https://wa.me/${data?.whatsapp}`}
                                  rel="noopener noreferrer"
                                >
                                  <u>{t('ClickHere')}</u>
                                </a>
                              </p>
                            )}
                          </div>
                          {(data?.facebook ||
                            data?.twitter ||
                            data?.linkedin ||
                            data?.instagram ||
                            data?.youtube ||
                            data?.pinterest) && (
                            <div id="social-network">
                              <h3 className="font-size-26 playfair-font purple-color mt-4 mb-3">
                                {t('SocialNetwork')}
                              </h3>
                              <div className="d-flex gap-3 align-items-center">
                                {data?.facebook && (
                                  <>
                                    <a href={addProtocol(data?.facebook)}>
                                      <i className="bi bi-facebook purple-color font-size-22"></i>
                                    </a>
                                  </>
                                )}
                                {data?.twitter && (
                                  <>
                                    <a href={addProtocol(data?.twitter)}>
                                      <i className="bi bi-twitter-x purple-color font-size-22"></i>
                                    </a>
                                  </>
                                )}
                                {data?.linkedin && (
                                  <>
                                    <a href={addProtocol(data?.linkedin)}>
                                      <i className="bi bi-linkedin purple-color font-size-22"></i>
                                    </a>
                                  </>
                                )}
                                {data?.instagram && (
                                  <>
                                    <a href={addProtocol(data?.instagram)}>
                                      <i className="bi bi-instagram purple-color font-size-22"></i>
                                    </a>
                                  </>
                                )}
                                {data?.youtube && (
                                  <>
                                    <a href={addProtocol(data?.youtube)}>
                                      <i className="bi bi-youtube purple-color font-size-22"></i>
                                    </a>
                                  </>
                                )}
                                {data?.pinterest && (
                                  <>
                                    <a href={addProtocol(data?.pinterest)}>
                                      <i className="bi bi-pinterest purple-color font-size-22"></i>
                                    </a>
                                  </>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                       {/* ))}  */}
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* </>)} */}
          {data?.reviews[0]?.reviews?.length > 0 && (
            <section className="vendor-storefront-5 py-3" id="reviews">
              <div className="container">
                <div class="border-top  mb-4 mt-5"></div>
                <div className="d-flex align-items-center gap-3 mb-4">
                  <h3 className="font-size-36 playfair-font color-black">
                    {t('Reviews')} ({data?.reviews[0]?.reviews?.length})
                  </h3>
                  <p className="font-size-16 color-black mb-0">
                    <i class="bi bi-star-fill pink-color"></i>{" "}
                    <span className="fw-medium">
                      {data?.reviews[0]?.overallAverage}
                    </span>{" "}
                    - {data?.reviews[0]?.reviews?.length}{" "}
                    {data?.reviews[0]?.reviews?.length > 1
                      ? "Reviews"
                      : "Review"}
                  </p>
                </div>
                <div className="row gy-3 mb-5">
                  <div className="col-md-4 d-flex align-items-center gap-3">
                    <p className="font-size-14 color-black fw-medium mb-0">
                      {t('Quality')}
                    </p>
                    <div
                      class="progress"
                      style={{ height: "12px", width: "100%" }}
                    >
                      <div
                        class="progress-bar"
                        role="progressbar"
                        aria-label="Example 20px high"
                        style={{
                          width: `${
                            (data?.reviews[0]?.qualityAverage / 5) * 100
                          }%`,
                          backgroundColor: "#EE257C",
                        }}
                        aria-valuenow={data?.reviews[0]?.qualityAverage * 20}
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                    <p className="font-size-14 color-black fw-medium mb-0">
                      {data?.reviews[0]?.qualityAverage}
                    </p>
                  </div>
                  <div className="col-md-4 d-flex align-items-center gap-3">
                    <p className="font-size-14 color-black fw-medium mb-0">
                      {t('Responsive')}
                    </p>
                    <div
                      class="progress"
                      style={{ height: "12px", width: "100%" }}
                    >
                      <div
                        class="progress-bar"
                        role="progressbar"
                        aria-label="Example 20px high"
                        style={{
                          width: `${
                            (data?.reviews[0]?.responsiveAverage / 5) * 100
                          }%`,
                          backgroundColor: "#EE257C",
                        }}
                        aria-valuenow={data?.reviews[0]?.responsiveAverage * 20}
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                    <p className="font-size-14 color-black fw-medium mb-0">
                      {data?.reviews[0]?.responsiveAverage}
                    </p>
                  </div>
                  <div className="col-md-4 d-flex align-items-center gap-3">
                    <p className="font-size-14 color-black fw-medium mb-0">
                      {t('Flexibility')}
                    </p>
                    <div
                      class="progress"
                      style={{ height: "12px", width: "100%" }}
                    >
                      <div
                        class="progress-bar"
                        role="progressbar"
                        aria-label="Example 20px high"
                        style={{
                          width: `${
                            (data?.reviews[0]?.flexibilityAverage / 5) * 100
                          }%`,
                          backgroundColor: "#EE257C",
                        }}
                        aria-valuenow={
                          data?.reviews[0]?.flexibilityAverage * 20
                        }
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                    <p className="font-size-14 color-black fw-medium mb-0">
                      {data?.reviews[0]?.flexibilityAverage}
                    </p>
                  </div>
                  <div className="col-md-4 d-flex align-items-center gap-3">
                    <p className="font-size-14 color-black fw-medium mb-0 w-50">
                      {t('ValueforMoney')}
                    </p>
                    <div
                      class="progress"
                      style={{ height: "12px", width: "100%" }}
                    >
                      <div
                        class="progress-bar"
                        role="progressbar"
                        aria-label="Example 20px high"
                        style={{
                          width: `${
                            (data?.reviews[0]?.valueForMoneyAverage / 5) * 100
                          }%`,
                          backgroundColor: "#EE257C",
                        }}
                        aria-valuenow={
                          data?.reviews[0]?.valueForMoneyAverage * 20
                        }
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                    <p className="font-size-14 color-black fw-medium mb-0">
                      {data?.reviews[0]?.valueForMoneyAverage}
                    </p>
                  </div>
                  <div className="col-md-4 d-flex align-items-center gap-3">
                    <p className="font-size-14 color-black fw-medium mb-0">
                      {t('Professionalism')}
                    </p>
                    <div
                      class="progress"
                      style={{ height: "12px", width: "100%" }}
                    >
                      <div
                        class="progress-bar"
                        role="progressbar"
                        aria-label="Example 20px high"
                        style={{
                          width: `${
                            (data?.reviews[0]?.professionalismAverage / 5) * 100
                          }%`,
                          backgroundColor: "#EE257C",
                        }}
                        aria-valuenow={
                          data?.reviews[0]?.professionalismAverage * 20
                        }
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                    <p className="font-size-14 color-black fw-medium mb-0">
                      {data?.reviews[0]?.professionalismAverage}
                    </p>
                  </div>
                </div>
                <div className="row gy-4">
                  {data?.reviews[0]?.reviews?.map((data, i) => {
                    return (
                      <div className="col-md-4">
                        <div className="d-flex align-items-center justify-content-between mb-3">
                          <div className="d-flex align-items-center gap-3">
                          <div className="" style={{ width: "200px" }}>
                                <div className="wf-up-avatar d-flex align-items-center mb-0">
                                  <div className="avatar-couple-first">
                                    <img
                                      width="57"
                                      height="57"
                                      alt=""
                                      src={data?.couple?.person1?.imageName ? `${process.env.REACT_APP_IMGURL}${data?.couple?.person1?.imageName}` : "https://via.placeholder.com/50x50"}
                                      className="rounded-circle avatar"
                                      style={{ border: "3px solid #804099", objectFit: "cover" }}
                                    />
                                  </div>
                                  <div className="avatar-couple-sec me-2" style={{ marginLeft: "-30px" }}>
                                    <img
                                      width="57"
                                      height="57"
                                      alt=""
                                      src={data?.couple?.person2?.imageName ? `${process.env.REACT_APP_IMGURL}${data?.couple?.person2?.imageName}` : "https://via.placeholder.com/50x50"}
                                      className="rounded-circle avatar"
                                      style={{ border: "3px solid #EE0E7B", objectFit: "cover" }}
                                    />
                                  </div>
                                  <p className="font-size-16 pink-color mb-0 fw-semibold">
                                    {data?.couple?.person1?.name || "partner"} &{" "}
                                    {data?.couple?.person2?.name || "partner"}
                                  </p>
                                </div>
                              </div>
                            <div>
                              {/* <p className="font-size-16 fw-medium color-black mb-0">
                                {!data.user_id.name
                                  ? `${
                                      data?.name?.charAt(0)?.toUpperCase() +
                                      data?.name?.slice(1)
                                    }`
                                  : `${
                                      data.user_id.name
                                        .charAt(0)
                                        .toUpperCase() +
                                      data.user_id.name.slice(1)
                                    }`}
                              </p> */}
                              <p className="font-size-12 color-black mb-0">
                                {moment(data?.createdAt).format("DD MMM YYYY")}
                              </p>
                            </div>
                          </div>
                          <p className="font-size-16 color-black mb-0">
                            <i class="bi bi-star-fill pink-color"></i>{" "}
                            <span className="fw-medium">{data?.average}</span>
                          </p>
                        </div>
                        {/* <p className="font-size-16 color-black mb-0">{data?.comments}... <a className="pink-color">Read More</a></p> */}
                        {data.comments ? (
                          <p className="font-size-16 color-black mb-0">
                            {showFullContent[i]
                              ? data.comments
                              : `${data.comments.substring(0, 100)}${
                                  data.comments.length > 100 ? "..." : ""
                                } `}
                            {data.comments.length > 100 && (
                              <a
                                className="pink-color"
                                onClick={() => toggleReadMore(i)}
                              >
                                {showFullContent[i] ? "Read Less" : "Read More"}
                              </a>
                            )}
                          </p>
                        ) : null}
                      </div>
                    );
                  })}
                </div>
              </div>
            </section>
          )}

{data?.suggested_vendors?.length > 0 &&(<section className="vendor-storefront-6 py-5">
            <div className="container">
              <div class="border-top  mb-3 mt-5"></div>
              <h3 className="font-size-36 playfair-font color-black mb-5">
                {t('PreferredSuppliers')}
              </h3>
              <div className="row">
                {load
                  ? new Array(4)
                      .fill(null)
                      .map((_, index) => <VendorCardSkeletonHome key={index} />)
                  : data?.suggested_vendors?.length > 0 &&
                  data?.suggested_vendors?.map((s) => (
                      <div key={s._id} className="col-md-3 vendor-card">
                        <div className="vendor-card-body">
                          <Swiper
                            modules={[Navigation, A11y]}
                            spaceBetween={15}
                            slidesPerView={1}
                            navigation
                            pagination={{ clickable: true }}
                            onSwiper={(swiper) => console.log(swiper)}
                            onSlideChange={() => console.log("slide change")}
                          >
                            <SwiperSlide>
                              <a
                                href={`${
                                  process.env.REACT_APP_FrontURL
                                }/supplier-details/${s?.store_name
                                  .replace(/\s/g, "-")
                                  .replace(/&/g, "and")
                                  .toLowerCase()}`}
                                onClick={() => IncrementView(s._id, "profile")}
                                target="_Blank"
                              >
                                <img
                                  alt={s?.store_name}
                                  src={`${process.env.REACT_APP_IMGURL}${s?.image}`}
                                  className="vendor-image w-100"
                                />
                              </a>
                            </SwiperSlide>
                          
                              <SwiperSlide>
                                <a
                                  href={`${
                                    process.env.REACT_APP_FrontURL
                                  }/supplier-details/${s.store_name
                                    .replace(/\s/g, "-")
                                    .replace(/&/g, "and")
                                    .toLowerCase()}`}
                                  onClick={() =>
                                    IncrementView(s._id, "profile")
                                  }
                                  target="_Blank"
                                >
                                  <img
                                   
                                    alt={`${s?.image}`}
                                    src={`${process.env.REACT_APP_IMGURL}${s?.image}`}
                                    className="vendor-image w-100"
                                  />
                                </a>
                              </SwiperSlide>
                       
                          </Swiper>
                          <div className="d-flex justify-content-between align-items-center top-box">
                            {s?.is_featured == 1 ? (
                              <span className="font-size-12 rounded-1 wf-purple-btn py-1 px-3 text-uppercase">
                                {t('Featured')}
                              </span>
                            ) : null}
                            {profile && profile?.role == "customer" && (
                              <div
                                className="heart-icon"
                                onClick={() =>
                                  s?.isfavourite
                                    && viewModal(
                                        s?._id,
                                        s?.prefered_services[0]?._id,
                                        "favourite",
                                        "suggestedStore",
                                        s?.isfavourite
                                      )
                                }
                              >
                                {s.isfavourite?.length>0 ? (
                                  <Heart fill="#EE0E7B" stroke="#EE0E7B" />
                                ) : (
                                  <Heart />
                                )}
                              </div>
                            )}
                          </div>
                          <div className="d-flex align-items-center bottom-box">
                            <span className="font-size-12 rounded-1 wf-pink-btn py-1 px-3 text-uppercase cursor-pointer">
                              <a
                                href={`${
                                  process.env.REACT_APP_FrontURL
                                }/suppliers/${s?.prefered_services[0]?.name
                                  .replace(/\s/g, "-")
                                  .replace(/&/g, "and")
                                  .toLowerCase()}`}
                              >
                                {s?.prefered_services[0]?.name}
                              </a>
                            </span>
                          </div>
                        </div>
                        <div className="vendor-card-footer">
                          <h3 className="font-size-20 fw-medium mb-1">
                            <a
                              href={`${
                                process.env.REACT_APP_FrontURL
                              }/supplier-details/${s.store_name
                                .replace(/\s/g, "-")
                                .replace(/&/g, "and")
                                .toLowerCase()}`}
                              onClick={() => IncrementView(s._id, "profile")}
                              target="_Blank"
                              className="color-black"
                            >
                              {s.store_name}
                            </a>
                          </h3>
                          <div className="d-flex justify-content-between align-items-center">
                            <p className="font-size-16 color-black mb-0">{`${
                              s.cities.length > 0 ? s.cities[0]?.name : ""
                            }${
                              s.cities.length > 0 && s.country.length > 0
                                ? ","
                                : ""
                            } ${
                              s.country.length > 0 ? s.country[0]?.name : ""
                            }`}</p>
                            <p className="font-size-16 color-black mb-0">
                              <i class="bi bi-star-fill purple-color"></i>{" "}
                              <span className="fw-medium">
                                {s?.reviews?.average
                                  ? s?.reviews?.average
                                  : 0}
                              </span>{" "}
                              (
                              {s?.reviews?.total
                                ? s?.reviews?.total
                                : 0}
                              )
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
              </div>
            </div>
          </section>)}
        </>
      )}
      <Footer />
    </>
    
    )
   
  
};

export default Storefront;
