import React, {useEffect,useState} from 'react'
import Header from './HeaderLight'
import Footer from './Footer'
import { Helmet } from 'react-helmet';
import ScrollAnimation from 'react-animate-on-scroll';
import { useTranslation } from 'react-i18next';
import i18n from '../../components/i18n';
import { getProfile } from '../../store/slices/userSlice';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const questions = [
    {
      id: 1,
      question: 'What is the task management feature in Weddified?',
      answer: 'The task management feature in Weddified is a comprehensive tool designed to help you organize and manage all your wedding-related tasks efficiently. It offers customizable checklists, reminders, and progress tracking to streamline your wedding planning process.',
    },
      {
      id: 2,
      question: 'How can I personalize my task list in Weddified?',
      answer: 'You can personalize your task list by adding, editing, or removing tasks. The app also allows you to set your own deadlines and prioritize tasks based on your specific wedding timeline and needs.',
    },
      {
      id: 3,
      question: 'Is it possible to share and collaborate on tasks with my partner or wedding planner?',
      answer: 'Yes, Weddified’s task management feature includes collaborative tools. You can share your task list with your partner, family, or wedding planner and assign tasks to them, facilitating teamwork in the planning process.',
    },
        {
      id: 4,
      question: 'Does the app send reminders for upcoming tasks?',
      answer: 'Yes, Weddified provides automated reminders for upcoming tasks. You can set reminders for specific dates and times to ensure you stay on top of all your wedding planning duties.',
    },
    {
      id: 5,
      question: 'Can I track the overall progress of my wedding planning in the app?',
      answer: 'Absolutely. The task management feature includes a progress tracking tool that gives you a visual overview of completed, pending, and upcoming tasks, helping you understand how much of your planning is accomplished and what’s left.',
    },
    {
      id: 6,
      question: 'How does the task management feature help with budgeting?',
      answer: 'While primarily focused on task organization, this feature integrates with Weddified’s budgeting tools, allowing you to link tasks with your budget and keep track of expenses related to each task.',
    },
    {
      id: 7,
      question: 'Is it possible to access the task manager on different devices?',
      answer: 'Yes, Weddified is designed for cross-device compatibility, so you can access your task manager on any device, be it your smartphone, tablet, or computer, ensuring you have your wedding planning tools at your fingertips at all times.',
    },
    {
      id: 8,
      question: 'How do I prioritize tasks in the app?',
      answer: 'You can prioritize tasks by assigning them different levels of urgency or importance. The app also allows you to rearrange tasks based on your preference and deadlines.',
    },
    {
      id: 9,
      question: 'Can I integrate tasks with my personal calendar?',
      answer: 'Yes, tasks from Weddified can be synchronized with your personal calendar, allowing you to have a consolidated view of your wedding-related activities alongside your everyday schedule.',
    },
    {
      id: 10,
      question: 'Is there support available if I encounter issues with the task management feature?',
      answer: 'Certainly. If you encounter any issues or have questions about the task management feature, our support team is readily available to assist you. You can contact them directly through the app or via our website.',
    },
  ]
  
  


const TaskManagementFeature = () => {
  const profile = useSelector(getProfile);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const currentLanguage = i18n.language;
  const [dotScrollPercentage, setDotScrollPercentage] = useState(1);
  const [timelineOffsetTop, setTimelineOffsetTop] = useState(0);
  const [visibleBadgeIndex, setVisibleBadgeIndex] = useState(-1);

  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight - windowHeight;

      // Calculate the distance of the timeline div from the top of the document
      const newTimelineOffsetTop = document.querySelector('.timeline').offsetTop;
      setTimelineOffsetTop(newTimelineOffsetTop);

      // Calculate the scroll percentage relative to the position of the timeline div
      const scrollPercentage = Math.max(0, (window.scrollY - timelineOffsetTop) / documentHeight) * 100;

      // You can adjust the multiplier to control the speed of the dot
      const dotMultiplier = 3; // Adjust as needed

      // Ensure the dot doesn't go beyond a certain point (e.g., 100%)
      const maxDotPercentage = 93.5;
      const newDotScrollPercentage = Math.min(maxDotPercentage, Math.max(1, scrollPercentage * dotMultiplier));
      setDotScrollPercentage(newDotScrollPercentage);

      // Get the bounding rectangle of the dot
      const dotRect = document.querySelector('.timeline .dot').getBoundingClientRect();

      // Get the bounding rectangles of all rows
      const rowElements = document.querySelectorAll('.timeline .row');
      let newVisibleBadgeIndex = -1;

      rowElements.forEach((rowElement, index) => {
        const rowRect = rowElement.getBoundingClientRect();

        // Check if the top of the dot is below the middle of the row
        if (dotRect.top >= rowRect.top + rowRect.height / 2) {
          newVisibleBadgeIndex = index;
        }
      });

      setVisibleBadgeIndex(newVisibleBadgeIndex);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [timelineOffsetTop]);


  return (
<>
<Helmet>
    <title>Task Management - Feature</title>
    <meta name="description" content="Explore the journey of Weddified, the innovative wedding planning app. Get to know our team, our values, and our dedication to simplifying your wedding planning experience." />
    <meta name="keywords" content="Weddified, wedding planning" />
</Helmet>

<section class="features-hero feature-bg">
<Header theme="light" />
  <div class="container">
    <div class="features-hero-body">
    <div className="row gy-4">
    <div className="col-lg-5 col-md-12">
    <h1 class="font-size-40 color-white playfair-font mb-3">{currentLanguage === 'ar' ? 'خطط لحفل زفافك الآن و بكل سهولة مع ويديفايد' : 'Effortless Wedding Planning at Your Fingertips with Weddified!'}</h1>
    <p className="font-size-20 color-white mb-5">{currentLanguage === 'ar' ? 'استمتع بأقصى درجات السهولة في تنظيم يومك الكبير مع أداة إدارة المهام المتقدمة لدينا.' : 'Experience the Ultimate Ease in Organizing Your Big Day with Our Advanced Task Management Tool.'}</p>
    <div className="d-flex flex-wrap gap-3 mt-3">
      <button class="btn btn-lg wf-purple-btn wf-btn text-uppercase px-4 wf-shadow" onClick={(e)=>{e.preventDefault(); navigate(profile&&profile?.role=="customer"?'/task-management':'/login')}}>{t('StartPlanning')}</button>
      <button class="btn btn-lg wf-white-btn wf-btn text-uppercase px-4 wf-shadow" onClick={()=> navigate('/features/guest-management')}>{t('ExploreOtherFeatures')}</button>
    </div>
    </div>
    <div className="col-lg-7 col-md-12">
        <img src={`${process.env.REACT_APP_FrontURL}/assets/images/task-hero-image.png`} alt="" style={{marginBottom:'-30px'}} className="features-hero-image w-100" />
    </div>
    </div>
    </div>
  </div>
</section>
<section className="features-section-1 py-5">
<div className="container">
  <p className="font-size-16 color-black mb-5 w-50 mx-auto text-center pt-4">{currentLanguage === 'ar' ? 'مرحبًا بك في ويديفايد، حيث تلتقي البساطة بالتطور في تخطيط الحفلات. ميزة إدارة المهام الرائدة لدينا، والتي تتسم بسهولة الاستخدام، مصممة لتحول تحضير حفل زفافك إلى رحلة ممتعة وخالية من التوتر.' 
  : "Welcome to Weddified, where simplicity meets sophistication in wedding planning. Our state-of-the-art, user-friendly task management feature is crafted to transform your wedding preparation into an enjoyable and stress-free journey." }</p>
  <h2 className="font-size-30 playfair-font mb-3 w-75 mx-auto text-center gradient-color">{currentLanguage === 'ar' ? 'كيف يعزز ويديفايد تجربتك في تنظيم حفل الزفاف' : "Why Weddified Enhances Your Wedding Planning Experience" }</h2> 
  <p className="font-size-16 color-black mb-5 w-50 mx-auto text-center">{currentLanguage === 'ar' ? 'يقدم Weddified نهجًا حديثًا وفعّالًا وتعاونيًا لتنظيم حفل الزفاف، مما يجعل العملية بأكملها أكثر سلاسة ومتعة للجميع المعنيين.' 
  : "Weddified offers a modern, efficient, and collaborative approach to organizing a wedding, making the entire process smoother and more enjoyable for everyone involved." }</p>
  <div className="row gy-4 mb-4 pt-3 gx-5">
    <div className="col-lg-6 col-md-12">
        <img src={`${process.env.REACT_APP_FrontURL}/assets/images/task-image-1.png`} className="features-img w-100" alt="" />
        {/* <div className="h-100 w-100 rounded light-purple-bg"></div> */}
    </div>
    <div className="col-lg-6 col-md-12 align-self-center">
    <div className="mb-4">
    <div className="d-flex gap-3 align-items-center mb-2">
        <img height={43} src={`${process.env.REACT_APP_FrontURL}/assets/images/task-icon-1.png`} className="features-icon" alt="" />
        <div>
        <h2 className="font-size-18 fw-semibold purple-color mb-0">{currentLanguage === 'ar' ? 'مزودي حفلات الزفاف المحليين الموثوقين ' : "Verified and Reliable Local Wedding Suppliers" }</h2>
        </div>
      </div>
      <p className="font-size-16 color-black mb-0">{currentLanguage === 'ar' ? 'اكتشف قائمة مختارة من مزودي حفلات الزفاف المحليين، حيث تم فحص كل واحد منهم بدقة لضمان الجودة والموثوقية، مما يضمن لك الاتصال بأفضل الخيارات ليومك الخاص. استفد من شبكة من الموردين ذوي التقييم العالي، من الزهور إلى المصورين، والذين تم التحقق منهم جميعًا من قبل Weddified بسبب تميزهم واحترافيتهم.' 
      : "Discover a curated list of local wedding suppliers, each rigorously vetted for quality and reliability, ensuring you connect with only the best for your special day. Access a network of top-rated vendors, from florists to photographers, all verified by Weddified for their excellence and professionalism." }</p>
    </div>
    <div className="mb-4">
    <div className="d-flex gap-3 align-items-center mb-2">
      <img height={43} src={`${process.env.REACT_APP_FrontURL}/assets/images/task-icon-2.png`} className="features-icon" alt="" />
        <div>
        <h2 className="font-size-18 fw-semibold purple-color mb-0">{currentLanguage === 'ar' ? 'خصومات حصرية ومقارنات الأسعار' : "Exclusive Discounts and Price Comparisons" }</h2>
        </div>
      </div>
      <p className="font-size-16 color-black mb-0">{currentLanguage === 'ar' ? 'تمتع بخصومات حصرية وعروض خاصة من مجموعة متنوعة من مزودي حفلات الزفاف، متاحة فقط لمستخدمي ويديفايد، مما يساعدك في استخدام ميزانيتك بأفضل طريقة. قارن الأسعار والخدمات عبر مزودين مختلفين مباشرة داخل التطبيق، مما يضمن لك الحصول على أفضل العروض أثناء تخطيط حفل الزفاف الخاص بك.' 
      : "Enjoy exclusive discounts and special offers from a range of wedding suppliers, available only to Weddified users,  helping you make the most of your budget. Compare prices and services across different vendors directly within the app, ensuring you get the best deals while  planning your dream wedding." }</p>
    </div>
    <div className="">
    <div className="d-flex gap-3 align-items-center mb-2">
      <img height={43} src={`${process.env.REACT_APP_FrontURL}/assets/images/task-icon-3.png`} className="features-icon" alt="" />
        <div>
        <h2 className="font-size-18 fw-semibold purple-color mb-0">{currentLanguage === 'ar' ? 'تنسيق الوقت مع التاريخ ' : 'Real Time Coordination with Complete History'}</h2>
        </div>
      </div>
      <p className="font-size-16 color-black mb-0">{currentLanguage === 'ar' ? 'استمتع بتنسيق فوري وسلس مع المزودين وفريق حفل الزفاف الخاص بك، مع توفير تاريخ شامل لجميع التفاعلات والتحديثات. ابقَ منظمًا وعلى علم بجميع الخطوات التي اتخذتها في تخطيط حفل زفافك، من الاستفسارات الأولية إلى الترتيبات النهائية، كل ذلك داخل التطبيق.' 
      : "Experience seamless real-time coordination with vendors and your wedding party, complete with a comprehensive  history of all interactions and updates. Stay organized and informed with a complete chronological record of your wedding planning steps, from initial  inquiries to final arrangements, all within the app."}</p>
    </div>
    </div>    
  </div>
</div>
</section>
{/* <section className="features-section-2 py-5">
<div className="container">
    <h2 className="font-size-46 playfair-font mb-4 text-center gradient-color">Features Highlight</h2>
    <p className="font-size-18 purple-color mb-5 w-50 mx-auto text-center">Here's how our Task Management and Checklist features can make your wedding planning a breeze:</p>
    <div className="row gy-4 px-md-5 gx-5 mb-4">
        <div className="col-md-7">
            <img src={`${process.env.REACT_APP_FrontURL}/assets/images/task-featured-image-1.png`} className="features-img w-100 rounded-4" alt="" />
        </div>
        <div className="col-md-5 align-self-center px-md-5">
        <div className="light-pink-bg rounded-circle d-flex align-items-center justify-content-center mb-2" style={{height:'50px', width:'100%', maxWidth:'50px'}}>
        <i class="bi bi-sliders font-size-30 pink-color d-flex"></i></div>
          <h2 className="font-size-26 playfair-font pink-color mb-2">Personalized To-Do Lists</h2>
          <p className="font-size-18 color-black mb-4">Say goodbye to generic task lists. Weddified's AI understands your unique wedding vision and tailors your to-do list to your preferences. Whether you're a fan of rustic chic or elegant black-tie affairs, we've got you covered.</p>
        </div>
        
    </div>
    <div className="row gy-4 px-md-5 gx-5 mb-4">
      <div className="col-md-5 align-self-center px-md-5">
      <div className="light-pink-bg rounded-circle d-flex align-items-center justify-content-center mb-2" style={{height:'50px', width:'100%', maxWidth:'50px'}}>
        <i class="bi bi-list-stars font-size-30 pink-color d-flex"></i></div>
        <h2 className="font-size-26 playfair-font pink-color mb-2">Smart Task Priortization</h2>
        <p className="font-size-18 color-black mb-4">Our AI priortizes tasks, ensuring that you focus on th most critical aspects oif your wedding first. Never miss deadline or overlook essential details again.</p>
        </div>
        <div className="col-md-7">
            <img src={`${process.env.REACT_APP_FrontURL}/assets/images/task-featured-image-1.png`} className="features-img w-100 rounded-4" alt="" />
        </div>
    </div>
    <div className="row gy-4 px-md-5 gx-5 mb-4">
        <div className="col-md-7">
            <img src={`${process.env.REACT_APP_FrontURL}/assets/images/task-featured-image-1.png`} className="features-img w-100 rounded-4" alt="" />
        </div>
        <div className="col-md-5 align-self-center px-md-5">
        <div className="light-pink-bg rounded-circle d-flex align-items-center justify-content-center mb-2" style={{height:'50px', width:'100%', maxWidth:'50px'}}>
        <i class="bi bi-gear font-size-30 pink-color d-flex"></i></div>
          <h2 className="font-size-26 playfair-font pink-color mb-2">Real-time Progress Tracking</h2>
          <p className="font-size-18 color-black mb-4">Watch your wedding plans come to life with real-time updates. Keep track of completed tasks, upcoming milestones, and what's next on your checklist.</p>
        </div>
        
    </div>
    <div className="row gy-4 px-md-5 gx-5 mb-4">
        <div className="col-md-5 align-self-center px-md-5">
        <div className="light-pink-bg rounded-circle d-flex align-items-center justify-content-center mb-2" style={{height:'50px', width:'100%', maxWidth:'50px'}}>
        <i class="bi bi-chat-text font-size-30 pink-color d-flex"></i></div>
          <h2 className="font-size-26 playfair-font pink-color mb-2">Vendor Coordination</h2>
          <p className="font-size-18 color-black mb-4">Communicate seamlessly with your vendors. We help you coordinate with photographers, caterers, florists, and more, making sure everyone is on the same page for your big day.</p>
        </div>
        <div className="col-md-7">
            <img src={`${process.env.REACT_APP_FrontURL}/assets/images/task-featured-image-2.png`} className="features-img w-100 rounded-4" alt="" />
        </div>
    </div>
    <div className="row gy-4 px-md-5 gx-5 mb-4">
        <div className="col-md-7">
            <img src={`${process.env.REACT_APP_FrontURL}/assets/images/task-featured-image-2.png`} className="features-img w-100 rounded-4" alt="" />
        </div>
        <div className="col-md-5 align-self-center px-md-5">
        <div className="light-pink-bg rounded-circle d-flex align-items-center justify-content-center mb-2" style={{height:'50px', width:'100%', maxWidth:'50px'}}>
        <i class="bi bi-arrow-left-right font-size-30 pink-color d-flex"></i></div>
            <h2 className="font-size-26 playfair-font pink-color mb-2">Easy Collaboration</h2>
            <p className="font-size-18 color-black mb-4">Collaborate effortlessly with your partner, family, and wedding party. Share tasks, delegate responsibilities, and keep everyone in sync.</p>
        </div>
        
    </div>
    <div className="row gy-4 px-md-5 gx-5 mb-4">
        <div className="col-md-5 align-self-center px-md-5">
        <div className="light-pink-bg rounded-circle d-flex align-items-center justify-content-center mb-2" style={{height:'50px', width:'100%', maxWidth:'50px'}}>
        <i class="bi bi-graph-down-arrow font-size-30 pink-color d-flex"></i></div>
            <h2 className="font-size-26 playfair-font pink-color mb-2">Stress Reduction</h2>
            <p className="font-size-18 color-black mb-4">With Weddified, there's no need to stress over the details. Our AI keeps everything organized, so you can focus on what truly matters - the love you're celebrating.</p>
        </div>
        <div className="col-md-7">
            <img src={`${process.env.REACT_APP_FrontURL}/assets/images/task-featured-image-2.png`} className="features-img w-100 rounded-4" alt="" />
        </div>
    </div>
    <div className="row gy-4 px-md-5 gx-5 mb-5">
        <div className="col-md-7">
            <img src={`${process.env.REACT_APP_FrontURL}/assets/images/task-featured-image-2.png`} className="features-img w-100 rounded-4" alt="" />
        </div>
        <div className="col-md-5 align-self-center px-md-5">
        <div className="light-pink-bg rounded-circle d-flex align-items-center justify-content-center mb-2" style={{height:'50px', width:'100%', maxWidth:'50px'}}>
        <i class="bi bi-phone font-size-30 pink-color d-flex"></i></div>
            <h2 className="font-size-26 playfair-font pink-color mb-2">Mobile Accessibility</h2>
            <p className="font-size-18 color-black mb-4">Access your checklist and task management on the go, wherever you are. Stay in control of your wedding plans from the palm of your hand.</p>
        </div>
        
    </div>
    <p className="font-size-30 purple-color mb-2 w-75 mx-auto text-center">Join Weddified and experience the future of wedding planning with our AI-powered Task Management and Checklist features.</p>
    <p className="font-size-18 pink-color w-50 mx-auto text-center">Let us handle the logistics while you enjoy the journey to your perfect day.</p>
</div>
</section> */}
<section className="features-section-2 py-5">
<div className="container">
    <h2 className="font-size-46 playfair-font mb-4 text-center gradient-color">{t('FeaturesHighlight')}</h2>
    <p className="font-size-18 purple-color mb-5 w-50 mx-auto text-center">{currentLanguage === 'ar' ? 'استمتع بتنسيق فوري وسلس مع المزودين وفريق حفل الزفاف الخاص بك، مع توفير تاريخ شامل لجميع التفاعلات والتحديثات. ابقَ منظمًا وعلى علم بجميع الخطوات التي اتخذتها في تخطيط حفل زفافك، من الاستفسارات الأولية إلى الترتيبات النهائية، كل ذلك داخل التطبيق.' 
    : "Here's how our Task Management and Checklist features can make your wedding planning a breeze:"}</p>
  <div class="timeline">
  <div className="line"></div>
  <div className="dot" style={{ top: `${dotScrollPercentage}%` }}></div>
  <div className="row gy-4 px-md-5 gx-5 mb-4">
  {visibleBadgeIndex === 0 && <div className={`timeline-badge ${dotScrollPercentage >= 50 ? 'visible' : ''}`}></div>}
      <div className={`col-md-5 order-md-1`} >
        <ScrollAnimation animateIn="animate__zoomIn">
            <img src={`${process.env.REACT_APP_FrontURL}/assets/images/task-highlight-image-1.png`} className="features-img w-100" alt="" /> 
        </ScrollAnimation>
        </div>
        
        <div className={`col-md-5 order-md-2 align-self-center ${currentLanguage === 'ar' ? 'offset-md-1' : 'offset-md-2'}`} >
        <ScrollAnimation animateIn="animate__zoomIn">
        <img width={62} src={`${process.env.REACT_APP_FrontURL}/assets/images/task-highlight-icon-1.svg`} className="features-icon" alt="" />
          <h2 className="font-size-26 playfair-font pink-color mb-2">{currentLanguage === 'ar' ? 'قوائم المهام الشخصية' : 'Personalized To-Do Lists'}</h2>
          <p className="font-size-16 color-black mb-4">{currentLanguage === 'ar' ? 'استمتع بالتخلص من القوائم العامة للمهام. يفهم الذكاء الاصطناعي في ويديفايد رؤيتك الفريدة لحفل الزفاف ويضبط قائمة مهامك وفقًا لتفضيلاتك. سواء كنت من محبي الطابع الريفي الأنيق أو الفعاليات الراقية باللون الأسود، فإننا نغطي جميع الاحتياجات.' 
          : "Say goodbye to generic task lists. Weddified's AI understands your unique wedding vision and tailors your to-do list to your preferences. Whether you're a fan of rustic chic or elegant black-tie affairs, we've got you covered."}</p>
        </ScrollAnimation>
        </div>
    </div>
    <div className="row gy-4 px-md-5 gx-5 mb-4">
    {visibleBadgeIndex === 1 && <div className={`timeline-badge ${dotScrollPercentage >= 50 ? 'visible' : ''}`}></div>}
      <div className="col-md-5 align-self-center px-md-5 order-md-1 order-2" >
      <ScrollAnimation animateIn="animate__zoomIn">
      <img width={62} src={`${process.env.REACT_APP_FrontURL}/assets/images/task-highlight-icon-2.svg`} className="features-icon mb-2" alt="" />
        <h2 className="font-size-26 playfair-font pink-color mb-2">{currentLanguage === 'ar' ? 'تحديد أولويات المهام الذكية' : 'Smart Task Priortization'}</h2>
        <p className="font-size-16 color-black mb-4">{currentLanguage === 'ar' ? 'يقوم الذكاء الاصطناعي لدينا بتحديد أولويات المهام، مما يضمن أن تركز على أهم جوانب حفل زفافك أولاً. لا تفوت أبدًا المواعيد النهائية أو تتجاهل التفاصيل الأساسية مرة أخرى.' 
        : 'Our AI priortizes tasks, ensuring that you focus on th most critical aspects oif your wedding first. Never miss deadline or overlook essential details again.'}</p>
        </ScrollAnimation>
      </div>
        <div className={`col-md-5 order-md-2 order-1 ${currentLanguage === 'ar' ? 'offset-md-1' : 'offset-md-2'}`} >
        <ScrollAnimation animateIn="animate__zoomIn">
            <img src={`${process.env.REACT_APP_FrontURL}/assets/images/task-highlight-image-2.png`} className="features-img w-100" alt="" />
          </ScrollAnimation>
        </div>
    </div>
    <div className="row gy-4 px-md-5 gx-5 mb-4">
    {visibleBadgeIndex === 2 && <div className={`timeline-badge ${dotScrollPercentage >= 50 ? 'visible' : ''}`}></div>}
        <div className="col-md-5 order-md-1" >
        <ScrollAnimation animateIn="animate__zoomIn">
            <img src={`${process.env.REACT_APP_FrontURL}/assets/images/task-highlight-image-3.png`} className="features-img w-100" alt="" />
            </ScrollAnimation>
        </div>
        <div className={`col-md-5 align-self-center px-md-5 order-md-2 ${currentLanguage === 'ar' ? 'offset-md-1' : 'offset-md-2'}`} >
        <ScrollAnimation animateIn="animate__zoomIn">
        <img width={62} src={`${process.env.REACT_APP_FrontURL}/assets/images/task-highlight-icon-3.svg`} className="features-icon mb-2" alt="" />
          <h2 className="font-size-26 playfair-font pink-color mb-2">{currentLanguage === 'ar' ? 'تتبع التقدم في الوقت الحقيقي' : 'Real-time Progress Tracking'}</h2>
          <p className="font-size-16 color-black mb-4">{currentLanguage === 'ar' ? 'راقب تحول خطط حفل زفافك إلى واقع من خلال التحديثات في الوقت الحقيقي. تتيح لك متابعة المهام المكتملة، والأحداث القادمة، وما هو قادم على قائمة المراجعة الخاصة بك.' 
          : "Watch your wedding plans come to life with real-time updates. Keep track of completed tasks, upcoming milestones, and what's next on your checklist."}</p>
          </ScrollAnimation>
        </div>
        
    </div>
    <div className="row gy-4 px-md-5 gx-5 mb-4">
    {visibleBadgeIndex === 3 && <div className={`timeline-badge ${dotScrollPercentage >= 50 ? 'visible' : ''}`}></div>}
        <div className="col-md-5 align-self-center px-md-5 order-md-1 order-2" >
        <ScrollAnimation animateIn="animate__zoomIn">
        <img width={62} src={`${process.env.REACT_APP_FrontURL}/assets/images/task-highlight-icon-4.svg`} className="features-icon mb-2" alt="" />
          <h2 className="font-size-26 playfair-font pink-color mb-2">{currentLanguage === 'ar' ? 'التنسيق مع المزودين' : 'Vendor Coordination'}</h2>
          <p className="font-size-16 color-black mb-4">{currentLanguage === 'ar' ? 'تواصل بسلاسة مع مزودي حفل زفافك. نساعدك في التنسيق مع المصورين، ومقدمي الطعام، والزهور، وغيرهم، مضمنينًا أن الجميع على نفس الصفحة ليوم الحفل الكبير' 
          : 'Communicate seamlessly with your vendors. We help you coordinate with photographers, caterers, florists, and more, making sure everyone is on the same page for your big day.'}</p>
          </ScrollAnimation>
        </div>
        <div className={`col-md-5 order-md-2 order-1 ${currentLanguage === 'ar' ? 'offset-md-1' : 'offset-md-2'}`} >
        <ScrollAnimation animateIn="animate__zoomIn">
            <img src={`${process.env.REACT_APP_FrontURL}/assets/images/task-highlight-image-4.png`} className="features-img w-100" alt="" />
            </ScrollAnimation>
        </div>
    </div>
    <div className="row gy-4 px-md-5 gx-5 mb-4">
    {visibleBadgeIndex === 4 && <div className={`timeline-badge ${dotScrollPercentage >= 50 ? 'visible' : ''}`}></div>}
        <div className="col-md-5 order-md-1" >
        <ScrollAnimation animateIn="animate__zoomIn">
            <img src={`${process.env.REACT_APP_FrontURL}/assets/images/task-highlight-image-5.png`} className="features-img w-auto mx-auto" alt="" />
            </ScrollAnimation>
        </div>
        <div className={`col-md-5 align-self-center px-md-5 order-md-2 ${currentLanguage === 'ar' ? 'offset-md-1' : 'offset-md-2'}`} >
        <ScrollAnimation animateIn="animate__zoomIn">
        <img width={62} src={`${process.env.REACT_APP_FrontURL}/assets/images/task-highlight-icon-5.svg`} className="features-icon mb-2" alt="" />
            <h2 className="font-size-26 playfair-font pink-color mb-2">{currentLanguage === 'ar' ? 'التعاون السهل' : 'Easy Collaboration'}</h2>
            <p className="font-size-16 color-black mb-4">{currentLanguage === 'ar' ? 'تعاون بسهولة مع شريكك، وعائلتك، وفريق حفل الزفاف. شارك المهام، وتفويض المسؤوليات، وحافظ على تناغم الجميع.' : 'Collaborate effortlessly with your partner, family, and wedding party. Share tasks, delegate responsibilities, and keep everyone in sync.'}</p>
            </ScrollAnimation>
        </div>
        
    </div>
    <div className="row gy-4 px-md-5 gx-5 mb-4">
    {visibleBadgeIndex === 5 && <div className={`timeline-badge ${dotScrollPercentage >= 50 ? 'visible' : ''}`}></div>}
        <div className="col-md-5 align-self-center px-md-5 order-md-1 order-2" >
        <ScrollAnimation animateIn="animate__zoomIn">
        <img width={62} src={`${process.env.REACT_APP_FrontURL}/assets/images/task-highlight-icon-6.svg`} className="features-icon mb-2" alt="" />
            <h2 className="font-size-26 playfair-font pink-color mb-2">{currentLanguage === 'ar' ? 'تقليل التوتر' : 'Stress Reduction'}</h2>
            <p className="font-size-16 color-black mb-4">{currentLanguage === 'ar' ? 'مع ويديفايد، لا داعي للقلق بشأن التفاصيل. يحتفظ ذكاء الاصطناعي لدينا بتنظيم كل شيء، حتى تتمكن من التركيز على ما يهم حقًا ' 
            : "With Weddified, there's no need to stress over the details. Our AI keeps everything organized, so you can focus on what truly matters - the love you're celebrating."}</p>
            </ScrollAnimation>
        </div>
        <div className={`col-md-5 order-md-2 order-1 ${currentLanguage === 'ar' ? 'offset-md-1' : 'offset-md-2'}`} >
        <ScrollAnimation animateIn="animate__zoomIn">
            <img src={`${process.env.REACT_APP_FrontURL}/assets/images/task-highlight-image-6.png`} className="features-img w-auto mx-auto" alt="" />
            </ScrollAnimation>
        </div>
    </div>
    <div className="row gy-4 px-md-5 gx-5 mb-5">
    {visibleBadgeIndex === 6 && <div className={`timeline-badge ${dotScrollPercentage >= 50 ? 'visible' : ''}`}></div>}
        <div className="col-md-5 order-md-1" >
        <ScrollAnimation animateIn="animate__zoomIn">
            <img src={`${process.env.REACT_APP_FrontURL}/assets/images/task-highlight-image-7.png`} className="features-img w-100" alt="" />
            </ScrollAnimation>
        </div>
        <div className={`col-md-5 align-self-center px-md-5 order-md-2 ${currentLanguage === 'ar' ? 'offset-md-1' : 'offset-md-2'}`} >
        <ScrollAnimation animateIn="animate__zoomIn">
        <img width={62} src={`${process.env.REACT_APP_FrontURL}/assets/images/task-highlight-icon-7.svg`} className="features-icon mb-2" alt="" />
            <h2 className="font-size-26 playfair-font pink-color mb-2">{currentLanguage === 'ar' ? 'إمكانية الوصول عبر الهاتف المحمول' : 'Mobile Accessibility'}</h2>
            <p className="font-size-16 color-black mb-4">{currentLanguage === 'ar' ? 'قم بالوصول إلى قائمة المراجعة وإدارة المهام أثناء التنقل، أينما كنت. ابقَ على تحكم في خطط زفافك من خلال جوالك.' 
            : 'Access your checklist and task management on the go, wherever you are. Stay in control of your wedding plans from the palm of your hand.'}</p>
            </ScrollAnimation>
        </div>
        
    </div>
  </div>
    
    
    {/* <p className="font-size-30 purple-color mb-2 w-75 mx-auto text-center">Join Weddified and experience the future of wedding planning with our AI-powered Task Management and Checklist features.</p>
    <p className="font-size-16 pink-color w-50 mx-auto text-center">Let us handle the logistics while you enjoy the journey to your perfect day.</p> */}
</div>
</section>
<section className="features-section-3 py-5">
<div className="container">
<h2 className="font-size-36 playfair-font gradient-color mb-3 text-center w-75 mx-auto">{currentLanguage === 'ar' ? 'حقائق مذهلة تسلط الضوء على قوة إدارة المهام' : 'Surprising Facts That Highlight the Power of Task Management'}</h2>
  <div className="row gy-4 mb-4 pt-3 gx-5">
  <div className="col-lg-4 col-md-12 align-self-center order-lg-1 order-2">
    <p className="font-size-16 color-black mb-4">{currentLanguage === 'ar' ? 'تخطيط الزفاف هو رقص دقيق بين التفاصيل واتخاذ القرارات. بينما تنطلق في هذه الرحلة نحو يومك الخاص، فإن فهم تأثير إدارة المهام الفعّالة يمكن أن يحول تجربتك بشكل كامل.'
     : 'Wedding planning is an intricate dance of details and decisions. As you embark on this journey towards your special day, understanding the impact of effective task management can transform your experience.'}</p>
    <div className="row">
      <div className="col-md-6 light-pink-bg py-4">
      <h3 className="font-size-50 gradient-color playfair-font mb-3 text-center">100+</h3>
      <p className="font-size-14 color-black mb-0 text-center">{currentLanguage === 'ar' ? 'مهمة لإدارتها في حفل زفاف من قبل الزوجين' : 'Tasks to manage in a wedding by an avegrage couple'}</p>
      </div>
      <div className="col-md-6 py-4">
      <h3 className="font-size-50 gradient-color playfair-font mb-3 text-center">150+</h3>
      <p className="font-size-14 color-black mb-0 text-center">{currentLanguage === 'ar' ? 'ساعة من التخطيط لحفل الزفاف يمكن حفظها باستخدام Weddified.' : 'hours of wedding planning can be saved by Weddifed'}</p>
      </div>
      <div className="col-md-6 py-4">
      <h3 className="font-size-50 gradient-color playfair-font mb-3 text-center">60%</h3>
      <p className="font-size-14 color-black mb-0 text-center">{currentLanguage === 'ar' ? 'من الأزواج يعتمدون على الموارد عبر الإنترنت لتخطيط حفل الزفاف.' : 'couples rely on online resources for wedding planning'}</p>
      </div>
      <div className="col-md-6 light-purple-bg py-4">
      <h3 className="font-size-50 gradient-color playfair-font mb-3 text-center">1000+</h3>
      <p className="font-size-14 color-black mb-0 text-center">{currentLanguage === 'ar' ? 'قرار يتخذه زوجان خلال عملية تخطيط حفل زفافهم.' : 'decisions are taken by a couple during their wedding planning process'}</p>
      </div>
    </div>
  </div>  
    <div className="col-lg-8 col-md-12 order-lg-2 order-1">
        <img src={`${process.env.REACT_APP_FrontURL}/assets/images/task-facts-image.png`} className="features-img w-100" alt="" />
        {/* <div className="h-100 w-100 rounded light-purple-bg"></div> */}
    </div>  
  </div>
</div>
</section>
<section className="features-section-4 py-5">
<div className="container">
  <div className="row gy-4 mb-4 pt-3 gx-5">
  <div className="col-lg-5 col-md-12 align-self-center">
    <img src={`${process.env.REACT_APP_FrontURL}/assets/images/task-guide-image.png`} className="features-img w-100" alt="" />
  </div>  
    <div className="col-lg-7 col-md-12">
    <p className="font-size-18 purple-color mb-0">{t('HowtoGuide')}</p>
    <h2 className="font-size-32 playfair-font mb-4 pink-color">{currentLanguage === 'ar' ? 'دليل سريع لاستخدام ميزة إدارة المهام في ويديفايد' : 'Quick Guide to Using Weddified’s Task Management'}</h2> 
    <p className="font-size-18 color-black mb-3">{currentLanguage === 'ar' ? 'قم بإدارة تخطيط حفل زفافك بكل سهولة باستخدام ميزة إدارة المهام في ويديفايد. فيما يلي دليل بسيط للبدء في تنظيم يومك الخاص وتحديد الأولويات والتعاون:' 
    : "Effortlessly manage your wedding planning with Weddified's task management feature. Here's a simple guide to get you started on organizing, prioritizing, and collaborating for your special day:"}</p>
      
      <div className="mb-4 light-pink-bg p-3 rounded-3 wf-shadow">
        <h2 className="font-size-18 fw-semibold pink-color mb-2">{currentLanguage === 'ar' ? 'تحديد الأولويات وتعيين تذكيرات' : 'Priortize and Set Reminders'}</h2>
        <div className="row">
        <div className="col-md-6 d-flex align-items-start gap-3">
        <div className="pink-bg rounded-circle d-flex align-items-center justify-content-center mt-2" style={{height:'17px', width:'100%', maxWidth:'17px'}}>
          <i class="bi bi-check2-circle font-size-14 color-white d-flex"></i>
        </div>
        <div>
          <p className="font-size-14 color-black mb-0">{currentLanguage === 'ar' ? 'قم بترتيب المهام استنادًا إلى الأولوية باستخدام ميزة السحب والإفلات.' : 'Arrange tasks based on priority, using the drag-and-drop feature.'}</p>
        </div>
        </div>
        <div className="col-md-6 d-flex align-items-start gap-3">
        <div className="pink-bg rounded-circle d-flex align-items-center justify-content-center mt-1" style={{height:'17px', width:'100%', maxWidth:'17px'}}>
          <i class="bi bi-check2-circle font-size-14 color-white d-flex"></i>
        </div>
        <div>
          <p className="font-size-14 color-black mb-0">{currentLanguage === 'ar' ? 'قم بتعيين تذكيرات لكل مهمة لمتابعة المواعيد النهائية.' : 'Set up reminders for each task to keep track of deadlines.'}</p>
        </div>
        </div>
        </div>
      </div>

      <div className="mb-4 light-purple-bg p-3 rounded-3 wf-shadow">
        <h2 className="font-size-18 fw-semibold pink-color mb-2">{currentLanguage === 'ar' ? 'التعاون ومراقبة التقدم' : 'Collaborate and Monitor Progress'}</h2>
        <div className="row">
        <div className="col-md-6 d-flex align-items-start gap-3">
        <div className="purple-bg rounded-circle d-flex align-items-center justify-content-center mt-1" style={{height:'17px', width:'100%', maxWidth:'17px'}}>
          <i class="bi bi-check2-circle font-size-14 color-white d-flex"></i>
        </div>
        <div>
          <p className="font-size-14 color-black mb-0">{currentLanguage === 'ar' ? 'شارك قائمة مهامك مع الأشخاص الآخرين المعنيين بتخطيط حفل الزفاف.' : 'Share your task list with other involved in your wedding planning.'}</p>
        </div>
        </div>
        <div className="col-md-6 d-flex align-items-start gap-3">
        <div className="purple-bg rounded-circle d-flex align-items-center justify-content-center mt-1" style={{height:'17px', width:'100%', maxWidth:'17px'}}>
          <i class="bi bi-check2-circle font-size-14 color-white d-flex"></i>
        </div>
        <div>
          <p className="font-size-14 color-black mb-0">{currentLanguage === 'ar' ? 'قم بتحديث قائمة المهام بانتظام وحدد المهام المكتملة لمراقبة التقدم العام.' : 'Regularly update and check off completed tasks to monitor overall progress.'}</p>
        </div>
        </div>
        </div>
      </div>
      <div className="light-black-bg p-3 rounded-3 wf-shadow">
        <h2 className="font-size-18 fw-semibold pink-color mb-2">{currentLanguage === 'ar' ? 'إنشاء وتخصيص قائمة المهام الخاصة بك' : 'Create and Personalize Your Task List'}</h2>
        <div className="row">
        <div className="col-md-6 d-flex align-items-start gap-3">
        <div className="black-bg rounded-circle d-flex align-items-center justify-content-center mt-1" style={{height:'17px', width:'100%', maxWidth:'17px'}}>
          <i class="bi bi-check2-circle font-size-14 color-white d-flex"></i>
        </div>
        <div>
          <p className="font-size-14 color-black mb-0">{currentLanguage === 'ar' ? 'ابدأ بإعداد قائمة المهام الأولية في التطبيق أو قم بتحميل قوائم المهام المعرفة مسبقًا.' : 'Start by setting up your initial task list in the app or load pre-defined task lists'}</p>
        </div>
        </div>
        <div className="col-md-6 d-flex align-items-start gap-3">
        <div className="black-bg rounded-circle d-flex align-items-center justify-content-center mt-1" style={{height:'17px', width:'100%', maxWidth:'17px'}}>
          <i class="bi bi-check2-circle font-size-14 color-white d-flex"></i>
        </div>
        <div>
          <p className="font-size-14 color-black mb-0">{currentLanguage === 'ar' ? 'إدارة تفاصيل المهام مثل الأسماء وتواريخ الاستحقاق والملاحظات، وتصنيفها لتحسين التنظيم.' : 'Manage task details like names, due dates, and notes, and categorize them for better organization.'}</p>
        </div>
        </div>
        </div>
      </div>
    </div>  
  </div>
</div>
</section>
<section className="features-section-5 py-5">
<div className="container">
    <h2 className="font-size-46 playfair-font gradient-color mb-5 text-center">{t('FrequentlyAskedQuestions')}</h2>
    <div className="faq-grid-system">
    {questions.map((data)=>(
    <div className="" key={data}>
        <h3 className="font-size-18 playfair-font color-black mb-2">
        <img style={{marginRight:'10px'}} src="../assets/images/w-pink.svg" width="35" alt="" className="features-icon-img"/> 
        {data.question}</h3>
        <p className="font-size-16 color-black mb-4"  dangerouslySetInnerHTML={{ __html: data.answer }}></p>
    </div>
    ))}
    </div>
    <p className="font-size-16 color-black mb-0 text-center">{currentLanguage === 'ar' ? 'لأي استفسار إضافي، اتصل بفريق دعم ويدفايد.' : 'For any additional queries, contact Weddified’s Support Team.'}</p>
</div>
</section>
<section className="features-section-6 py-5 dowload-app-bg">
<div className="container">
  <div className="row gy-4 mb-4 pt-3 gx-5">
    <div className="col-lg-7 col-md-12">
        <img src={`${process.env.REACT_APP_FrontURL}/assets/images/feature-download-image.png`} className="features-img w-100" alt="" />
    </div>
    <div className="col-lg-5 col-md-12 align-self-center">
      <h2 className="font-size-36 playfair-font mb-2 pink-color">{currentLanguage === 'ar' ? 'ابدأ تخطيط حفل زفافك بدون أي عناء اليوم!' : 'Begin Your Hassle-Free Wedding Planning Today!'}</h2> 
      <p className="font-size-16 color-black mb-4">{currentLanguage === 'ar' ? 'قل وداعًا لإجهاد تخطيط الزفاف ومرحبًا بـ ويدفايد! تطبيقنا الكامل لتخطيط الزفاف متوفر الآن لتحول رحلتك إلى المذبح إلى تجربة سلسة وممتعة. مع Weddified، يكون يومك المثالي على بُعد نقرة واحدة.' 
      : 'Say goodbye to wedding planning stress and hello to Weddified! Our all-in-one wedding planning app is here to transform your journey to the altar into a seamless, enjoyable experience. With Weddified, your perfect day is just a tap away.'}</p>
      <div className="d-flex align-items-center gap-3 mb-4">
      <img width={170} class="google-store-img img-fluid " src={`${process.env.REACT_APP_FrontURL}/assets/images/play-store-img.svg`} alt=""/>
        <img width={170} class="apple-store-img img-fluid" src={`${process.env.REACT_APP_FrontURL}/assets/images/app-store-img.svg`} alt=""/>
      </div>
      <div className="d-flex align-items-center gap-3">
      <img class="" width="70" src={`${process.env.REACT_APP_FrontURL}/assets/images/dowload-weddified-icon.svg`} alt=""/>
      <div>
      <p className="font-size-18 color-black mb-0">{t('StartPlanningYourDreamWeddingToday')}!</p>
      <p className="font-size-18 mb-0 fw-semibold"><a className='pink-color'>{t('DownloadWeddifiedforFree')}! <i class="bi bi-arrow-right"></i></a></p>
      </div>
      </div>
    </div>    
  </div>
</div>
</section>
<section className="features-section-7 py-5 subscribe-bg">
<div className="container">
<p className="font-size-18 color-black text-center mb-3 mt-5">{t('ExclusiveUpdates')}</p>
    <h3 className="font-size-36 playfair-font pink-color mb-5 text-center">{t('JointheWeddifiedInsiderCommunity')}:<br/> {t('StayUpdatedontheLatestinWeddingPlanning')}!</h3>
    <div class="input-group wf-input-group w-50 mx-auto mb-1">
      <div class="form-floating wf-input">
        <input type="email" class="form-control" id="email-address" placeholder="Email Address"/>
        <label for="email-address">{t('EmailAddress')}</label>
      </div>
      <button class="btn wf-pink-btn wf-btn">{t('SubscribeNow')}</button>
    </div>
    <p className="font-size-12 color-black text-center mb-5">{currentLanguage === 'ar' ? 'كن على يقين، عنوان بريدك الإلكتروني في أمان معنا. سيتم استخدامه حصريًا لإرسال تحديثات ويديفايد ولن يتم مشاركته أبدًا مع أطراف أخرى.' : 'Rest assured, your email address is safe with us. It will be exclusively used to send you Weddified updates and will never be shared with third parties.'}</p>
</div>
</section>
  
<Footer />
    </>
  )
}

export default TaskManagementFeature