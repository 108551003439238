import React, { useEffect, useState, useContext } from 'react'
import { context } from '../../context/context';
import {  useNavigate, useLocation, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import { PanelRightOpen } from 'lucide-react';

const Sidebar = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
    const navigate = useNavigate();
    const location=useLocation();
    const { toggleButton,SetToggleButton } = useContext(context);
  return (
    <>
    <div className="wf-business-profile-sidebar-toggle" onClick={() => SetToggleButton((prev) => !prev)}>
    <PanelRightOpen />
    </div>
    <div className={toggleButton?"sidebar wf-shadow-2 white-bg close rounded":"sidebar wf-shadow-2 white-bg rounded"}>
    <div class="logo-details cursor-pointer" onClick={() => SetToggleButton((prev) => !prev)}>
    <i class="bi bi-list d-md-block d-none"></i>
    <i class="bi bi-x-lg d-block d-md-none"></i>
    </div>
    <ul class="nav-links">
      <li>
      <div className={location?.pathname=="/business-details"?"icon-link active":"icon-link"}>
        <Link to="/business-details" >
        <div className="link_icon"><img src={`${process.env.REACT_APP_FrontURL}/assets/images/business-details-icon.svg`}  alt="" className="sidebar-icon-v" /></div>
          <span class="link_name">{t('Business')} {t('Details')}</span>
        </Link>
        </div>
      </li>
      <li>
        <div className={location?.pathname=="/additional-details"?"icon-link active":"icon-link"}>
          <Link to="/additional-details">
          <div className="link_icon"><img src={`${process.env.REACT_APP_FrontURL}/assets/images/business-details-icon.svg`} alt="" className="sidebar-icon-v" /></div>
            <span class="link_name">{t('Additional')} {t('Details')}</span>
          </Link>
        </div>
      </li>
      <li>
        <div className={location?.pathname=="/contact-details"?"icon-link active":"icon-link"}>
          <Link to="/contact-details">
          <div className="link_icon"><img src={`${process.env.REACT_APP_FrontURL}/assets/images/contact-details-icon.svg`}  alt="" className="sidebar-icon-v" /></div>
            <span class="link_name">{t('Contact')} {t('Details')}</span>
          </Link>
        </div>
      </li>
       <li>
        <div className={location?.pathname=="/location-and-maps"?"icon-link active":"icon-link"}>
          <Link to="/location-and-maps">
          <div className="link_icon"><img src={`${process.env.REACT_APP_FrontURL}/assets/images/location-map-icon.svg`} alt="" className="sidebar-icon-v" /></div>
            <span class="link_name">{t('LocationandMaps')}</span>
          </Link>
        </div>
      </li>
      <li>
      <div className={location?.pathname=="/faqs"?"icon-link active":"icon-link"}>
        <Link to="/faqs">
        <div className="link_icon"><img src={`${process.env.REACT_APP_FrontURL}/assets/images/faqs-icon.svg`} alt="" className="sidebar-icon-v" /></div>
          <span class="link_name">{t('FAQs')}</span>
        </Link>
        </div>
      </li>
      <li>
      <div className={location?.pathname=="/pricing-and-deals"?"icon-link active":"icon-link"}>
        <Link to="/pricing-and-deals">
        <div className="link_icon"><img src={`${process.env.REACT_APP_FrontURL}/assets/images/pricing-deals-icon.svg`} alt="" className="sidebar-icon-v" /></div>
          <span class="link_name">{t('Pricing&Deals')}</span>
        </Link>
        </div>
      </li>
      <li>
      <div className={location?.pathname=="/photos-and-videos"?"icon-link active":"icon-link"}>
        <Link to="/photos-and-videos">
        <div className="link_icon"><img src={`${process.env.REACT_APP_FrontURL}/assets/images/photos-videos-icon.svg`}  alt="" className="sidebar-icon-v" /></div>
          <span class="link_name">{t('Photos&Videos')}</span>
        </Link>
        </div>
      </li>
      <li>
      <div className={location?.pathname=="/social-network"?"icon-link active":"icon-link"}>
        <Link to="/social-network">
        <div className="link_icon"><img src={`${process.env.REACT_APP_FrontURL}/assets/images/social-network-icon.svg`}  alt="" className="sidebar-icon-v" /></div>
          <span class="link_name">{t('SocialNetwork')}</span>
        </Link>
        </div>
      </li>
      <li>
      <div className={location?.pathname=="/preferred-suppliers"?"icon-link active":"icon-link"}>
        <Link to="/preferred-suppliers">
        <div className="link_icon"><img src={`${process.env.REACT_APP_FrontURL}/assets/images/preferred-suppliers-icon.svg`}  alt="" className="sidebar-icon-v" /></div>
          <span class="link_name">{t('PreferredSuppliers')}</span>
        </Link>
        </div>
      </li>
      <li>
      <div className={location?.pathname=="/weddified-badge"?"icon-link active":"icon-link"}>
        <Link to="/weddified-badge">
        <div className="link_icon"><img src={`${process.env.REACT_APP_FrontURL}/assets/images/weddified-badge.svg`}  alt="" className="sidebar-icon-v" /></div>
          <span class="link_name">{t('WeddifiedBadge')}</span>
        </Link>
        </div>
      </li>
    </ul>
    </div>
    </>
  )
}

export default Sidebar