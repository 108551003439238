import { Heart } from 'lucide-react'
import React from 'react'

const VendorDetailSkeleton = () => {
  return (
    <>
      <section className="vendor-storefront-1 pb-1 pt-3">
      <div className="container">
      <div class="border-top  mb-3 mt-3"></div>
      <div className="d-md-flex align-items-center justify-content-between mb-2">
      <span style={{width:'250px', height:'46px'}} className="shimmer rounded mb-3"></span>
      <div className="d-flex align-items-center gap-3 flex-wrap">
      <span style={{width:'142px', height:'24px'}} className="shimmer rounded"></span>
      {/* <span className="font-size-14 rounded-1 black-bg color-white py-2 px-3 text-uppercase">Top Pick</span> */}
      <span style={{width:'180px', height:'24px'}} className="shimmer rounded"></span>
      </div>
      </div>
      <div className="row gy-3">
      <div className="col-md-9 d-md-flex align-items-center gap-3">
      <span style={{width:'200px', height:'27px'}} className="shimmer rounded"></span>
        <span style={{width:'142px', height:'24px'}} className="shimmer rounded"></span>
        <span style={{width:'182px', height:'24px'}} className="shimmer rounded"></span>
      </div>
      <div className="col-md-3 d-flex align-items-center justify-content-md-end">
      <span style={{width:'100px', height:'24px'}} className="shimmer rounded"></span>
      </div>
      </div>
      </div>
    </section>
    <section className="vendor-storefront-2 py-3">
      <div className="container">
      <div className="storefront-gallery position-relative">
      <div className="row gy-4 gx-3">
          <div className="col-md-5">
          <div className="gallery-card">
              <span className="shimmer gallery-img big"></span>
              </div>
          </div>
          <div className="col-md-7 d-md-block d-none">
              <div className="row mb-4 gy-4 gx-3">
          <div className="col-md-6">
          <div className="gallery-card">
          <span className="shimmer gallery-img"></span>
              </div>
          </div>
          <div className="col-md-6">
          <div className="gallery-card">
              <span className="shimmer gallery-img"></span>
              </div>
          </div>
          </div>
          <div className="row gy-4">
          <div className="col-md-6">
          <div className="gallery-card">
              <span className="shimmer gallery-img"></span>
              </div>
          </div>
         <div className="col-md-6">
          <div className="gallery-card">
              <span className="shimmer gallery-img"></span>
              </div>
          </div>
          </div>
          </div>
        </div>
    
        <a style={{width:'212px', height:'43px'}} className="see-all-photos-btn shimmer wf-shadow-2"></a>
        </div>
      </div>
    </section>
    <section className="vendor-storefront-3 py-3">
    <div className="container">
    <div className="d-flex justify-content-between row-gap-3 flex-md-row flex-column">
    <div className="d-flex align-items-center gap-2">
    <i class="bi bi-cash font-size-26 purple-color"></i>
    <div>
    <span style={{width:'100px', height:'21px'}} className="shimmer rounded d-block mb-1"></span>
    <span style={{width:'150px', height:'24px'}} className="shimmer rounded d-block"></span>
    </div>
    </div>
    <div className="d-flex align-items-center gap-2">
    <i class="bi bi-briefcase font-size-26 purple-color"></i>
    <div>
    <span style={{width:'100px', height:'21px'}} className="shimmer rounded d-block mb-1"></span>
    <span style={{width:'150px', height:'24px'}} className="shimmer rounded d-block"></span>
    </div>
    </div>
    <div className="d-flex align-items-center gap-2">
    <i class="bi bi-gem font-size-26 purple-color"></i>
    <div>
    <span style={{width:'100px', height:'21px'}} className="shimmer rounded d-block mb-1"></span>
    <span style={{width:'150px', height:'24px'}} className="shimmer rounded d-block"></span>
    </div>
    </div>
    <div className="d-flex align-items-center gap-2">
    <i class="bi bi-people font-size-26 purple-color"></i>
    <div>
    <span style={{width:'100px', height:'21px'}} className="shimmer rounded d-block mb-1"></span>
    <span style={{width:'150px', height:'24px'}} className="shimmer rounded d-block"></span>
    </div>
    </div>
    <div className="d-flex align-items-center gap-2">
    <i class="bi bi-shield-check font-size-26 purple-color"></i>
    <div>
    <span style={{width:'100px', height:'21px'}} className="shimmer rounded d-block mb-1"></span>
    <span style={{width:'150px', height:'24px'}} className="shimmer rounded d-block"></span>
    </div>
    </div>
    <div className="d-flex justify-content-md-end gap-2 align-self-md-center">
          <div className="options-vendor-storefront">
          <div className="favourite-option shimmer rounded" >
            {/* <Heart /> */}
          </div>
          </div>
          <div className="options-vendor-storefront">
            <div  className="share-option shimmer rounded">
            {/* <i class="fas fa-share-alt"></i> */}
            </div>
          </div>
          <div className="options-vendor-storefront">
          <div className="share-option shimmer rounded">
          {/* <i class="bi bi-facebook"></i> */}
          </div>
        </div>
        <div className="options-vendor-storefront">
          <div className="share-option shimmer rounded">
          {/* <i class="bi bi-whatsapp"></i> */}
          </div>
        </div>
      </div>
    </div>
      </div>
    </section>
    <section className="vendor-storefront-4 pt-3">
    <div className="container">
    <div className="row">
      <div className="col-md-8">
        <div id="about-content">
          <div class="border-top  mb-3 mt-0"></div>

        </div>
        <div id="what-place-offers">
  <div class="border-top mb-4 mt-5"></div>
  <h3 className="font-size-36 dm-font color-black mb-3">What This Place Offers</h3>
  <div className="row gy-3">

  </div>
</div>

        <div id="attributes">
          <div class="border-top  mb-4 mt-5"></div>
          <h3 className="font-size-36 dm-font color-black mb-3">Attributes</h3>

        </div>

        <div id="pricing-details">
          <div class="border-top  mb-4 mt-5"></div>
          <h3 className="font-size-36 playfair-font color-black mb-3">Pricing & Details</h3>
          <div className="shimmer rounded mb-3" style={{ height: '50px', width: '100%',borderRadius:'10px' }}></div>
          <div className="shimmer rounded mb-3" style={{ height: '50px', width: '100%',borderRadius:'10px' }}></div>
          <div className="shimmer rounded" style={{ height: '50px', width: '100%',borderRadius:'10px' }}></div>

        </div>
        <div id="faqs" >
          <div class="border-top  mb-4 mt-5"></div>
          <h3 className="font-size-36 playfair-font color-black mb-3">FAQ’s</h3>
          <div className="shimmer rounded mb-3" style={{ height: '70px', width: '100%',borderRadius:'10px' }}></div>
          <div className="shimmer rounded mb-3" style={{ height: '70px', width: '100%',borderRadius:'10px' }}></div>
          <div className="shimmer rounded mb-3" style={{ height: '70px', width: '100%',borderRadius:'10px' }}></div>
          <div className="shimmer rounded" style={{ height: '70px', width: '100%',borderRadius:'10px' }}></div>
        </div>
        <div id="video">
        <div class="border-top  mb-5 mt-5"></div>
        <h3 className="font-size-36 playfair-font color-black mb-3">Video</h3>
        <div className="shimmer rounded" style={{ height: '400px', width: '100%',borderRadius:'20px' }}></div>
        </div>

        <div id="location">
          <div class="border-top  mb-3 mt-5"></div>
          <h3 className="font-size-36 playfair-font color-black mb-3">Location</h3>
          <div className="shimmer rounded" style={{ height: '400px', width: '100%',borderRadius:'20px' }}></div>
        
          <div className="row mt-4">
            <div className="col-md-7 d-flex gap-2">
            <i class="bi bi-geo-alt-fill font-size-22 color-black d-flex"></i>
            <div>
              <h4 className="font-size-16 color-black fw-semibold mb-0">Address</h4>
              <p className="font-size-14 color-black"></p>
            </div>
            </div>
            <div className="col-md-3 d-flex gap-2">
            <i class="bi bi-globe-americas font-size-22 color-black d-flex"></i>
            <div>
              <h4 className="font-size-16 color-black fw-semibold mb-0">Country</h4>
              <p className="font-size-14 color-black"></p>
            </div>
            </div>
            <div className="col-md-2 d-flex gap-2">
            <i class="bi bi-mailbox font-size-22 color-black d-flex"></i>
            <div>
              <h4 className="font-size-16 color-black fw-semibold mb-0">Postcode</h4>
              <p className="font-size-14 color-black"></p>
            </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-4">
        <div className="vendor-storefront-sidebar">
          <div className="vendor-enquire-box wf-shadow border px-3 py-3 rounded-3 mt-3">
            <h3 className="font-size-26 playfair-font purple-color text-center mb-3">Send A Message To This Vendor</h3>
            <textarea className="enquire-box-text mb-3" cols="20" rows="7" placeholder="Type your Message.." ></textarea>
            <div className="text-end">
              <button  class="btn btn-lg wf-purple-btn wf-btn fw-bold text-uppercase py-2">Send Message</button>
            </div>
          </div>
          <div className="d-flex justify-content-between px-3 mt-3 mb-2">
            <p className="font-size-14 text-center purple-color cursor-pointer"><u>Claim this Listing</u></p>
            <p className="font-size-14 text-center purple-color cursor-pointer"><u>Report This Listing</u></p>
          </div>
          <div className="wf-shadow border px-3 py-3 rounded-3">
            <div id="contact-details">
              <h3 className="font-size-26 playfair-font pink-color mb-3">Contact Details</h3>
              <p className="font-size-16 color-black mb-0 ">
              <i class="bi bi-person pink-color font-size-18"></i>&nbsp;&nbsp; Contact Person: Weddified</p>
              <p className="font-size-16 color-black mb-0 ">
              <i class="bi bi-at pink-color font-size-18"></i>&nbsp;&nbsp; Email: <a className="pink-color fw-medium" ><u>info@weddified.com</u></a></p>
              <p className="font-size-16 color-black mb-0 ">
              <i class="bi bi-telephone pink-color font-size-18"></i>&nbsp;&nbsp; Phone:  <a className="pink-color fw-medium"><u>Click Here</u></a></p>
              <p className="font-size-16 color-black mb-0 ">
              <i class="bi bi-globe2 pink-color font-size-18"></i>&nbsp;&nbsp; Website: <a className="pink-color fw-medium"  rel="noopener noreferrer"><u>Click Here</u></a></p>
              <p className="font-size-16 color-black mb-0 ">
              <i class="bi bi-whatsapp pink-color font-size-18"></i>&nbsp;&nbsp; Whatsapp: <a className="pink-color fw-medium" rel="noopener noreferrer"><u>Click Here</u></a></p>
            </div>
              <div id="social-network">
                <h3 className="font-size-26 playfair-font purple-color mt-4 mb-3">Social Network</h3>
                <div className="d-flex gap-3 align-items-center">
                  <i className="bi bi-facebook purple-color font-size-22"></i>
                  <i className="bi bi-twitter-x purple-color font-size-22"></i>
                  <i className="bi bi-linkedin purple-color font-size-22"></i>
                  <i className="bi bi-instagram purple-color font-size-22"></i>
                  <i className="bi bi-youtube purple-color font-size-22"></i>
                  <i className="bi bi-pinterest purple-color font-size-22"></i>
                </div>
              </div>
          </div>

          </div>
      </div>
      </div>
      </div>
    </section>
    </>
  )
}

export default VendorDetailSkeleton