import { useState } from 'react';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import axios from "axios";
import i18n from '../../components/i18n';


const Footer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const date = new Date();
  const [countries, setcountries] = useState(null);
  const dispatch = useDispatch();
  const savedLanguage = localStorage.getItem('selectedLanguage');

  const Countries = async () => {
    try {
      setcountries(null);
      const response = await axios.get("user/getcountries",{params:{lang: savedLanguage}}) ;
      console.log('response12121212', response)
      setcountries(response?.data?.data);
     
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  useEffect(() => {
    let mount = true;
    if (mount) {
        Countries();
    }
    return () => {
      mount = false;
    };
  }, []);

  const currentLanguage = i18n.language;

  console.log('countries', countries)

  return (
    <>
    {/* <section className="pre-footer">
     <div className="container">
       <div className="pre-content">
       <h2 className="h2-title playfair-font color-white center-align mb-3">For More Details Contact Us Or Visit Our FAQ's Page</h2>
       <div class="row pre-footer-buttons gy-2">
         <div class="col-md-6 d-grid">
           <Link to="/contact"
               className="btn wf-btn-icon wf-pink-btn">
               Contact US  <i class="fas fa-arrow-right"></i>
               </Link>
               </div>
               <div class="col-md-6 d-grid">
               <Link to="/faqs"
               className="btn wf-btn-icon outline-btn">
               Frequently asked questions  <i class="fas fa-arrow-right"></i>
             </Link>
         </div>
       </div>
       </div>
     </div>
    </section> */}
     <footer className='border-top-light-color px-2'>
       <div className="container">
        <div className="row gx-md-5 footer-top mb-0 justify-content-md-between gy-4">
        <div className="col-lg-3 col-md-6">
        <img className="footer-logo mb-3" src={`${process.env.REACT_APP_FrontURL}/assets/images/${currentLanguage === 'ar' ? 'logo-white-arabic.png' : 'logo-white.svg'}`} alt="Weddified" width={currentLanguage === 'ar' ? '180px' : '220px'} />
        <p className="font-size-12 color-white">{currentLanguage === 'ar' ? 'ويدفايد هو مخطط زفاف رقمي رئيسي لك، مصمم لجلب اليسر والابتكار والفرح إلى رحلة تخطيط يومك الخاص. مع منصتنا المتقدمة التي تعتمد على الذكاء الاصطناعي، نقدم مجموعة من الأدوات المصممة خصيصًا لكل جانب من جوانب تنظيم الزفاف - بدءًا من اختيار الموقع وإدارة البائعين إلى تنسيق قائمة الضيوف وتتبع الميزانية. مهمتنا هي تحويل تخطيط الزفاف من مهمة صعبة إلى تجربة ممتعة، مساعدة الأزواج في صياغة حفل الزفاف الذي يحلمون به بسهولة وثقة.' :
         'Weddified is your premier digital wedding planner, designed to bring ease, innovation, and joy to the journey of planning your special day. With our advanced AI-powered platform, we offer a suite of tools tailored to every aspect of wedding organization — from venue selection and vendor management to guest list coordination and budget tracking. Our mission is to transform wedding planning from a daunting task into an enjoyable experience, helping couples craft their dream wedding with ease and confidence.'}</p>
         <div className="d-flex gap-3 align-items-center font-size-24 wf-social-media-links">
          <Link to="https://www.facebook.com/weddifiedofficial/"><i class="bi bi-facebook"></i></Link>
          <Link to="https://x.com/weddifiedcom"><i class="bi bi-twitter-x"></i></Link>
          <Link to="https://www.instagram.com/weddifiedofficial/"><i class="bi bi-instagram"></i></Link>
          <Link to="https://www.youtube.com/@weddifiedofficial"><i class="bi bi-youtube"></i></Link>
          <Link to="https://www.linkedin.com/company/weddifiedofficial/"><i class="bi bi-linkedin"></i></Link>
         </div>
         </div>
        <div className="col-lg-2 col-md-6">
          <h3 className="font-size-20 dm-font color-white">{t('UsefulLinks')}</h3>
        <ul className="footer-links">
           <li className="font-size-14 color-white"><Link to={`${process.env.REACT_APP_FrontURL}/about`} className="color-white">{t('AboutUs')}</Link></li>
           <li className="font-size-14 color-white"><Link to={`${process.env.REACT_APP_FrontURL}/contact`} className="color-white">{t('ContactUs')}</Link></li>
           <li className="font-size-14 color-white"><Link to={`${process.env.REACT_APP_FrontURL}/suppliers/venue`} className="color-white">{t('Venues')}</Link></li>
           <li className="font-size-14 color-white"><Link to={`${process.env.REACT_APP_FrontURL}/suppliers`} className="color-white">{t('WeddingSuppliers')}</Link></li>
           <li className="font-size-14 color-white"><Link to={`${process.env.REACT_APP_FrontURL}/ideas-inspiration`} className="color-white">{t('Ideas&Inspirations')}</Link></li>
           <li className="font-size-14 color-white"><Link className="color-white">{t('Careers')}</Link></li>
           <li className="font-size-14 color-white"><Link to={`${process.env.REACT_APP_FrontURL}/privacy`} className="color-white">{t('PrivacyPolicy')}</Link></li>
           <li className="font-size-14 color-white"><Link className="color-white">{t('TermsofUse')}</Link></li>
           <li className="font-size-14 color-white"><Link className="color-white">{t('CookiePolicy')}</Link></li>
         </ul>
        </div>
        <div className="col-lg-3 col-md-6">
        <h3 className="font-size-20 dm-font color-white">{t('DownloadWeddifiedMobileApp')}</h3>
        <p className="font-size-12 color-white">{currentLanguage === 'ar' ? 'انضموا إلينا في الترحيب بمستقبل تخطيط الزفاف مع ويدفايد، حيث يبدأ يومك المثالي.' : 'Join us in embracing the future of wedding planning with Weddified, where your perfect day starts.'}</p>
        <div class="d-flex gap-2">
          <div >
          <a href='https://apps.apple.com/pk/app/weddified/id6503425450'><img className="store-app mb-2 w-100" src="../assets/images/app-store-img.svg" alt="apple store" /></a>
          <a href='https://play.google.com/store/apps/details?id=com.weddified.app'><img className="store-app w-100" src="../assets/images/play-store-img.svg" alt="google play store" /></a>
          </div>
          {/* <img className="qr-code" height="110" src="../assets/images/weddified-qr-code.svg" alt="weddified qr code" /> */}
        </div>
        
        </div>
        <div className="col-lg-3 col-md-6">
        <h3 className="font-size-20 dm-font color-white">{t('Countries')}</h3>
        <ul className="list-unstyled footer-links mb-4">
        {countries?.length>0&&countries?.map(country => (
          <li className="">
            <Link to={`${process.env.REACT_APP_FrontURL}/suppliers/${country.slug}`} className="d-flex align-items-center gap-2">
            <img src={`${process.env.REACT_APP_IMGURL}${country.imageName}`} style={{width:'25px',height:'15px',objectFit:'cover'}} alt="" className="" />
            <p className="font-size-12 mb-0 color-white">{country.name}</p>
            </Link>
          </li>
        ))}
        </ul>
        <div class="d-flex gap-3">
        <img className="vision-2030-logo" style={{width:'40%', height:'77px'}} src="../assets/images/vision-2030-logo.svg" alt="Vision 2030 Logo" />
        <img className="unsdg-logo" style={{width:'33%', height:'77px'}} src="../assets/images/unsdg-logo.svg" alt="UNSDG Logo" />
        </div>
        </div>
        <div className="col-md-12 pt-5">
        <p className="font-size-14 color-white">{`© ${date.getFullYear()} `}<Link to={process.env.REACT_APP_FrontURL} className="color-white">{t('Weddified')}</Link>. {t('Allrightsreserved')}.</p>
        </div>
        </div>
       </div>
     </footer>
     </>
  )
}

export default Footer