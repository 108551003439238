import React , {useEffect} from 'react'
import Header from './HeaderLight'
import Footer from './Footer'
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import i18n from '../../components/i18n';

const AboutUs = () => {
  const { t } = useTranslation();
  const currentLanguage = i18n.language;
  useEffect(() => {
  window.scrollTo({ top: 0, left: 0})
}, []);

  return (
    <>
    <Helmet>
        <title>About Weddified - Your Trusted Wedding Planning Partner</title>
        <meta
          name="description"
          content="Explore the journey of Weddified, the innovative wedding planning app. Get to know our team, our values, and our dedication to simplifying your wedding planning experience."
        />
        <meta
          name="keywords"
          content="Weddified, wedding planning"
        />
      </Helmet>

<section class="about-hero about-bg">
<Header theme="light" />
  <div class="container">
    <div class="about-hero-body">
      <h1 class="font-size-46 color-white dm-font">{t('AboutWeddified')}</h1>
        <p className="font-size-16 color-white about-content mb-0">{currentLanguage === 'ar' ? 'الزفاف هو مناسبة جميلة وفرحة، ولكن يمكن أن يكون أيضًا الكثير من العمل للتخطيط. لهذا السبب، نحن متحمسون للإعلان عن إطلاق موقعنا الجديد لتخطيط الزفاف، ويدفايد، للأشخاص الذين يخططون لحفل زفافهم في الإمارات العربية المتحدة.' : 
        "Weddings are a beautiful and joyous occasion, but they can also be a lot of work to plan. That's why we're excited to announce the launch of our new wedding planning website, Weddified, for people who are planning their wedding in the UAE."}</p>
    </div>
  </div>
<div className="home-hero-overlay"></div>
</section>
<section className="about-section-1 py-5">
  <div className="container">
    <p className="font-size-16 color-black fw-medium text-center">{currentLanguage === 'ar' ? 'موقعنا يقدم مجموعة متنوعة من الميزات لمساعدتك في تخطيط حفل زفافك المثالي، بما في ذلك:' : 'Our website offers a variety of features to help you plan your perfect wedding, including:'}</p>
    <div className="row gx-5 gy-4">
      <div className="col-lg-3 col-md-6">
        <div className="d-flex grey-border px-3 py-3 rounded">
          {/* <img width="60" src="../assets/images/task-management-1.svg" alt="" className="about-us-icon-img" /> */}
          <p className="font-size-16 color-black m-0 fw-semibold text-center">{currentLanguage === 'ar' ? 'قائمة شاملة لتخطيط حفل الزفاف.' : 'A comprehensive wedding planning checklist.'}</p>
        </div>
      </div>
      <div className="col-lg-3 col-md-6">
        <div className="d-flex grey-border px-3 py-3 rounded">
          {/* <img width="60" src="../assets/images/task-management-2.svg" alt="" className="about-us-icon-img" /> */}
          <p className="font-size-16 color-black m-0 fw-semibold text-center">{currentLanguage === 'ar' ? '"دليل لبائعي خدمات الزفاف في الإمارات العربية المتحدة."' : 'A directory of wedding vendors in the UAE.'}</p>
        </div>
      </div>
      <div className="col-lg-3 col-md-6">
        <div className="d-flex grey-border px-3 py-3 rounded">
          {/* <img width="60" src="../assets/images/task-management-1.svg" alt="" className="about-us-icon-img" /> */}
          <p className="font-size-16 color-black m-0 fw-semibold text-center">{currentLanguage === 'ar' ? 'القدرة على حجز بائعي الخدمات عبر الإنترنت.' : 'The ability to book vendors online.'}</p>
        </div>
      </div>
      <div className="col-lg-3 col-md-6">
        <div className="d-flex grey-border px-3 py-3 rounded">
          {/* <img width="60" src="../assets/images/task-management-2.svg" alt="" className="about-us-icon-img" /> */}
          <p className="font-size-16 color-black m-0 fw-semibold text-center">{currentLanguage === 'ar' ? 'مدونة لتخطيط الزفاف تحتوي على نصائح ومشورة.' : 'A wedding planning blog with tips and advice.'}</p>
        </div>
      </div>
    </div>
  </div>
</section>
<section className="about-section-2 pb-5">
  <div className="container">
    <div className="row gy-4">
      <div className="col-lg-8 col-md-12 order-lg-1 order-2 align-self-center">
        <h2 className="font-size-26 dm-font color-black mb-3">{t('OurStory')}</h2>
        <p className="font-size-16 color-black mb-4">
        {currentLanguage === 'ar' ? 'تم استلهامنا لإنشاء هذا الموقع لأننا نعلم مدى إجهاد تخطيط حفل الزفاف يمكن أن يكون. أردنا إنشاء مصدر يجعل من السهل على الأزواج تخطيط حفل زفافهم الحلم دون الحاجة إلى قضاء ساعات على جوجل أو الاتصال بمختلف البائعين.' : 'We were inspired to create this website because we know how stressful wedding planning can be. We wanted to create a resource that would make it easier for couples to plan their dream wedding without having to spend hours on Google or calling around to different vendors.'}
          <br /> <br />
          {currentLanguage === 'ar' ? 'أردنا أيضًا إنشاء موقعًا يكون مخصصًا لسوق الإمارات العربية المتحدة. نعلم أن هناك تحديات وفرصًا فريدة تأتي مع تخطيط حفل زفاف في الإمارات، وأردنا التأكد من أن موقعنا يعكس ذلك.' : 'We also wanted to create a website that would be personalized to the UAE market. We know that there are some unique challenges and opportunities that come with planning a wedding in the UAE, and we wanted to make sure that our website reflected that.'}
        </p>
        <h2 className="font-size-26 dm-font color-black mb-3">{t('OurCommitmenttoYou')}</h2>
        <p className="font-size-16 color-black">
        {currentLanguage === 'ar' ? 'نحن ملتزمون بتوفير أفضل تجربة ممكنة لتخطيط حفل زفافك. سنعمل معك لإنشاء خطة مخصصة تناسب ميزانيتك ورؤيتك. سنتأكد أيضًا من أنك على اتصال مع البائعين المناسبين حتى يكون يوم زفافك كل ما حلمت به.' : "We are committed to providing you with the best possible wedding planning experience. We will work with you to create a personalized plan that fits your budget and your vision. We will also make sure that you are in touch with the right vendors so that your wedding day is everything you've dreamed of."}
          <br /> <br />
          {currentLanguage === 'ar' ? 'شكرًا لزيارتكم لموقعنا. نأمل أن تجدوا فيه المساعدة التي تحتاجونها!' : 'Thank you for visiting our website. We hope you find it helpful!'}</p>
      </div>
      <div className="col-lg-4 col-md-12 order-lg-2 order-1">
        <img src="../assets/images/about-us-img-1.jpg" className="about-us-img w-100 rounded-4" alt="" />
      </div>
    </div>
  </div>
</section>
  
<Footer />
</>
  )
}

export default AboutUs