import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate,useLocation } from "react-router-dom";
import { AddReview } from "../../store/slices/userSlice";
import { toast } from "react-toastify";
import moment from "moment";
import jwtDecode from 'jwt-decode';
import { Star } from 'lucide-react';
import Header from "../Public/HeaderLight";
import Footer from "../Public/Footer";


const ReviewBooking = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
 

    const [id,setid]=useState("")
    const [eventid,seteventid]=useState("")
    const [storeid,setstoreid]=useState("")
    const [name,setname]=useState("")
    const [email,setemail]=useState("")
    const [comments,setcomments]=useState("")
    const [value_for_money,setvalue_for_money]=useState(0)
    const [flexibility,setflexibility]=useState(0)
    const [professionalism,setprofessionalism]=useState(0)
    const [responsive,setresponsive]=useState(0)
    const [quality,setquality]=useState(0)
  


    useEffect(() => {
      setid(location.state._id)
      seteventid(location.state.user_event._id)
      setstoreid(location.state.vendor_event._id)
      }, []);


      const handleSave = async (e) => {
        e.preventDefault();
    
    
    
        try {
    
            const requestData = {
                id: id, storeid: storeid, eventid: eventid, quality: quality, responsive: responsive, professionalism: professionalism, flexibility: flexibility, value_for_money: value_for_money, comments: comments               
              };
            
           const nav= await dispatch(
            AddReview(requestData)
              ).unwrap();
              
           if(nav){
            navigate('/bookings')
           }   
           
          
          
        } catch (rejectedValueOrSerializedError) {
          console.log(rejectedValueOrSerializedError);
        }
      };

console.log('responsive', location.state)


      
  return (
    <>
    {/* <Header theme="dark" /> */}
    <section className="wf-request-review px-md-5 pb-5 pt-2">
        <div className="container-fluid">
        <h1 className="font-size-46 playfair-font color-black mb-3">Request Review</h1>
        <div className="row gy-3">
        <div className="col-md-4">
          <p className="font-size-24 dm-font pink-color mb-0">Event Name</p>
          <p className="font-size-18 mb-0">{location.state.user_event.event_name}</p>
        </div>
        <div className="col-md-4">
          <p className="font-size-24 dm-font pink-color mb-0">Event Date</p>
          <p className="font-size-18 mb-0">{moment(location.state.user_event.event_date).format("DD MMM YYYY")}</p>
        </div>
        <div className="col-md-4">
          <p className="font-size-24 dm-font pink-color mb-0">Supplier Name</p>
          <p className="font-size-18 mb-0">{location.state.vendor_event.store_name}</p>
        </div>
        
        <div className="col-md-12">
        <div class="form-floating wf-textarea wf-textarea-101">
        <textarea value={comments} onChange={(e)=>setcomments(e.target.value)} class="form-control border-0" placeholder="Leave a comment here" id="review-comment" style={{height:'150px'}}></textarea>
        <label for="review-comment">Comments</label>
        </div>
        </div>
        <div className="col-md-6">
            <p className="font-size-18 dm-font pink-color mb-2">Quality</p>
        <div className="star-rating">
          {[1, 2, 3, 4, 5].map((index) => (
            <span
              key={index}
              onClick={() => setquality(index)}
              className={index <= quality ? 'star-filled' : 'star-empty'}
            >
              {index <= quality ? (
                <Star size={24} stroke="#ffc107" fill="#ffc107" />
              ) : (
                <Star size={24} stroke="#ffc107"  className="cursor-pointer" />
              )}
            </span>
          ))}
        </div>
        </div>
        <div className="col-md-6">
            <p className="font-size-18 dm-font pink-color mb-2">Responsive</p>
        <div className="star-rating">
          {[1, 2, 3, 4, 5].map((index) => (
            <span
              key={index}
              onClick={() => setresponsive(index)}
              className={index <= responsive ? 'star-filled' : 'star-empty'}
            >
              {index <= responsive ? (
                <Star size={24} stroke="#ffc107" fill="#ffc107" />
              ) : (
                <Star size={24} stroke="#ffc107"  className="cursor-pointer" />
              )}
            </span>
          ))}
        </div>
        </div>
        <div className="col-md-6">
            <p className="font-size-18 dm-font pink-color mb-2">Flexibility</p>
        <div className="star-rating">
          {[1, 2, 3, 4, 5].map((index) => (
            <span
              key={index}
              onClick={() => setflexibility(index)}
              className={index <= flexibility ? 'star-filled' : 'star-empty'}
            >
              {index <= flexibility ? (
                <Star size={24} stroke="#ffc107" fill="#ffc107" />
              ) : (
                <Star size={24} stroke="#ffc107" className="cursor-pointer" />
              )}
            </span>
          ))}
        </div>
        </div>
        <div className="col-md-6">
            <p className="font-size-18 dm-font pink-color mb-2">Value for Money</p>
        <div className="star-rating">
          {[1, 2, 3, 4, 5].map((index) => (
            <span
              key={index}
              onClick={() => setvalue_for_money(index)}
              className={index <= value_for_money ? 'star-filled' : 'star-empty'}
            >
              {index <= value_for_money ? (
                <Star size={24} stroke="#ffc107" fill="#ffc107" />
              ) : (
                <Star size={24} stroke="#ffc107" className="cursor-pointer"  />
              )}
            </span>
          ))}
        </div>
        </div>
        <div className="col-md-6">
            <p className="font-size-18 dm-font pink-color mb-2">Professionalism</p>
        <div className="star-rating">
          {[1, 2, 3, 4, 5].map((index) => (
            <span
              key={index}
              onClick={() => setprofessionalism(index)}
              className={index <= professionalism ? 'star-filled' : 'star-empty'}
            >
              {index <= professionalism ? (
                <Star size={24} stroke="#ffc107" fill="#ffc107" />
              ) : (
                <Star size={24} stroke="#ffc107"  className="cursor-pointer" />
              )}
            </span>
          ))}
        </div>
        </div>

        <div className="col-md-6 text-end align-self-end">
          <button onClick={handleSave} class="btn btn-lg wf-purple-btn wf-btn fw-bold text-uppercase py-2">Send</button>
        </div>
        </div>
        </div>
    </section>
    {/* <Footer /> */}
    </>
  )
}

export default ReviewBooking