import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AddReview } from "../../store/slices/userSlice";
import { toast } from "react-toastify";
import jwtDecode from 'jwt-decode';
import { Star } from 'lucide-react';


const RequestReview = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
 

    const [id,setid]=useState("")
    const [name,setname]=useState("")
    const [email,setemail]=useState("")
    const [comments,setcomments]=useState("")
    const [value_for_money,setvalue_for_money]=useState(0)
    const [flexibility,setflexibility]=useState(0)
    const [professionalism,setprofessionalism]=useState(0)
    const [responsive,setresponsive]=useState(0)
    const [quality,setquality]=useState(0)
  


    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const token = searchParams.get('token');
        console.log('tokennnnnnnn', token)
        if (token) {
          
          try {
           
            
            const decodedToken = jwtDecode(token);
            console.log('decodedToken', decodedToken)
            setid(decodedToken?.id)
          
          } catch (error) {
            console.error('Error decoding token:', error);
          }
    }
      }, []);


      const handleSave = async (e) => {
        e.preventDefault();
    
    
    
        try {
    
            const requestData = {
                id: id, name: name, email: email, quality: quality, responsive: responsive, professionalism: professionalism, flexibility: flexibility, value_for_money: value_for_money, comments: comments
                
              };
            
           const nav= await dispatch(
            AddReview(requestData)
              ).unwrap();
              
           if(nav){
            navigate(`${process.env.REACT_APP_FrontURL}`)
           }   
           
          
          
        } catch (rejectedValueOrSerializedError) {
          console.log(rejectedValueOrSerializedError);
        }
      };

console.log('responsive', responsive)


      
  return (
    <section className="request-review py-5">
        <div className="container">
        <h1 className="font-size-46 playfair-font color-black mb-3">Request Review</h1>
        <div className="row gy-4 gx-4">
    <div className="col-md-6">
    <div class="form-floating wf-input wf-input-2">
      <input value={name} onChange={(e)=>setname(e.target.value)} type="text" class="form-control" id="Contact-person" placeholder="name" 
       autocomplete="name" required />
      <label for="Contact-person" className="text-uppercase">Name</label>
    </div>
    </div>
    <div className="col-md-6">
    <div class="form-floating wf-input wf-input-2">
      <input value={email} onChange={(e)=>setemail(e.target.value)} type="email" class="form-control" id="Phone" placeholder="Phone" 
       autocomplete="Phone" required />
      <label for="Phone" className="text-uppercase">Email</label>
    </div>
    </div>
    <div className="col-md-12">
    <div class="form-floating wf-textarea">
    <textarea value={comments} onChange={(e)=>setcomments(e.target.value)} class="form-control border-0" placeholder="Leave a comment here" id="floatingTextarea2" style={{height:'100px'}}></textarea>
    <label for="floatingTextarea2">Comments</label>
    </div>
    </div>
    <div className="col-md-6">
        <p className="font-size-12">Quality</p>
    <div className="star-rating">
      {[1, 2, 3, 4, 5].map((index) => (
        <span
          key={index}
          onClick={() => setquality(index)}
          className={index <= quality ? 'star-filled' : 'star-empty'}
        >
          {index <= quality ? (
            <Star size={24} stroke="#ffc107" fill="#ffc107" />
          ) : (
            <Star size={24} stroke="#ffc107" />
          )}
        </span>
      ))}
    </div>
    </div>
    <div className="col-md-6">
        <p className="font-size-12">Responsive</p>
    <div className="star-rating">
      {[1, 2, 3, 4, 5].map((index) => (
        <span
          key={index}
          onClick={() => setresponsive(index)}
          className={index <= responsive ? 'star-filled' : 'star-empty'}
        >
          {index <= responsive ? (
            <Star size={24} stroke="#ffc107" fill="#ffc107" />
          ) : (
            <Star size={24} stroke="#ffc107"  />
          )}
        </span>
      ))}
    </div>
    </div>
    <div className="col-md-6">
        <p className="font-size-12">Flexibility</p>
    <div className="star-rating">
      {[1, 2, 3, 4, 5].map((index) => (
        <span
          key={index}
          onClick={() => setflexibility(index)}
          className={index <= flexibility ? 'star-filled' : 'star-empty'}
        >
          {index <= flexibility ? (
            <Star size={24} stroke="#ffc107" fill="#ffc107" />
          ) : (
            <Star size={24} stroke="#ffc107" />
          )}
        </span>
      ))}
    </div>
    </div>
    <div className="col-md-6">
        <p className="font-size-12">Value for Money</p>
    <div className="star-rating">
      {[1, 2, 3, 4, 5].map((index) => (
        <span
          key={index}
          onClick={() => setvalue_for_money(index)}
          className={index <= value_for_money ? 'star-filled' : 'star-empty'}
        >
          {index <= value_for_money ? (
            <Star size={24} stroke="#ffc107" fill="#ffc107" />
          ) : (
            <Star size={24} stroke="#ffc107"  />
          )}
        </span>
      ))}
    </div>
    </div>
    <div className="col-md-6">
        <p className="font-size-12">Professionalism</p>
    <div className="star-rating">
      {[1, 2, 3, 4, 5].map((index) => (
        <span
          key={index}
          onClick={() => setprofessionalism(index)}
          className={index <= professionalism ? 'star-filled' : 'star-empty'}
        >
          {index <= professionalism ? (
            <Star size={24} stroke="#ffc107" fill="#ffc107" />
          ) : (
            <Star size={24} stroke="#ffc107"  />
          )}
        </span>
      ))}
    </div>
    </div>

    <div className="col-md-6 text-end align-self-end">
      <button onClick={handleSave} class="btn btn-lg wf-purple-btn wf-btn fw-bold text-uppercase py-2">Send</button>
    </div>
    </div>
        </div>
    </section>
  )
}

export default RequestReview