import React from 'react'

const BlogTemplateSkeleton = () => {
  return (
    <>
    <section className="single-blog-1">
        <div className="container">
          <div class="border-top  mb-3 mt-2"></div>
          <div className="row">
            <div className="col-6">
              <div className="go-back-btn"><i class="fas fa-arrow-left"></i> &nbsp;Go Back</div>
            </div>
            <div className="col-6 d-flex justify-content-end gap-2" >
              <div className="options-vendor-storefront">
                <div className="share-option">
                  <i class="bi bi-hand-thumbs-up"></i>
                </div>
              </div>
              <div className="options-vendor-storefront">
                <div className="share-option">
                <i class="fas fa-share-alt"></i>
                </div>
              </div>
              <div className="options-vendor-storefront">
                <div className="share-option">
                <i class="bi bi-facebook"></i>
                </div>
              </div>
              <div className="options-vendor-storefront">
                <div className="share-option">
                <i class="bi bi-whatsapp"></i>
                </div>
              </div>
            </div>         
          </div>
          <div>
            <div className="single-blog-image mt-3 mb-3 shimmer"></div>
            <div style={{height:'55px'}} className="w-100 shimmer mb-3"></div>
            <div className="d-flex align-items-center flex-wrap gap-4 mb-3 before-css">
              <p className="font-size-20 color-black fw-semibold mb-0">Post by <span className="shimmer" style={{width:'70px',height:'18px',display:'inline-block'}}></span></p>
              <p className="font-size-20 color-black fw-semibold mb-0"><span className="shimmer" style={{width:'100px',height:'18px',display:'inline-block'}}></span></p>
              <p className="font-size-20 color-black fw-semibold mb-0">Published on{" "}<span className="shimmer" style={{width:'100px',height:'18px',display:'inline-block'}}></span> </p>
              <p className="font-size-20 color-black fw-semibold mb-0">Read Mins{" "}<span className="shimmer" style={{width:'20px',height:'18px',display:'inline-block'}}></span></p>
            </div>
          </div>
        </div>
      </section>
      <section className="single-blog-2">
        <div className="container">
          <div className="row">
            <div className="col-md-8" >
              <div className="blog-content">
              {new Array(18).fill(null).map((_, index) => (
              <div style={{height:'20px'}} className="w-100 shimmer mb-2"></div>
              ))}
              </div>
            </div>
            <div className="col-md-4 position-relative overflow-hidden">
              <div className="light-purple-bg p-3 rounded">
                <div className="d-flex gap-3 align-items-start">
                  <img className="w-auto" src="../assets/images/w-logo.svg" alt="" />
                  <div className="download-app-text">
                    <p class="font-size-20 color-black mb-0"><b>Download the Weddified App</b></p>
                    <p class="font-size-16 color-black mb-1">Easily plan your wedding anytime, anywhere with the Weddified App.</p>
                    <div className="row">
                      <div className="col-6">
                        <img class="apple-store-img w-100" src="../assets/images/app-store-img.svg" alt="" />
                      </div>
                      <div className="col-6">
                        <img class="google-store-img w-100" src="../assets/images/play-store-img.svg" alt=""/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="ad-box mt-3">
                <h2 className="font-size-36 color-black playfair-font text-center">Ad Box</h2>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default BlogTemplateSkeleton