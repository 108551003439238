import React from 'react'

const ButtonSkeleton = () => {
  return (
    <>
    <div className='shimmer rounded-3' style={{height:'40px', width:'64px'}}></div>
    </>
  )
}

export default ButtonSkeleton