import React, { useEffect, useState, useRef } from "react";
import Header from "./HeaderLight";
import Footer from "./Footer";
import cheerio from 'cheerio';
import { Pin } from "lucide-react";
import { PinOff } from "lucide-react";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from "react-redux";
import {
  Likes,
  AddComments,
  PinUnPin,
} from "../../store/slices/userSlice";
import moment from "moment";
import DOMPurify from 'dompurify';
import {
  getProfile
} from "../../store/slices/userSlice";
import axios from "axios";
import parse from "html-react-parser";
import BlogCardSkeleton from "../LoadingSkeleton/BlogCardSkeleton";
import BlogTemplateSkeleton from "../LoadingSkeleton/BlogTemplateSkeleton";
import { useTranslation } from "react-i18next";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faFacebook, faWhatsapp } from '@fortawesome/free-brands-svg-icons';


const BlogDetails1 = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const profile = useSelector(getProfile);

  // const data= location?.state?.data

  const [load, setload] = useState(false);
  const [blogs, setblogs] = useState([]);
  const [data, setdata] = useState([]);
  const [comment, setcomment] = useState("");
  const [readTime, setreadTime] = useState("");
  const savedLanguage = localStorage.getItem('selectedLanguage');
  

  const Details = async (blog_name) => {
    try {
      setblogs([]);
      setload(true);
      const response = await axios.get(`vendorpage/getlatestblogs`,{
        params:{
          name: blog_name,
          user_id: profile?._id,
          lang: savedLanguage
        }
      });
      console.log("abc", response);
      
      setblogs(response?.data?.data);
      setload(false);
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };


  const Detail1 = async (blog_name) => {
    try {
      setdata([]);
      setload(true);
      console.log('firsrrrrt',blog_name)
      const response = await axios.post(`vendorpage/getblogsdetails`,{slug: blog_name,user_id: profile?._id, lang: savedLanguage});
      setdata(response?.data?.data);
      const sanitizedText = DOMPurify.sanitize(response?.data?.data?.description, { USE_PROFILES: { html: true } }); 
      calculateReadingTime(sanitizedText)
      setload(false);
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };


  const IncrementBlogView = async (blog_id) => {
    try {
      await axios.post(`vendorpage/incrementblogview`, {
        blog_id: blog_id,
      });
    } catch (error) {
      console.error("Error incrementing view:", error);
    }
  };


  const abcd=()=>{
    
    const p= location.pathname.split('/')
    const p1= decodeURIComponent(p[p.length-1])



  const originalTitle = p1


console.log('originalTitleeee', originalTitle)
  

  Details(originalTitle);
  Detail1(originalTitle);

  // localStorage.removeItem('originalTitle');
  console.log("ttttttttt",originalTitle)
  }



  
  useEffect(() => {

    
    
    window.scrollTo({ top: 0, left: 0})
    abcd()
    
  }, []);

  


 

  const handlePin = async (image, id, title) => {
    console.log("id", id);
    console.log("image", image);
  

     const abc= await dispatch(
      PinUnPin({ blog_id: id, imageName: image, user_id: profile?._id })
    ).unwrap();
  
  if(abc){  
  const $ = cheerio.load(data.description);

  
  const imgTag = $(`img[src="${image}"]`);

  
  if (imgTag.length > 0) {
   
    const isPinned = imgTag.attr('data-ispinned') === 'true';
    imgTag.attr('data-ispinned', isPinned ? 'false' : 'true');

   
    setdata((prevData) => ({
      ...prevData,
      description: $.html(),
    }));
  }}
  };
    
  

  const handleLikes = async ( blog_id, title ) => {

    await dispatch(
      Likes({ blog_id: blog_id, user_id: profile?._id })
    ).unwrap();
   
      if (data._id === blog_id) {
        
        const newData = { ...data, likes: data.likes==0?1:0, totalLikes: data.likes==1?data.totalLikes-1:data.totalLikes+1 };
  
        // Update the state with the new object
        setdata(newData);
   
      
    }
  };

  

  // Calculate reading time
  const calculateReadingTime = (text) => {
    const wordsPerMinute = 225; 
    const textWithoutImages = text.replace(/<img[^>]*>.*?<\/img>/gi, '');
    const words = textWithoutImages.split(/\s+/).length;
    const minutes = words / wordsPerMinute;
    setreadTime(Math.ceil(minutes));
  };

  const handleComments = async ( blog_id, title ) => {
    
    if (comment == "") {
      toast.error("Comment cannot be empty");
    } else {

    await dispatch(
      AddComments({ blog_id: blog_id, comment: comment, user_id: profile?._id })
    ).unwrap();

    
      setcomment("")
      
      if (data._id === blog_id) {
        const newComment = { blog_id: blog_id, comment: comment, user_id: profile?._id };
        
        
        const newData = { ...data, comments: [...data.comments, newComment] };
      
       
        setdata(newData);
      }
      
    }
    
  };

  console.log("data", data);

  
  const sanitizedHtml = DOMPurify.sanitize(data?.description);
  
 
  const parsedHtml = parse(sanitizedHtml, {
    replace: (domNode) => {
      if (domNode.type === 'tag' && domNode.name === 'img') {
        const isPinned = domNode.attribs['data-ispinned'] =='false';
        console.log('isPinned', isPinned)
        const iconComponent = isPinned ? <Pin /> : <PinOff />;
        const imgSrc = domNode.attribs.src;

        return (
          <div className="blog-pin-image">
            <img src={imgSrc} alt="Image" />
            {profile?(<span
              className="pin-blog"
              onClick={() => handlePin(imgSrc, data?._id, data?.title)}
            >
              {iconComponent}
            </span>):null}
          </div>
        );
      } else {
        return domNode;
      }
    },
  });

  const shareCurrentPage = async () => {
    try {
      if (navigator.share) {
        
        await navigator.share({
          title: 'Share this link',
          url: window.location.href,
        });
      } else {
        
        const fallbackUrl = `${process.env.REACT_APP_FrontURL}/share?url=${encodeURIComponent(window.location.href)}`;
        window.open(fallbackUrl, '_blank');
      }
    } catch (error) {
      console.error('Error sharing:', error);
    }
  };

  const shareOnFacebook = () => {
    const url = window.location.href;
    const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`;
    window.open(facebookShareUrl, '_blank');
  };

  const shareOnWhatsApp = () => {
    const url = window.location.href;
    const whatsappShareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(url)}`;
    window.open(whatsappShareUrl, '_blank');
  };

 

  console.log('readTime', readTime)

  return (
    <>
    <Helmet>
        <title>{data?.title}</title>
        <meta
          name="description"
          content={data?.excerpt}
        />
        <meta
          name="keywords"
          content="Weddified, wedding planning"
        />
      </Helmet>
    {/* {load&&<Spinner />} */}
    <Header theme="dark" />
    {load ? (<BlogTemplateSkeleton />) : (<>
      <section className="single-blog-1">
        <div className="container">
          <div class="border-top  mb-3 mt-2"></div>
          <div className="row">
            <div className="col-6">
              <div className="go-back-btn" onClick={()=>navigate("/ideas-inspiration")}><i class="fas fa-arrow-left"></i> &nbsp;{t('GoBack')}</div>
            </div>
            <div className="col-6 d-flex justify-content-end gap-2" >
              <div onClick={() => handleLikes( data?._id, data?.title )} className="options-vendor-storefront">
                <div className="share-option">
                  {data?.likes==0?(<i class="bi bi-hand-thumbs-up"></i>):(
                  <i class="bi bi-hand-thumbs-up-fill pink-color"></i>)}
                </div>
              </div>
              <div onClick={shareCurrentPage} className="options-vendor-storefront">
                <div className="share-option">
                <i class="fas fa-share-alt"></i>
                </div>
              </div>
              <div className="options-vendor-storefront">
                <div onClick={shareOnFacebook} className="share-option">
                <i class="bi bi-facebook"></i>
                </div>
              </div>
              <div className="options-vendor-storefront">
                <div onClick={shareOnWhatsApp} className="share-option">
                <i class="bi bi-whatsapp"></i>
                </div>
              </div>
            </div>         
          </div>
          <div>
            <img src={`${process.env.REACT_APP_IMGURL}${data?.image}`} className="single-blog-image mt-3 mb-3"/>
            <h1 style={{lineHeight:'1.2'}} className="h2-title playfair-font color-black mb-md-0 mb-2">{data?.title}</h1>
            <div className="d-flex flex-wrap align-items-center gap-4 mb-3 before-css">
              <p className="font-size-20 color-black fw-semibold mb-0">{t('Postby')} <span className="pink-color">{data?.author}</span></p>
              <p className="font-size-20 color-black fw-semibold mb-0"><a className="pink-color" href={`/suppliers/${data?.category_id?.name.replace(/\s/g, '-').replace(/&/g, "and").toLowerCase()}`}>{data?.category_id?.name}</a></p>
              <p className="font-size-20 color-black fw-semibold mb-0">{t('Publishedon')}{" "}<span className="pink-color">{moment(data?.createdAt).format("D MMMM YYYY")}</span> </p>
              <p className="font-size-20 color-black fw-semibold mb-0">{t('ReadMins')}{" "}<span className="pink-color">{readTime}</span></p>
            </div>
          </div>
        </div>
      </section>
      <section className="single-blog-2">
        <div className="container">
          <div className="row gy-3">
            <div className="col-lg-8 col-md-12" >
              <div className="blog-content">
                {parsedHtml}
              </div>
              {profile?(<div className="vendor-enquire-box wf-shadow border px-3 py-3 rounded-3 mt-3 mb-3">
      <h3 className="font-size-26 playfair-font purple-colormb-3">{t('CommentBox')}</h3>
      <textarea
        maxLength={300}
        onChange={(e)=>setcomment(e.target.value)}
        value={comment}
        className="enquire-box-text mb-3"
        cols="20"
        rows="5"
        placeholder="Type your Message.."
      ></textarea>
      <div className="text-end">
        <p className="font-size-12 pink-color mb-2">
          {comment === "" ? 0 : comment.length}/300 words
        </p>
        <button
          className="btn btn-lg wf-purple-btn wf-btn fw-bold text-uppercase py-2"
          onClick={()=>handleComments(data?._id,data?.title)}
        >
          Send
        </button>
      </div>
    </div>):null}
    {data?.comments?.length>0?(<><h3 className="font-size-36 playfair-font color-black mb-3">{t('Comments')} ({data?.comments?.length})
              </h3>
              <div className="row gy-4">
              {data?.comments?.map((d) => (
              <div className="col-md-12">
                <div className="d-flex align-items-center justify-content-between mb-3">
                <div className="d-flex align-items-center gap-3">
                <img width="50" height="50" alt="avatar" src={d?.user_id?.imageName?`${process.env.REACT_APP_IMGURL}${d?.user_id?.imageName}`:"https://via.placeholder.com/40x40"} class="rounded-circle" />
                <div>
                <p className="font-size-16 fw-medium color-black mb-0">{d?.user_id?.name}</p>
                <p className="font-size-12 color-black mb-0">{moment(d.createdAt).format('DD MMMM YYYY')}</p>
                </div>
                </div>
                </div>
                <p className="font-size-16 color-black mb-0 overflow-hidden">{d.comment}</p>
              </div>
            ))}
              </div></>):null}
            </div>
            <div className="col-lg-4 col-md-12 position-relative overflow-hidden">
            <div id="download-app" className="mb-3">
          <h2 className="font-size-26 dm-font color-black mb-2">{t('DownloadourMobileApp')}</h2>
            <p className="font-size-14 purple-color mb-2">{t('StartPlanningYourDreamWeddingToday')}!</p>
            <div className="wf-shadow-2 white-bg rounded py-3 px-4">
            <img class="img-fluid w-100 mb-4" src={`${process.env.REACT_APP_FrontURL}/assets/images/download-app-graphics.svg`} alt=""/>
            <div className="d-flex align-items-center gap-3 mb-3 justify-content-center">
          <img width={150} class="google-store-img img-fluid " src={`${process.env.REACT_APP_FrontURL}/assets/images/play-store-img.svg`} alt=""/>
            <img width={150} class="apple-store-img img-fluid" src={`${process.env.REACT_APP_FrontURL}/assets/images/app-store-img.svg`} alt=""/>
          </div>
          <h2 className="font-size-20 color-black mb-0 text-center dm-font"><a className="color-black"><u>{t('Download')} <span className="pink-color">{t('Weddified')}</span> {t('forFree')}!</u></a></h2>
            </div>
          </div>
              {/* <div className="light-purple-bg p-3 rounded">
                <div className="d-flex gap-3 align-items-start">
                  <img className="w-auto" src="../assets/images/w-logo.svg" alt="" />
                  <div className="download-app-text">
                    <p class="font-size-20 color-black mb-0"><b>{t('DownloadourMobileApp')}</b></p>
                    <p class="font-size-16 color-black mb-1">{t('StartPlanningYourDreamWeddingToday')}!</p>
                    <div className="row">
                      <div className="col-6">
                        <img class="apple-store-img w-100" src="../assets/images/app-store-img.svg" alt="" />
                      </div>
                      <div className="col-6">
                        <img class="google-store-img w-100" src="../assets/images/play-store-img.svg" alt=""/>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              {/* <div className="ad-box mt-3">
                <h2 className="font-size-36 color-black playfair-font text-center">Ad Box</h2>
              </div> */}
            </div>
          </div>
        </div>
      </section>
      </>)}
      <section className="single-blog-3 mt-5 mb-5">
        <div className="container">
          <div class="border-top  mb-5 mt-3"></div>
          <h3 className="font-size-36 playfair-font color-black mb-4">{t('Latest')} {t('Ideas&Inspirations')}</h3>
          <div className="row gy-3">
          {load ? 
          new Array(3).fill(null).map((_, index) => (
            <BlogCardSkeleton key={index} />
          ))
        :blogs && blogs?.slice(0, 3).map((b) => (
          <div key={b.id} className="col-md-4 blog-card">
          <div className="blog-card-body">
            <a
              className="color-black"
              onClick={()=> IncrementBlogView(b?._id)}
              href={`/blog/${b?.slug}`}
              target="_blank"
            >
              <img
                alt={b.title}
                src={
                  b?.image
                    ? `${process.env.REACT_APP_IMGURL}${b?.image}`
                    : "https://via.placeholder.com/415x268"
                }
                className="blog-image w-100"
              />
            </a>
            {b.category_id?.name ? (
              <div className="d-flex align-items-center top-box">
                <span className="font-size-12 rounded-1 wf-purple-btn py-1 px-3 text-uppercase cursor-pointer">
                  <a
                    style={{ color: "white" }}
                    href={`/ideas-inspiration/${b?.category_id?.slug}`}
                    target="_Blank"
                  >
                    {b.category_id?.name}
                  </a>
                </span>
              </div>
            ) : null}

            {b.blog_category_id?.name ? (
              <div className="d-flex align-items-center bottom-box">
                <span className="font-size-12 rounded-1 wf-pink-btn py-1 px-3 text-uppercase cursor-pointer">
                  <a
                    href={`${process.env.REACT_APP_FrontURL}/ideas-inspiration/${b.blog_category_id?.slug}`}
                  >
                    {b.blog_category_id?.name}
                  </a>
                </span>
              </div>
            ) : null}
          </div>
          <div className="blog-card-footer">
            <div className="row mb-2">
              <div className="col-6 d-flex align-items-center gap-2">
                <img
                  width="40"
                  height="40"
                  alt="avatar"
                  src={`${process.env.REACT_APP_IMGURL}w-logo-placeholder.png`}
                  class="rounded-circle wf-shadow-2"
                  style={{ objectFit: "cover" }}
                />
                <p className="font-size-14 color-black mb-0 fw-medium mt-1">
                  {b.author}
                </p>
              </div>
              <div className="col-6 d-flex align-items-center justify-content-end gap-4">
                <p className="font-size-14 color-black mb-0 fw-medium">
                  <i class="bi bi-hand-thumbs-up font-size-18"></i>{" "}
                  {b?.likes}
                </p>
                <p className="font-size-14 color-black mb-0 fw-medium">
                  <i class="bi bi-chat font-size-18"></i>{" "}
                  {b?.comments}
                </p>
              </div>
            </div>
            <h3 className="font-size-18 fw-semibold mb-1">
              <a
                className="color-black"
                onClick={()=> IncrementBlogView(b?._id)}
                href={`/blog/${b?.slug}`}
                target="_blank"
              >
                {b?.title}
              </a>
            </h3>
            <p className="font-size-16 color-black mb-1">
              {b.excerpt.substring(0, 64)}...
            </p>
            <div className="d-flex align-items-center justify-content-between">
              <p className="font-size-14 color-black mb-0">
                {moment(b?.createdAt).format("D MMMM YYYY")}
              </p>
              {/* <p className="font-size-14 color-black mb-0">5 mins read</p> */}
            </div>
          </div>
        </div>
              ))}
          </div>
        </div>
      </section>
      <Footer />
      </>
  );
};

export default BlogDetails1;