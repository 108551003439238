import React, { useEffect, useState, useContext } from 'react'
import {  useNavigate, useLocation, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getProfile, getEventList, userLogout } from "../../store/slices/userSlice"
import TaskContent from '../UserDashboard/TaskContent';
import NoEventUI from '../UserDashboard/noEventUI';

const Task_Dashboard = () => {

  const myEvents = useSelector(getEventList);

console.log('myEventstasks', myEvents)
 

  return (
    <>
                {/* Row 3 */}
                <div className='row mb-4'>
              <div className='col-12'>
                <div class="d-flex gap-4 align-items-end mb-2">
                  <h2 class="font-size-30 dm-font purple-color mb-0">Tasks</h2>
                  {myEvents?.some(event => event?.Event && event?.Event?.eventTasks?.length > 0) && (<a class="font-size-14 pink-color mb-0" href="#"><u>View All</u></a>)}
                </div>
              </div>
            </div>
           
           {myEvents?.some(event => event?.Event && event?.Event?.eventTasks?.length > 0) ? (<TaskContent />):(<NoEventUI name={"Tasks"} />)}
    </>
  )
}

export default Task_Dashboard