import React, {useEffect,useState} from 'react'
import Header from './HeaderLight'
import axios from "axios";
import Footer from './Footer'
import { ArrowRight, AtSign, Phone } from 'lucide-react';
import { Contactus } from "../../store/slices/userSlice";
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

const ContactUs = () => {

  const { t } = useTranslation();
    const [tc, settc] = useState(false);
    const [name, setname] = useState("");
    const [email, setemail] = useState("");
    const [role, setrole] = useState("");
    const [reason, setreason] = useState("");
    const [message, setmessage] = useState("");

    
    useEffect(() => {
    
    
      window.scrollTo({ top: 0, left: 0})
      
     
    
  }, []);




    const handleClick = async (v) => {
   if(v=="tc"){
        window.open(`${process.env.REACT_APP_APIURL}terms_and_conditions`,"_blank")
   }
   else{
    window.open(`${process.env.REACT_APP_APIURL}privacy_policy`,"_blank")
   }
      };




      const handleSubmit = async (e) => {
        e.preventDefault();
        console.log('first')
        try {
          if(!tc){
            toast.error("Please accept all terms and conditions")
          }
          else{
            const response = await axios.post(`vendorpage/contactus`, { name: name, email: email, role:role?role:"Bride/Groom", reason:reason, message:message });

            if(response){
              console.log('response', response)
                response?.data?.status==0?toast.error(response?.data?.message):toast.success(response?.data?.message)
                setname("")
                setemail("")
                setrole("")
                setreason("")
                setmessage("")
                settc(false)
            }
          }
        } catch (rejectedValueOrSerializedError) {
          toast.error(rejectedValueOrSerializedError.response?.data?.message);
        }
      };



return (
<>
<Helmet>
<title>Contact Weddified - Reach Out for Support and Inquiries</title>
<meta
  name="description"
  content="Reach out to the Weddified team for all your wedding planning needs. We're here to assist you with any inquiries or support you may require throughout your wedding journey."
/>
<meta
  name="keywords"
  content="Weddified, wedding planning"
/>
</Helmet>
<section class="contact-hero contact-bg">
<Header theme="light" />
  <div className="container">
    <div class="contact-hero-body">
      <h1 class="font-size-46 fw-bold playfair-font color-white">{t('ContactUs')}</h1>
    </div>
  </div>
</section>  
<section className="contact-section py-5">
  <div className="container">
    <div className="row gy-4">
    <div className="col-lg-5 col-md-12">
      <img src="../assets/images/contact-us-img-1.jpg" className="contact-us-img w-100 rounded-4 mb-4" alt="" />
      <p className="font-size-16 color-black">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud. Lorem ipsum dolor sit amet, consectetur adipiscing elit,</p>
      <div className="row gx-5 gy-2">
      <div className="col-md-6">
        <div className="d-flex align-items-center gap-3">
        <AtSign stroke="#ED257D" width={24} />
          <div>
          <p className="font-size-16 purple-color mb-0 fw-semibold">Email Address</p>
          <p className="font-size-14 color-black mb-0">info@weddified.com</p>
          </div>
        </div>
      </div>
      <div className="col-md-6">
        <div className="d-flex align-items-center gap-3">
        <Phone stroke="#ED257D" width={24} />
          <div>
          <p className="font-size-16 purple-color mb-0 fw-semibold">Contact Number</p>
          <p className="font-size-14 color-black mb-0">+01 000 000 0000</p>
          </div>
        </div>
      </div>
  </div>
    </div>
    <div className="col-lg-7 col-md-12">
      <div className="contact-form">
      <h2 className="font-size-26 playfair-font pink-color mb-3">Send Us A Message</h2>
      <div className="spacer-20"></div>
      <form onSubmit={handleSubmit}>

        <div class="form-floating wf-input wf-input-101 mb-3">
        <input type="text" class="form-control" id="floatingInput" placeholder="name" value={name?name:""}
        onChange={(e)=>setname(e.target.value)} autocomplete="name" required />
        <label for="floatingInput">Name</label>
      </div>
      <div class="form-floating wf-input wf-input-101 mb-3">
        <input type="email" class="form-control" id="floatingInput" placeholder="name@example.com" value={email?email:""}
        onChange={(e)=>setemail(e.target.value)} autocomplete="email" required />
        <label for="floatingInput">Email Address</label>
      </div>
      <div class="row  gx-2 gy-3 mb-4">
        <div class="col-md-4 col-sm-4 col-6 d-grid">
        <input type="radio" onClick={()=>setrole("Bride/Groom")} checked={role=="Bride/Groom"||role==""?true:false} name="role" id="bride-groom" class="btn-check" />
        <label class="btn wf-select-btn" for="bride-groom">Bride / Groom</label>
        </div>
        <div class="col-md-4 col-sm-4 col-6 d-grid">
        <input type="radio" onClick={()=>setrole("business")} checked={role=="business"?true:false} name="role" id="business" class="btn-check" />
        <label class="btn wf-select-btn" for="business">Business</label>
        </div>
        <div class="col-md-4 col-sm-4 col-6 d-grid">
        <input type="radio" onClick={()=>setrole("press")} checked={role=="press"?true:false} name="role" id="press" class="btn-check" />
        <label class="btn wf-select-btn" for="press">Press</label>
        </div>
      </div>
          <div class="form-floating mb-3 wf-select wf-select-101">
            <select class="form-select" id="floatingSelect" aria-label="Floating label select example" value={reason?reason:""} onChange={(e)=>setreason(e.target.value)}>
            <option value="Reason 1">Reason 1</option>
            <option value="Reason 2">Reason 2</option>
            <option value="Reason 3">Reason 3</option>
            </select>
            <label for="floatingSelect">Choose a Reason</label>
          </div>
          <div class="form-floating mb-3 wf-textarea wf-textarea-101">
            <textarea class="form-control" placeholder="Leave a comment here" id="floatingTextarea2" style={{"height": "150px"}} value={message?message:""} onChange={(e)=>setmessage(e.target.value)}></textarea>
            <label for="floatingTextarea2">Comment/Message</label>
          </div>
          <div class="form-check wf-signup-checkbox mb-3">
        <input class="form-check-input wf-shadow-2" type="checkbox" value="" id="flexCheckDefault" checked={tc} onChange={()=>settc(!tc)} />
        <label class="form-check-label" for="flexCheckDefault">
        I Accept Weddified <a className="white-color link-underline" onClick={()=>handleClick("tc")}>Terms & Conditions</a> and <a className="white-color link-underline" onClick={()=>handleClick("pp")}>Privacy & Policy</a>
        </label>
        </div>


        <button type="submit" className="btn wf-btn-2 wf-purple-btn py-2 px-4 rounded-2 wf-shadow-2"> Send Message</button>
        </form>
        </div>
    </div>
    </div>
  </div>
</section>
    
        <Footer />
        </>
      )
}

export default ContactUs