import React, { useEffect, useState, useContext } from 'react'
import { context } from '../../context/context';
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getProfile, getEventList, userLogout } from "../../store/slices/userSlice"
import { AlignJustify, ArrowRight, ChevronLeft, PanelRightOpen, X } from 'lucide-react';
import Modal from "react-modal";
import { useTranslation } from 'react-i18next';
import ChangePassword from '../ChangePassword';
const customStyles = {
    content: {
        // top: "50%",
        // left: "50%",
        // right: "auto",
        // bottom: "auto",
        // width: "30%",
        // marginRight: "-50%",
        // transform: "translate(-50%, -50%)",
        display: "block",
        '--bs-modal-width': '30%',
    },
};
Modal.setAppElement("#root");



const Sidebar = () => {

    const { t } = useTranslation();
    const profile = useSelector(getProfile)
    const myEvents = useSelector(getEventList);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const { toggleButton, SetToggleButton } = useContext(context);
    const [modalIsOpen, setIsOpen] = useState(false);
    const [actualmyEvents, setactualmyEvents] = useState([]);
    const [filteredData, setfilteredData] = useState([]);
    const [search, setsearch] = useState("");
    const [coupleURLFilter, setcoupleURLFilter] = useState(queryParams.get("couple") ? JSON.parse(queryParams.get("couple")) : []);
    const [coupleEventURLFilter, setcoupleEventURLFilter] = useState(queryParams.get("events") ? JSON.parse(queryParams.get("events")) : []);


    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }


    const handleLogout = async () => {
        try {

            await dispatch(userLogout()).unwrap()
            navigate(profile?.role == "customer" ? "/login" : "/vendor/login")

        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError)
        }
    }


    const handleFilters = () => {
        if (search) {
            setfilteredData(
                actualmyEvents?.filter((e) =>
                    e?.person1?.name?.toLowerCase().includes(search.toLowerCase()) ||
                    e?.person2?.name?.toLowerCase().includes(search.toLowerCase()) ||
                    e?.Event.some((event) =>
                        event?.event_name?.toLowerCase().includes(search.toLowerCase())
                    )
                )
            );
        }
        else {
            setfilteredData(actualmyEvents)
        }
    }




    const handleEventChange = (e, e1) => {
        let eventFilter;


        if (coupleEventURLFilter?.includes(e._id)) {
            eventFilter = coupleEventURLFilter?.filter((service) => service !== e._id);
        } else {
            eventFilter = [...coupleEventURLFilter, e._id];
            setcoupleURLFilter(e1.coupleIds)
            queryParams.set('couple', JSON.stringify(e1.coupleIds));
        }

        setcoupleEventURLFilter(eventFilter);


        if (eventFilter.length === 0) {
            queryParams.delete('events');
            queryParams.delete('couple');
            setcoupleURLFilter([])
        } else {
            queryParams.set('events', JSON.stringify(eventFilter));
        }

        navigate(`${location.pathname}?${queryParams}`);
    };

    const handleCoupleChange = (e) => {
        console.log('eeeeeee', e)

        function arraysEqual(arr1, arr2) {
            if (arr1.length !== arr2.length) {
                return false;
            }

            let sortedArr1 = arr1.slice().sort();
            let sortedArr2 = arr2.slice().sort();

            for (let i = 0; i < sortedArr1.length; i++) {
                if (sortedArr1[i] !== sortedArr2[i]) {
                    return false;
                }
            }

            return true;
        }

        const check = arraysEqual(coupleURLFilter, e.coupleIds)


        if (check) {

            setcoupleEventURLFilter([])
            setcoupleURLFilter([])
            queryParams.delete('events');
            queryParams.delete('couple');

            navigate(`${location.pathname}`);

        } else {

            console.log('else')


            const allcoupleevents = e.Event.some(event => event?._id) ? e?.Event?.map((ev) => ev._id) : []

            setcoupleURLFilter(e?.coupleIds);
            setcoupleEventURLFilter(allcoupleevents)

            queryParams.set('couple', JSON.stringify(e?.coupleIds));
            if (allcoupleevents?.length > 0) {
                queryParams.set('events', JSON.stringify(allcoupleevents));
            } else {
                queryParams.delete('events');
            }
        }
        navigate(`${location.pathname}?${queryParams}`);

    };

    console.log('coupleURLFilter', coupleURLFilter)
    console.log('coupleEventURLFilter', coupleEventURLFilter)


    const handleSetData = (e) => {
        console.log('evvv', e)
        const mergedEvents = [];

        e?.forEach(event => {

            let modifiedEvent = {
                ...event,
                Event: [event.Event],
                coupleIds: [event._id]
            };

            const existingEventIndex = mergedEvents?.findIndex(
                mergedEvent => mergedEvent?.person1?._id == event?.person1?._id && mergedEvent?.person2?._id == event?.person2?._id
            );

            if (existingEventIndex !== -1) {

                mergedEvents[existingEventIndex].Event.push(event.Event);
                mergedEvents[existingEventIndex].coupleIds.push(event._id);
            } else {

                mergedEvents.push(modifiedEvent);
            }
        });


        setactualmyEvents(mergedEvents);
        setfilteredData(mergedEvents);
    };


    useEffect(() => {
        handleSetData(myEvents)
    }, [myEvents]);

    useEffect(() => {
        handleFilters();
    }, [search]);




    console.log('filteredDatasidebar', filteredData)
    console.log('queryParams.toString(', queryParams.toString())

    return (
        <>

            <div className="wf-business-profile-sidebar-toggle" onClick={() => SetToggleButton((prev) => !prev)}>
                <ArrowRight />
            </div>
            <div className={`${toggleButton ? "sidebar close" : "sidebar"} d-flex align-items-start flex-column overflow-y-scroll`}>
                <div class="logo-details">
                    {toggleButton ? <X onClick={() => SetToggleButton((prev) => !prev)} stroke='var(--color2)' strokeWidth={1} /> : <AlignJustify onClick={() => SetToggleButton((prev) => !prev)} stroke='var(--color2)' strokeWidth={1} />}
                </div>
                {profile?.role == "planner" && (<>
                    <ul class="nav-links">
                        <li className={location.pathname == "/dashboard-planner" ? "active" : ""}>
                            <div className="icon-link">
                                <Link to={`${"/dashboard-planner"}?${queryParams.toString()}`}>
                                    <img className="" alt="icon" src={`../assets/images/dashboard-icon-wf.svg`} />
                                    <span className="link_name">{t('Dashboard')}</span>
                                </Link>
                            </div>
                        </li>
                        <li className={location.pathname == "/couples-list" || location.pathname == "/couples-overview" ? "active" : ""}>
                            <div className="icon-link">
                                <Link to={`${"/couples-list"}?${queryParams.toString()}`}>
                                    <img className="" alt="icon" src={`../assets/images/task-icon-wf.svg`} />
                                    <span className="link_name">Couples</span>
                                </Link>
                            </div>
                        </li>
                        <li className={location.pathname == "/event-list" || location.pathname == "/event-overview" ? "active" : ""}>
                            <div className="icon-link">
                                <Link to={`${"/event-list"}?${queryParams.toString()}`}>
                                    <img className="" alt="icon" src={`../assets/images/budget-icon-wf.svg`} />
                                    <span className="link_name">{t('Events')}</span>
                                </Link>
                            </div>
                        </li>
                        <li className={location.pathname == "/calendar" ? "active" : ""}>
                            <div className="icon-link">
                                <Link to={`${"/calendar"}?${queryParams.toString()}`}>
                                    <img className="" alt="icon" src={`../assets/images/guest-icon-wf.svg`} />
                                    <span className="link_name">{t('Calendar')}</span>
                                </Link>
                            </div>
                        </li>
                    </ul>
                    <div className="border-bottom w-100 my-3"></div>
                    <div class="wf-sidebar_allcouples my-4 dropdown">
                        <a class="wf-sidebar_allcouples-btn d-flex justify-content-between align-items-center px-3 py-2" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
                            <div className='d-flex align-items-center gap-3'>
                                <div className='wf-up-avatar d-flex mb-2 mb-md-0'>
                                    <div className='avatar-couple-first'>
                                        <img width="32" height="32" alt="" src={coupleURLFilter && myEvents?.filter((val) => val?._id == coupleURLFilter)[0]?.person1?.imageName ?
                                            `${process.env.REACT_APP_IMGURL}${myEvents?.filter((val) => val?._id == coupleURLFilter)[0]?.person1?.imageName}`
                                            : "https://via.placeholder.com/50x50"} class="rounded-circle avatar" style={{ border: '1px solid #804099', objectFit: 'cover' }} />
                                    </div>
                                    <div className='avatar-couple-sec' style={{ marginLeft: '-10px' }}>
                                        <img width="32" height="32" alt="" src={coupleURLFilter && myEvents?.filter((val) => val?._id == coupleURLFilter)[0]?.person2?.imageName ?
                                            `${process.env.REACT_APP_IMGURL}${myEvents?.filter((val) => val?._id == coupleURLFilter)[0]?.person2?.imageName}`
                                            : "https://via.placeholder.com/50x50"} class="rounded-circle avatar" style={{ border: '1px solid #EE0E7B', objectFit: 'cover' }} />
                                    </div>
                                </div>
                                <div className='d-flex flex-column'>
                                    <h4 className="font-size-12 text-break fw-semibold purple-color mb-0">{coupleURLFilter?.length > 0 ? `${filteredData?.filter((val) => coupleURLFilter?.includes(val?._id))[0]?.person1?.name || ""} ${myEvents?.filter((val) => coupleURLFilter?.includes(val?._id))[0]?.person2?.name ? "&" : ""} ${myEvents?.filter((val) => coupleURLFilter?.includes(val?._id))[0]?.person2?.name || ""}` : "All Couples"}</h4>
                                    <p className='font-size-12 color-black mb-0'>{coupleEventURLFilter?.length == myEvents?.length || coupleEventURLFilter?.length == 0 ? "All Events" : `${filteredData?.filter((val) => coupleURLFilter?.includes(val?._id))[0]?.Event?.filter((f) => coupleEventURLFilter?.includes(f._id))?.length || 0} ${filteredData?.filter((val) => coupleURLFilter?.includes(val?._id))[0]?.Event?.filter((f) => coupleEventURLFilter?.includes(f._id))?.length < 2 ? "Event" : "Events"}`}</p>
                                </div>
                            </div>
                            <div className='color-grey'><i class={"bi bi-chevron-down ms-2"}></i></div>
                        </a>
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                            <li>
                                <div class="px-3 py-3 purple-color">
                                    <div class="input-group recent-couples-search mb-1 wf-shadow-2 rounded">
                                        <input onChange={(e) => setsearch(e.target.value)} type="text" class="font-size-12 form-control" placeholder="Search Couples or Events..." />
                                        <button class="input-group-text purple-color" id="serach-couples">
                                            <i class="bi bi-search"></i>
                                        </button>
                                    </div>
                                </div>
                            </li>
                            <li><hr class="dropdown-divider" /></li>
                            {/* <li style={{ backgroundColor: coupleURLFilter?.includes(e._id) && "#BDBCBC" }} className='wf-sidebar_allcouples-sec cursor-pointer'> */}
                            <li style={{ backgroundColor: "transparent" }} className='wf-sidebar_allcouples-sec cursor-pointer'>

                                {filteredData?.length > 0 && filteredData?.filter(item => Boolean(item?.event_id))?.map((e) => (
                                    <div class="px-3 py-3" href="#">
                                        <div onClick={() => handleCoupleChange(e)} className='wf-up-avatar d-flex mb-3'>
                                            <div className='avatar-couple-first'>
                                                <img width="32" height="32" alt="" src={e?.person1?.imageName ? `${process.env.REACT_APP_IMGURL}${e?.person1?.imageName}` : "https://via.placeholder.com/50x50"} class="rounded-circle avatar" style={{ border: '1px solid #804099', objectFit: 'cover' }} />
                                            </div>
                                            <div className='avatar-couple-sec' style={{ marginLeft: '-10px' }}>
                                                <img width="32" height="32" alt="" src={e?.person2?.imageName ? `${process.env.REACT_APP_IMGURL}${e?.person2?.imageName}` : "https://via.placeholder.com/50x50"} class="rounded-circle avatar" style={{ border: '1px solid #EE0E7B', objectFit: 'cover' }} />
                                            </div>
                                        </div>
                                        <div onClick={() => handleCoupleChange(e)} className='d-flex flex-column mb-3'>
                                            <h4 className="font-size-16 text-break fw-semibold text-uppercase purple-color mb-0">{`${e?.person1?.name || ""} ${e?.person2?.name ? "&" : ""} ${e?.person2?.name || ""}`}</h4>
                                            {e?.Event.every(event => event && event._id) && (<p className='font-size-14 fw-semibold text-uppercase pink-color mb-0'>{Array.isArray(e?.Event) && e?.Event?.some(event => event?._id) ? e?.Event?.filter(event => event?._id)?.length : 0} {Array.isArray(e?.Event) && e?.Event?.some(event => event?._id) ? (e?.Event?.filter(event => event?._id)?.length < 2 ? "event" : "events") : "event"}</p>)}
                                        </div>

                                        {e?.Event.every(event => event && event._id) && e.Event && e.Event.some(event => event._id) && e?.Event?.map((ev) => (<form className='wf-couplesdropcheck'>
                                            <p className='mb-1'>
                                                <label className='font-size-14 color-black fw-semibold d-flex align-items-center'>
                                                    <input checked={coupleEventURLFilter?.includes(ev?._id)} onChange={() => handleEventChange(ev, e)} className='form-check-input m-0' type="checkbox" />
                                                    <span className='wf-couplesdropcheck_line mx-2' style={{ background: `${ev?.event_color}` }} ></span>{ev?.event_name} </label>
                                            </p>
                                        </form>))}
                                    </div>

                                ))}

                            </li>
                        </ul>
                    </div></>)}
                <ul class="nav-links">
                    {profile?.role !== "planner" && (<li className={location.pathname == "/dashboard" || location.pathname == "/couples-overview" ? "active" : ""}>
                        <div className="icon-link">
                            <Link to={profile?.role == "planner" ? '/couples-overview' : '/dashboard'}>
                                <img className="" alt="icon" src={`../assets/images/dashboard-icon-wf.svg`} />
                                <span className="link_name">{profile?.role == "planner" ? t('Overview') : t('Dashboard')}</span>
                            </Link>
                        </div>
                    </li>)}
                    <li className={location.pathname == "/task-management" ? "active" : ""}>
                        <div className="icon-link">
                            <Link to={`${"/task-management"}?${queryParams.toString()}`}>
                                <img className="" alt="icon" src={`../assets/images/task-icon-wf.svg`} />
                                <span className="link_name">{t('Task')}</span>
                            </Link>
                        </div>
                    </li>
                    <li className={location.pathname == "/budget" ? "active" : ""}>
                        <div className="icon-link">
                            <Link to={`${"/budget"}?${queryParams.toString()}`}>
                                <img className="" alt="icon" src={`../assets/images/budget-icon-wf.svg`} />
                                <span className="link_name">{t('Budget')}</span>
                            </Link>
                        </div>
                    </li>
                    <li className={location.pathname == "/guest-list" ? "active" : ""}>
                        <div className="icon-link">
                            <Link to={`${"/guest-list"}?${queryParams.toString()}`}>
                                <img className="" alt="icon" src={`../assets/images/guest-icon-wf.svg`} />
                                <span className="link_name">{t('Guests')}</span>
                            </Link>
                        </div>
                    </li>
                    <li className={location.pathname == "/short-list" ? "active" : ""}>
                        <div className="icon-link">
                            <Link to={`${"/short-list"}?${queryParams.toString()}`}>
                                <img className="" alt="icon" src={`../assets/images/shortlist-icon-wf.svg`} />
                                <span className="link_name">{t('Shortlist')}</span>
                            </Link>
                        </div>
                    </li>
                    <li className={location.pathname == "/bookings" ? "active" : ""}>
                        <div className="icon-link">
                            <Link to={`${"/bookings"}?${queryParams.toString()}`}>
                                <img className="" alt="icon" src={`../assets/images/bookings-icon-wf.svg`} />
                                <span className="link_name">{t('Bookings')}</span>
                            </Link>
                        </div>
                    </li>
                    {profile?.role !== "planner" && (
                        <>
                            <li className={location.pathname == "/event-list" || location.pathname == "/event-overview" ? "active" : ""}>
                                <div className="icon-link">
                                    <Link to={`${"/event-list"}?${queryParams.toString()}`}>
                                        <img className="" alt="icon" src={`../assets/images/budget-icon-wf.svg`} />
                                        <span className="link_name">{t('Events')}</span>
                                    </Link>
                                </div>
                            </li>
                            <li className={location.pathname == "/calendar" ? "active" : ""}>
                                <div className="icon-link">
                                    <Link to={`${"/calendar"}?${queryParams.toString()}`}>
                                        <img className="" alt="icon" src={`../assets/images/guest-icon-wf.svg`} />
                                        <span className="link_name">{t('Calendar')}</span>
                                    </Link>
                                </div>
                            </li>
                        </>
                    )}
                </ul>
                <ul className="nav-links mt-auto">
                    <li className={location.pathname == "/billing" ? "active" : ""}>
                        <div className="icon-link">
                            <a>
                                <img className="" alt="icon" src={`../assets/images/billing-icon-wf.svg`} />
                                <span className="link_name">Billing</span>
                            </a>
                        </div>
                    </li>
                    <li>
                        <div className="icon-link">
                            <a onClick={() => openModal()}>
                                <img className="" alt="icon" src={`../assets/images/change-password-icon-wf.svg`} />
                                <span className="link_name">Change Password</span>
                            </a>
                        </div>
                    </li>
                    <li>
                        <div className="icon-link">
                            <a >
                                <img className="" alt="icon" src={`../assets/images/language-icon-wf.svg`} />
                                <span className="link_name">Language</span>
                            </a>
                        </div>
                    </li>
                    <li>
                        <div className="icon-link">
                            <a>
                                <img className="" alt="icon" src={`../assets/images/help-icon-wf.svg`} />
                                <span className="link_name">help & Support</span>
                            </a>
                        </div>
                    </li>
                    <li>
                        <div className="icon-link">
                            <a onClick={handleLogout}>
                                <img className="" alt="icon" src={`../assets/images/logout-icon-wf.svg`} />
                                <span className="link_name">log out</span>
                            </a>
                        </div>
                    </li>
                </ul>

            </div>

            <Modal
                closeTimeoutMS={500}
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Change Password"
                className="modal fade show wf-modal"
            >
                <ChangePassword closeModal={closeModal} />
            </Modal>

        </>

    )
}

export default Sidebar 