import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import Cookies from 'js-cookie';

export const firebaseConfig = {
    apiKey: "AIzaSyCHcal0KVJC90hLhwzH6u5_8O_9k6aGD_w",
    authDomain: "weddified.firebaseapp.com",
    projectId: "weddified",
    storageBucket: "weddified.appspot.com",
    messagingSenderId: "68283495312",
    appId: "1:68283495312:web:60bf559a2e650ef2237915"
};

const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);

export const requestForToken = async () => {
    const permission = await Notification.requestPermission()
    console.log(permission)
    if (permission == "granted") {
        const token = await getToken(messaging, {
            vapidKey: 'BM9cUbTANaUumIVNNuMgJ8_s9Gg7r_hyp1bZk53ywJY8NX3IiV8Bdw5CN0blSVZhDCUqd6KhiGi8KrESDsoDJ-k'
        })


        Cookies.set("user_device_token", token, { expires: 365 }); // Set cookie to expire in 365 days

    }
};

export const onMessageListener = () =>
    new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            console.log("payload", payload)
            resolve(payload);
        });
    });