import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getProfile,
  getEventList,
  userLogout,
} from "../../store/slices/userSlice";
import moment from "moment";
import { useTranslation } from "react-i18next";
import Events_Dashboard from './Events_Dashboard'
import Task_Dashboard from './Task_Dashboard'
import Bookings_Dashboard from '../UserDashboard/Bookings_Dashboard'
import Budget_Dashboard from './Budget_Dashboard'
import Chatlist_Dashboard from '../UserDashboard/Chatlist_Dashboard'
import { Newsletter } from '../Public/Newsletter'
import SmallCalendar from './SmallCalendar'
import CalendarData from './CalendarData'



const Dashboard = () => {
  const { t } = useTranslation();
  const profile = useSelector(getProfile);
  const myEvents = useSelector(getEventList);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [date, setDate] = useState(new Date());
  const handleDateChange = (date) => {
    setDate(date);
    navigate('/calendar', { state: { date } }); // Navigate to '/calendar' with date state
  };


  return (
    <>
      <section className='wf-user-dashboard wf-background px-md-5 pb-5 pt-2'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-lg-8 col-md-12'>
              <Events_Dashboard />
              <Task_Dashboard />
              <Bookings_Dashboard />
              <Budget_Dashboard />

            </div>

            {/* Right bar */}
            <div className='col-lg-4 col-md-12'>
              {/* Row 1 */}
              <div className='row mb-4'>
                <div className='col-12'>
                  <div class="d-flex gap-4 align-items-end mb-0">
                    <h2 class="font-size-40 dm-font color-black mb-0">Calendar</h2>
                  </div>
                </div>

              </div>
              {/* Row 2 */}
              <div className='row mb-4'>
                <div className='col-12'>
                  <div className='Wediffied-Calender'>
                    <SmallCalendar selectedDate={date} onDateChange={handleDateChange} />
                  </div>
                </div>
              </div>
              <Chatlist_Dashboard />
              <div id="download-app" className="mb-3">
                <h2 className="font-size-26 dm-font color-black mb-2">{t('DownloadourMobileApp')}</h2>
                <p className="font-size-14 purple-color mb-2">{t('StartPlanningYourDreamWeddingToday')}!</p>
                <div className="wf-shadow-2 white-bg rounded py-3 px-4">
                  <img class="img-fluid w-100 mb-4" src={`${process.env.REACT_APP_FrontURL}/assets/images/download-app-graphics.svg`} alt="" />
                  <div className="d-flex align-items-center gap-3 mb-3 justify-content-center">
                    <div><img class="google-store-img img-fluid " src={`${process.env.REACT_APP_FrontURL}/assets/images/play-store-img.svg`} alt="" /></div>
                    <div><img class="apple-store-img img-fluid" src={`${process.env.REACT_APP_FrontURL}/assets/images/app-store-img.svg`} alt="" /></div>
                  </div>
                  <h2 className="font-size-20 color-black mb-0 text-center dm-font"><a className="color-black"><u>{t('Download')} <span className="pink-color">{t('Weddified')}</span> {t('forFree')}!</u></a></h2>
                </div>
              </div>
              <Newsletter />



            </div>

          </div>

        </div>

      </section>

    </>
  )
}

export default Dashboard