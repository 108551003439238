import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  signupUser,
  getAllCountries,
  updateProfile,
} from "../../store/slices/userSlice";
import { toast } from "react-toastify";
import jwtDecode from "jwt-decode";
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "../../components/Layout/LanguageSwitcher";
import i18n from "../../components/i18n";
// import jwt from 'jsonwebtoken';

const SignUp = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // const test= jwt.verify()

  const handleClick = async () => {
    window.open(
      `${process.env.REACT_APP_APIURL}terms_and_conditions`,
      "_blank"
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!tc) {
        toast.error("Please accept all terms and conditions");
      } else {
        if (id) {
          const su = await dispatch(
            updateProfile({ id, phone, name, password, country, confirmPassword })
          ).unwrap();
          navigate("/verify-account", { state: { user_id: su.data } });
        } else {
          const su = await dispatch(
            signupUser({
              name,
              email,
              password,
              phone,
              country,
              confirmPassword,
              user_purpose,
              own_wedding,
              role: own_wedding == "yes" ? "customer" : "planner",
            })
          ).unwrap();
          navigate("/verify-account", {
            state: { user_id: su.data, email: email },
          });
        }
      }
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  const getValuesofCountries = () => {
    const abc = countries && countries?.find((c) => c._id == country);
    setshowcountry(abc?.name);

    const index = countries?.findIndex((item) => item._id === country);

    if (index !== -1) {
      const objectToMove = countries?.splice(index, 1)[0];
      countries?.unshift(objectToMove);
    }
  };

  // useEffect(() => {
  //   const searchParams = new URLSearchParams(window.location.search);
  //   const token = searchParams.get("token");
  //   console.log("tokennnnnnnn", token);
  //   if (token) {
  //     try {
  //       let countryObj = "";
  //       const decodedToken = jwtDecode(token);

  //       if (countries) {
  //         countryObj = countries?.find(
  //           (obj) => obj._id == decodedToken?.country
  //         );
  //       }
  //       console.log("decodedToken", decodedToken, countryObj);
  //       setid(decodedToken?.id);
  //       setName(decodedToken?.name);
  //       setphone(decodedToken?.phone);
  //       setEmail(decodedToken?.email);
  //       setcountry(countryObj?._id);
  //       setshowcountry(countryObj?.name);
  //       setown_wedding(
  //         decodedToken?.user_purpose == "groom" ||
  //           decodedToken?.user_purpose == "bride"
  //           ? "yes"
  //           : "no"
  //       );
  //       setuser_purpose(decodedToken?.user_purpose);
  //     } catch (error) {
  //       console.error("Error decoding token:", error);
  //     }
  //   }
  // }, []);

  const [id, setid] = useState("");
  const [toggle, settoggle] = useState(false);
  const [countries, setcountries] = useState(null);
  const [email, setEmail] = useState("");
  const [phone, setphone] = useState("");
  const [name, setName] = useState("");
  const [country, setcountry] = useState("");
  const [showcountry, setshowcountry] = useState("");
  const [own_wedding, setown_wedding] = useState("");
  const [user_purpose, setuser_purpose] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setconfirmPassword] = useState("");
  const [tc, settc] = useState(false);
  const [isSecureEntry, setisSecureEntry] = useState(true);
  const [isSecureEntry1, setisSecureEntry1] = useState(true);

  console.log("id", id);

  const Countries = async () => {
    try {
      setcountries(null);
      const response = await dispatch(getAllCountries()).unwrap();
      setcountries(response?.data);

      const searchParams = new URLSearchParams(window.location.search);
      const token = searchParams.get("token");
      console.log("tokennnnnnnn", token);
      if (token) {
        try {
          let countryObj = "";
          const decodedToken = jwtDecode(token);

          countryObj = response?.data?.find(
            (obj) => obj._id == decodedToken?.country
          );

          console.log("decodedToken", decodedToken, countryObj);
          setid(decodedToken?.id);
          setName(decodedToken?.name);
          setEmail(decodedToken?.email);
          setphone(decodedToken?.phone);
          setcountry(countryObj?._id);
          setshowcountry(countryObj?.name);
          setown_wedding(
            decodedToken?.user_purpose == "groom" ||
              decodedToken?.user_purpose == "bride"
              ? "yes"
              : "no"
          );
          setuser_purpose(decodedToken?.user_purpose);
        } catch (error) {
          console.error("Error decoding token:", error);
        }
      }
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  useEffect(() => {
    let mount = true;
    if (mount) {
      Countries();
    }
    return () => {
      mount = false;
    };
  }, []);

  useEffect(() => {
    getValuesofCountries();
  }, [countries]);

  console.log("countries", countries);
  console.log("showcountry", showcountry);
  console.log("country", country);
  console.log("toggle", toggle);

  const currentLanguage = i18n.language;

  return (
    <>
      <section class="authentication-bg">
        <div class="container py-5 h-100">
          <div class="row d-flex justify-content-center align-items-center h-100">
            <div class="col-12 col-md-8 col-lg-6 col-xl-5">
              <div class="card shadow-2-strong border-0 rounded">
                <div class="card-body py-5 px-md-5 px-4">
                  <img
                    onClick={() => navigate("/")}
                    className="mx-auto d-block auth-logo mb-5 wf-logo-card"
                    src={`${process.env.REACT_APP_FrontURL}/assets/images/${
                      currentLanguage === "ar" ? "logo-arabic.png" : "logo.svg"
                    }`}
                    alt="Weddified"
                    width={currentLanguage === "ar" ? "240px" : "270px"}
                  />

                  <form onSubmit={handleSubmit}>
                    <div class="form-floating wf-input wf-input-101 mb-3">
                      <input
                        type="text"
                        class="form-control"
                        id="floatingInput"
                        placeholder="name"
                        onChange={(e) => setName(e.target.value)}
                        defaultValue={name}
                        autocomplete="name"
                        maxLength={40}
                        required
                      />
                      <label for="floatingInput">{t("YourFullName")}</label>
                      {/* <div class="invalid-feedback d-block">Enter Full Name</div> */}
                    </div>
                    <div class="form-floating wf-input wf-input-101 mb-3">
                      <input
                        disabled={id ? true : false}
                        type="email"
                        class="form-control"
                        defaultValue={email}
                        id="floatingInput"
                        placeholder="name@example.com"
                        onChange={(e) => setEmail(e.target.value)}
                        autocomplete="email"
                        maxLength={320}
                        required
                      />
                      <label for="floatingInput">{t("YourEmailAddress")}</label>
                      {/* <div class="invalid-feedback d-block">Enter Email Address</div> */}
                    </div>
                    <div class="form-floating wf-input wf-input-101 mb-3">
                      <input                     
                        type="tel"
                        pattern="[0-9]*"
                        class="form-control"
                        defaultValue={phone}
                        id="floatingInput"
                        placeholder="Phone"
                        onChange={(e) => setphone(e.target.value)}
                        autocomplete="phone"
                        maxLength={15}
                        required
                      />
                      <label for="floatingInput">{t("Phone")}</label>
                      {/* <div class="invalid-feedback d-block">Enter Email Address</div> */}
                    </div>
                    <div class="form-floating wf-input wf-input-101 mb-3">
                      <input
                        class="form-control"
                        id="floatingPassword"
                        placeholder="Password"
                        onChange={(e) => setPassword(e.target.value)}
                        type={isSecureEntry ? "password" : "text"}
                        autocomplete="password"
                        maxLength={128}
                        required
                      />
                      <i
                        onClick={() => {
                          setisSecureEntry((prev) => !prev);
                        }}
                        className={`fa-solid ${
                          isSecureEntry ? "fa-eye" : "fa-eye-slash"
                        } eye-password`}
                      ></i>
                      <label for="floatingPassword">{t("Password")}</label>
                      {/* <div class="invalid-feedback d-block">Enter Password</div> */}
                    </div>
                    <div class="form-floating wf-input wf-input-101 mb-3">
                      <input
                        class="form-control"
                        id="floatingPassword"
                        placeholder="Password"
                        onChange={(e) => setconfirmPassword(e.target.value)}
                        type={isSecureEntry1 ? "password" : "text"}
                        autocomplete="password"
                        maxLength={128}
                        required
                      />
                      <i
                        onClick={() => {
                          setisSecureEntry1((prev) => !prev);
                        }}
                        className={`fa-solid ${
                          isSecureEntry1 ? "fa-eye" : "fa-eye-slash"
                        } eye-password`}
                      ></i>
                      <label for="floatingPassword">
                        {t("Confirm")} {t("Password")}
                      </label>
                      {/* <div class="invalid-feedback d-block">Enter Confirm Password</div> */}
                    </div>
                    <div onClick={() => settoggle(!toggle)}>
                      <div class="dropdown mb-3 country-city-dropdown">
                        <a
                          className="btn dropdown-toggle"
                          show
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          {country ? (
                            showcountry
                          ) : (
                            <>{t("CountryofResidence")}</>
                          )}
                        </a>

                        <ul className={`dropdown-menu ${toggle && "show"}`}>
                          <li
                            disabled={id ? true : false}
                            onClick={() => {
                              setcountry("");
                            }}
                            className="country cursor-pointer"
                          >
                            <a>
                              <span style={{ marginLeft: "10px" }}>
                                {t("CountryofResidence")}
                              </span>
                            </a>
                          </li>
                          {countries?.length > 0 &&
                            countries.map((country) => (
                              <li
                                disabled={id ? true : false}
                                onClick={() => {
                                  setcountry(country._id);
                                  setshowcountry(country.name);
                                }}
                                key={country.code}
                                className="country cursor-pointer"
                              >
                                <a>
                                  <img
                                    src={`${process.env.REACT_APP_IMGURL}${country.imageName}`}
                                    width="25"
                                  />
                                  <span style={{ marginLeft: "10px" }}>
                                    {country.name}
                                  </span>
                                </a>
                              </li>
                            ))}
                        </ul>
                      </div>
                      {/* <div class="invalid-feedback d-block">Select Country of Residence</div> */}
                    </div>

                    <h3 className="font-size-20 dm-font pink-color mb-2">
                      {t("ImjoiningWeddifiedas")}
                    </h3>
                    <div className="d-flex gap-2 flex-wrap mb-4 wf-radio-buttons-container">
                      <input
                        disabled={id ? true : false}
                        checked={user_purpose === "groom"}
                        onClick={() => {
                          setown_wedding("yes");
                          setuser_purpose("groom");
                        }}
                        type="radio"
                        name="are-you"
                        id="groom"
                        className="btn-check"
                      />
                      <label
                        className="btn wf-select-btn d-flex align-items-center gap-3 wf-radio-label"
                        htmlFor="groom"
                      >
                        <img
                          src="../assets/images/groom-icon-wf.svg"
                          className="color"
                          height="30"
                        />
                        <img
                          src="../assets/images/white-groom-icon-wf.svg"
                          className="white"
                          height="30"
                        />
                        {t("Groom")}
                      </label>

                      <input
                        disabled={id ? true : false}
                        checked={user_purpose === "bride"}
                        onClick={() => {
                          setown_wedding("yes");
                          setuser_purpose("bride");
                        }}
                        type="radio"
                        name="are-you"
                        id="bride"
                        className="btn-check"
                      />
                      <label
                        className="btn wf-select-btn d-flex align-items-center gap-3 wf-radio-label"
                        htmlFor="bride"
                      >
                        <img
                          src="../assets/images/bride-icon-wf.svg"
                          className="color"
                          height="30"
                        />
                        <img
                          src="../assets/images/white-bride-icon-wf.svg"
                          className="white"
                          height="30"
                        />
                        {t("Bride")}
                      </label>

                      <input
                        type="radio"
                        name="join-weddifed"
                        checked={user_purpose === "planner"}
                        id="im-wedding-planner"
                        disabled={id ? true : false}
                        onClick={() => {
                          setown_wedding("no");
                          setuser_purpose("planner");
                        }}
                        className="btn-check"
                      />
                      <label
                        className="btn wf-select-btn d-flex flex-row align-items-center gap-2 wf-radio-label"
                        htmlFor="im-wedding-planner"
                      >
                        <img
                          src="../assets/images/organizing-a-wedding.svg"
                          className="mx-auto d-block color"
                          height="30"
                        />
                        <img
                          src="../assets/images/white-organizing-a-wedding.svg"
                          className="mx-auto d-block white"
                          height="30"
                        />
                        {t("weddingplanner")}
                      </label>
                    </div>

                    <div class="form-check wf-signup-checkbox mb-3">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                        checked={tc}
                        onChange={() => settc(!tc)}
                      />
                      <label class="form-check-label" for="flexCheckDefault">
                        {t("Acceptall")}{" "}
                        <a className="white-color link-underline">
                          {t("Terms&Conditions")}
                        </a>
                      </label>
                    </div>
                    <div class="d-grid gap-2 mt-2">
                      <button
                        class="btn wf-purple-btn wf-btn btn-lg"
                        type="submit"
                      >
                        {t("Sign Up")}
                      </button>
                    </div>
                  </form>
                </div>
                <div class="card-footer wf-auth-card-footer ">
                  <div className="text-center">
                    <a>
                      {t("AlreadyhaveaWeddifiedaccount")}?{" "}
                      <b
                        className="pink-color cursor-pointer"
                        onClick={() => navigate("/login")}
                      >
                        {t("Login")}
                      </b>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <p className="d-none">
        {" "}
        <LanguageSwitcher />{" "}
      </p>
    </>
  );
};

export default SignUp;
