import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getServices,getCitiesWithCountry } from "../../store/slices/userSlice";
import { getProfile} from "../../store/slices/userSlice";
import CaregoriesButtonSkeleton from "../LoadingSkeleton/CaregoriesButtonSkeleton";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";




const SignUpStep1 = ({ formData, handleFormUpdate }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const profile = useSelector(getProfile);



  const [services, setServices] = useState(null);
  const [cities, setCities] = useState(null);
  const [toggle, settoggle] = useState(false);
  const [load, setload] = useState(false);


  // const select_color = [
  //   {
  //     id: 1,
  //     color_code: '#C587DC',
  //   },
  //   {
  //     id: 2,
  //     color_code: '#FE7A1578',
  //   },
  //   {
  //     id: 3,
  //     color_code: '#3F51B5AD',
  //   },
  //   {
  //     id: 4,
  //     color_code: '#EB536EA3',
  //   },
  //   {
  //     id: 5,
  //     color_code: '#8FDC2E9E',
  //   },
  // ];

  const select_color = [
    {
      id: 1,
      color_code: '#ffca3a',
    },
    {
      id: 2,
      color_code: '#8ac926',
    },
    {
      id: 3,
      color_code: '#1982c5',
    },
    {
      id: 4,
      color_code: '#FF4365',
    },
    {
      id: 5,
      color_code: '#ec7d10',
    },
  ];
 




  const handleSkillChange = (category) => {
    

      if (
        !formData.prefered_services.some(
          (obj) =>
            obj._id === category
        )
      ) {
       
     handleFormUpdate({
      prefered_services: [
            ...formData.prefered_services,
            { _id: category},
          ],
        });
      }
     
     else {
 
      handleFormUpdate({
        prefered_services: formData.prefered_services.filter(
          (obj) =>
            !(obj._id === category)
        ),
      });
    }
  };



  const Services = async () => {
    try {
      setload(true);
      setServices(null);
      const response = await dispatch(getServices()).unwrap();
      console.log("abc", response?.data);
      setServices(response?.data);
      setload(false);
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  function moveObjectToFirst(array, objectId) {
    const index = array.findIndex(item => item.countryId === objectId);
  
    if (index !== -1) {
      const objectToMove = array.splice(index, 1)[0];
      array.unshift(objectToMove); 
    }
  }

  const CitiesWithCountry = async () => {
    try {
      setload(true);
      setCities(null);
      const response = await dispatch(getCitiesWithCountry()).unwrap();
      console.log("abc", response?.data);
      setCities(response?.data);
      moveObjectToFirst(response?.data, profile?.country);
      setload(false);
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };


  useEffect(() => {
    let mount = true;
    if (mount) {
      handleFormUpdate({ isquestion: false })
      Services();
      CitiesWithCountry();
    }
    return () => {
      mount = false;
    };
  }, []);


  const handleNextStep=()=>{
    if(!formData.event_name){
      toast.error("Event name is required")
    }
    else if(!formData.event_color){
      toast.error("Event color is required")
    }
    else if(!formData.show_city){
      toast.error("Event city is required")
    }
    else if(formData.prefered_services?.length<1){
      toast.error("Please select your desired categories")
    }
    else{
      handleFormUpdate({ page: formData.page+1 })
    }
  }


  



  return (
    <>
              
    

      <h3 className="playfair-font font-size-20 pink-color mb-3">{t('LetsCreateYourFirstEvent')}</h3>
              <div class="dropdown wf-event-name-dropdown mb-3">
                <div class="form-floating wf-input wf-input-101">
                      <input onChange={(e)=>handleFormUpdate({ event_name: e.target.value})} defaultValue={formData?.event_name} type="text" class="form-control" id="floatingInput" placeholder="Event name" 
                      autocomplete="event name" maxLength={30} required />
                      <label for="floatingInput">{t('Event')} {t('Name')}</label>
                  </div>
                  <a class="wf-event-color-dropdown" style={{backgroundColor:`${formData.event_color}`}} href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false"></a>
                  <div class="dropdown-menu">
                    <div className="d-flex gap-2 wf-event-color">
                    {select_color?.map((color) => (
                        <div key={color.id}>
                          <input type="radio" className="btn-check" id={`${color.id}-color`} name="color101" onChange={(e)=>handleFormUpdate({ ...formData, event_color: e.target.value })} value={color.color_code} checked={formData.event_color === color.color_code} />
                          <label className="btn btn-md" style={{ backgroundColor: `${color.color_code}` }} htmlFor={`${color.id}-color`}></label>
                        </div>
                      ))}
                    </div>
                  
                  </div>
                </div>
              <div onClick={()=>settoggle(!toggle)}>
              <div class="dropdown mb-3 country-city-dropdown">
                <a class="btn btn-light dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                {formData?.show_city?formData?.show_city:<>{t('City')}</>}
                </a>

                <ul className={`dropdown-menu ${toggle&&"show"}`}>
                {cities?.length>0&&cities.map((c)=>(<li className="country-city cursor-pointer"><img src={`${process.env.REACT_APP_IMGURL}${c.countryFlag}`} width="25" /><span style={{"margin-left": "10px"}}>{c.countryName}</span>
                <ul className="city-list show">
                    {c.cities.map((ci)=>(<li onClick={()=>{handleFormUpdate({ event_city: ci.id, show_city: ci.name});settoggle(!toggle)}} className={`city cursor-pointer ${formData?.show_city==ci.name&&"selected" }`}><a>{ci.name}</a></li>))}                    
                </ul>
                </li>))} 
              </ul>

              </div>
              </div>
              <p className="playfair-font font-size-20 pink-color mb-2">{t('Select')} {t('Your')} {t('Categories')}</p>
              <div class="d-flex gap-2 flex-wrap mb-4">
              {load? new Array(22).fill(null).map((_, index) => (
                <div key={index} className='shimmer rounded-3' style={{height:'100px', width:'100px'}}></div>
              )):services?.length>0&&services?.map((s)=>(
              <>
              <input type="checkbox" name="cat-select" value={s._id} checked={formData.prefered_services.some((obj) => obj._id === s._id)}
              onClick={()=>handleSkillChange(s?._id)} id={s?._id} class="btn-check" />
              <label key={s._id} class="btn wf-select-btn wf-select-cat-btn d-flex flex-column justify-content-center gap-2" style={{width:'100px', lineHeight:'1.2'}} for={s?._id}>
                <img src={`${process.env.REACT_APP_IMGURL}${s.imageName}`} className="mx-auto d-block color" height="35" />
                <img src={`${process.env.REACT_APP_IMGURL}white-${s.imageName}`} className="mx-auto d-block white" height="35" />
                {s?.name}</label>
              </>
              ))}
            </div>
             
              <div class="d-flex justify-content-center gap-3">
              <button class="btn wf-btn-2 wf-purple-btn py-2 px-4 rounded-2" onClick={handleNextStep}>{t('Save')}</button>
              </div>
  
         
    </>
  );
};

export default SignUpStep1;
