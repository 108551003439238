import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    getEventList,
  } from "../../store/slices/userSlice";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";

const NoEventUI = ({name,path}) => {

    const { t } = useTranslation();
    const myEvents = useSelector(getEventList);
    const navigate = useNavigate();
    const location = useLocation();


    console.log('name,path', name,path)
  
  return (
    <div
            className="wf-shadow-2 rounded white-bg d-flex flex-column align-items-center justify-content-center mb-3"
            style={{ height: "300px" }}
          >
            <img
              width={50}
              className="mb-4"
              alt="icon"
              src={`../assets/images/task-icon-wf.svg`}
            />
            <p className="font-size-18 color-grey mb-2">
              Seems like you have no {name?name:"Events"}
            </p>
            <p
              className="font-size-18 pink-color mb-0 cursor-pointer"
              onClick={() => {
                if (path&&!path.includes("/")) {
                  path();
                } else {
                  navigate(path?path:"/event");
                }
              }}
            >
              {path=="" &&(<u>Let's create your first {name?name:"event"}!</u>)}
            </p>
          </div>
  )
}

export default NoEventUI